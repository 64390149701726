import { Navigate, Outlet, useLocation } from "react-router-dom";
import MenuHeader from "./components/Header/MenuHeader";
import { selectUser } from "./features/auth/authSlice";
import { useSelector } from "react-redux";
import SubscriptionAlert from "./components/Header/SubscriptionAlert";
import AutoHideAlert from "./components/Alert/Alert";

const Layout = () => {
  const user = useSelector(selectUser);
  const location = useLocation();

  const authPaths = ["/signup", "/signin", "/"];
  const rootPath = "/";

  if (
    (user?.completedOnboarding || user?.step >= 4) &&
    authPaths.includes(location?.pathname)
  ) {
    if (!user?.completedOnboarding) {
      return <Navigate to="/welcome" />;
    }
    return <Navigate to="/home" />;
  } else {
    if (rootPath === location?.pathname) {
      return <Navigate to="/signin" />;
    }
  }

  return (
    <main className="overflow-hidden no-scrollbar">
      {(user?.completedOnboarding || user?.step >= 4) &&
        location?.pathname !== "/welcome" && (
          <>
            {user.subscription !== null &&
              user?.subscription?.status === "trialing" && (
                <SubscriptionAlert />
              )}
            <MenuHeader user={user} />
          </>
        )}
      <AutoHideAlert />
      <Outlet />
    </main>
  );
};

export default Layout;
