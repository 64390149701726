import { CircularProgress, Stack } from '@mui/material';
import React, { useRef, useState } from 'react';

import DraggableComponent from './DraggableComponent';
import { Rnd } from 'react-rnd';
import { useDrop } from 'react-dnd';

const ReportBuildingArea = ({
    onDrop,
    children,
    onDelete,
    components,
    setComponents,
    selectedComponent,
    setSelectedComponent,
    onPropertyChange,
    disableRndDragging,
    setFlag,
    dropRef,
    setIsSavedClicked,
    selectedTemplate,
    setIsCreateNewTemplate,
    setIsSaveAsClicked,
    isSelectReport,
    setIsSelectReport,
    dashboardId,
    isNewDashboardActive,
    isLoadingReport,
    isLoadingTemplate,
    isTableView,
    isChartView,
    setIsAnimated,
    handleDesignClick,
    handleDataClick
}) => {
    const [componentPositions, setComponentPositions] = useState({});
    const [delta, setDelta] = useState({ width: '100%', height: '98%' })
    const reportAreaRef = useRef(null);
    const componentRefs = useRef({});
    const [minSize, setMinSize] = useState({ minWidth: 100, minHeight: 100 });
    const handleDrop = async (item, dropPosition, size) => {
        // if ((!isTableView && item?.type == 'table') || (!isChartView && item?.type == 'chart')) {
        //     return;
        // }
        // if (selectedTemplate === null && dashboardId === null && !isNewReportActive) {
        //     return;
        // }
        // if (selectedTemplate !== null) {
        //     return;
        // }
        if ((dashboardId === null && !isNewDashboardActive)) {
            return;
        }
        if (dropRef.current) {
            const dropAreaRect = dropRef.current.getBoundingClientRect();
            const newPosition = {
                x: dropPosition.x - dropAreaRect.left,
                y: dropPosition.y - dropAreaRect.top,
            };
            // Ensure the component stays within the report building area
            newPosition.x = Math.min(Math.max(newPosition.x, 0), dropAreaRect.width);
            newPosition.y = Math.min(Math.max(newPosition.y, 0), dropAreaRect.height);
            const newComponentPositions = {
                ...componentPositions,
                [item.id]: newPosition,
            };
            setComponentPositions(newComponentPositions);
            item.properties.left = newPosition?.x
            item.properties.top = newPosition?.y
            onDrop(item, size);
        }
    };
    const [, drop] = useDrop({
        accept: 'reportComponent',
        drop: (item, monitor) => {
            const dropPosition = monitor.getClientOffset();
            handleDrop(item, dropPosition);
            setTimeout(() => {
                const dropElement = document.getElementById(item.id);
                if (dropElement) {
                    const initialWidth = dropElement.offsetWidth;
                    const initialHeight = dropElement.offsetHeight;
                    handleDrop(item, dropPosition, { height: initialHeight, width: initialWidth });
                }
            }, 0);
        },
    });
    // const handleResizeStop = (e, direction, ref, delta, position) => {
    //     setDelta({
    //         width: ref.style.width,
    //         height: ref.style.height
    //     });
    // };
    const handleDelete = (id) => {
        const newComponentPositions = { ...componentPositions };
        delete newComponentPositions[id];
        setComponentPositions(newComponentPositions);
        onDelete(id);
    };
    const handleResizeStop = (e, direction, ref, delta, position) => {
        setDelta({
            width: Number(ref.style.width.split('%')[0]) < 99 ? '100%' : ref.style.width,
            height: Number(ref.style.height.split('%')[0]) < 98 ? '98%' : ref.style.height
        });
        // Reposition components if necessary
        const newComponentPositions = { ...componentPositions };
        Object.keys(newComponentPositions).forEach(id => {
            const componentPosition = newComponentPositions[id];
            const componentElement = document.getElementById(id);
            if (componentElement) {
                const componentWidth = componentElement.offsetWidth;
                const componentHeight = componentElement.offsetHeight;

                // Adjust position if the component is outside the resized area
                if (componentPosition.x + componentWidth > ref.offsetWidth) {
                    componentPosition.x = ref.offsetWidth - componentWidth;
                }
                if (componentPosition.y + componentHeight > ref.offsetHeight) {
                    componentPosition.y = ref.offsetHeight - componentHeight;
                }
            }
        });
        setComponentPositions(newComponentPositions);
    };
    React.useEffect(() => {
        const calculateMinSize = () => {
            let minWidth = 100;
            let minHeight = 100;
            if (!isChartView) {
                components.filter(item => item?.type != 'chart').forEach(component => {
                    const childElement = document.getElementById(component.id);
                    if (childElement) {
                        const childRight = component.properties.left + childElement.offsetWidth + 10;
                        const childBottom = component.properties.top + childElement.offsetHeight + 10;
                        minWidth = Math.max(minWidth, childRight);
                        minHeight = Math.max(minHeight, childBottom);
                    }
                });
            } else if (!isTableView) {
                components.filter(item => item?.type != 'table').forEach(component => {
                    const childElement = document.getElementById(component.id);
                    if (childElement) {
                        const childRight = component.properties.left + childElement.offsetWidth + 10;
                        const childBottom = component.properties.top + childElement.offsetHeight + 10;
                        minWidth = Math.max(minWidth, childRight);
                        minHeight = Math.max(minHeight, childBottom);
                    }
                });
            } else {
                components.forEach(component => {
                    const childElement = document.getElementById(component.id);
                    if (childElement) {
                        const childRight = component.properties.left + childElement.offsetWidth + 10;
                        const childBottom = component.properties.top + childElement.offsetHeight + 10;
                        minWidth = Math.max(minWidth, childRight);
                        minHeight = Math.max(minHeight, childBottom);
                    }
                });
            }
            setMinSize({ minWidth, minHeight });
        };
        calculateMinSize();
    }, [components, componentPositions, isTableView, isChartView]);

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropRef?.current && dropRef?.current?.contains(event.target)) {
                let clickedOutside = true;
                Object.keys(componentRefs.current).forEach(key => {
                    if (componentRefs.current[key]?.contains(event.target)) {
                        clickedOutside = false;
                    }
                });
                if (clickedOutside) {
                    setSelectedComponent(null);
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setSelectedComponent]);
    React.useEffect(() => {
        if (isSelectReport === true) {
            setDelta({ width: '100%', height: '98%' });
            setIsSelectReport(false);
        }
    }, [isSelectReport])
    return (
        <>
            {selectedTemplate === null ? (
                <Rnd
                    // ref={(node) => {
                    //     drop(node); // Attach the drop target to this node
                    //     dropRef.current = node; // Set dropRef
                    // }}
                    id="report-building-area"
                    size={{ width: delta.width, height: delta?.height }}
                    enableResizing={{
                        top: false,
                        right: true,
                        bottom: true,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false,
                    }}
                    onResizeStop={handleResizeStop}
                    minWidth={minSize.minWidth}
                    minHeight={minSize.minHeight}
                    disableDragging={true}
                    style={{
                        // height: '564px',
                        // width: '100%',
                        top: '24px',
                        minHeight: '98%',
                        minWidth: '98%',
                        //border: selectedTemplate === null ? '2px dashed gray' : "none",
                        border: '1px solid gray',
                        // padding: '20px',
                        position: 'relative',
                        transform: 'translate(0px, 0px)',
                    }}
                >
                    {isLoadingReport ? <Stack sx={{ color: 'grey.500', height: '100%', alignItems: 'center', justifyContent: 'center' }} spacing={2} direction="row">
                        <CircularProgress color="inherit" disableShrink />
                    </Stack> :
                        <div
                            ref={(node) => {
                                drop(node);
                                dropRef.current = node;
                            }}
                            style={{ width: '100%', height: '100%', position: 'relative' }}
                        >
                            {/* <div style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#bdbdbd',
                                pointerEvents: 'none',
                                textAlign: 'center',
                                fontSize: '14px',
                                fontFamily: "Inter",
                            }}>
                                Drag and drop a table or chart here to start building your dashboard
                            </div> */}
                            {(!components.some(item => item.type === 'table' || item.type === 'chart') && isNewDashboardActive) && (
                                <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#bdbdbd',
                                    pointerEvents: 'none',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    fontFamily: "Inter",
                                }}>
                                    Drag or click a table or chart here to start building your dashboard
                                </div>
                            )}

                            {children.map((child, index) => {
                                const id = child?.key
                                const position = componentPositions[id] || { x: 0, y: 0 }; // Initialize position properly
                                return (
                                    <div
                                        key={child?.key}
                                        ref={(el) => componentRefs.current[id] = el}
                                    >
                                        <DraggableComponent
                                            setFlag={setFlag}
                                            key={child?.key}
                                            id={child?.key}
                                            type={child?.props?.type}
                                            position={position}
                                            properties={components?.find((item) => item?.id === id)?.properties}
                                            setPosition={(newPosition) => {
                                                setComponentPositions({
                                                    ...componentPositions,
                                                    [id]: newPosition,
                                                });
                                            }}
                                            onDelete={handleDelete}
                                            isInReportArea={true}
                                            components={components}
                                            setComponents={setComponents}
                                            selectedComponent={selectedComponent !== null ? selectedComponent : null}
                                            setSelectedComponent={setSelectedComponent}
                                            onPropertyChange={onPropertyChange}
                                            reportAreaRef={dropRef}
                                            disableRndDragging={disableRndDragging}
                                            setIsSavedClicked={setIsSavedClicked}
                                            setIsCreateNewTemplate={setIsCreateNewTemplate}
                                            setIsSaveAsClicked={setIsSaveAsClicked}
                                            isTableView={isTableView}
                                            isChartView={isChartView}
                                            setIsAnimated={setIsAnimated}
                                            handleDesignClick={handleDesignClick}
                                            handleDataClick={handleDataClick}
                                        >
                                            {child}
                                        </DraggableComponent>
                                    </div>
                                );
                            })}
                        </div>
                    }
                </Rnd>
            ) : (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                    }}>
                    {isLoadingTemplate || selectedTemplate === null ? <Stack sx={{ color: 'grey.500', height: '100%', alignItems: 'center', justifyContent: 'center' }} spacing={2} direction="row">
                        <CircularProgress color="inherit" disableShrink />
                    </Stack> :
                        <div
                            ref={(node) => {
                                drop(node);
                                dropRef.current = node;
                            }}
                            style={{ width: '100%', height: '100%', position: 'relative' }}
                        >
                            {children.map((child, index) => {
                                const id = child?.key
                                const position = componentPositions[id] || { x: 0, y: 0 };
                                return (
                                    <div
                                        key={child?.key}
                                        ref={(el) => componentRefs.current[id] = el}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <div
                                            // ref={dragRef}
                                            className={`d-flex grow justify-center`}
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                top: 0,
                                                width: '100%',
                                                height: '100%',
                                                // opacity: disabled || isDragging ? 0.5 : 1,
                                                //border: isInReportArea ? '1px solid black' : "",
                                                padding: '8px',
                                                // cursor: 'grab',
                                                //display: isDragging ? 'none' : 'block', // Hide the original while dragging
                                            }}>
                                            {children}
                                            {/* {isInReportArea && (
                                            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                            </div>
                                        )} */}
                                        </div>

                                    </div>
                                );
                            })}
                        </div>}
                </div>
            )}
        </>
    );
};

export default ReportBuildingArea;
