import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { Icons } from "../../../../assets";
import classNames from "classnames";
import CheckboxSelector from "../../../Schedules/components/CheckboxSelector";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import { Tooltip } from "@mui/material";

function NewPatientsReportTable({
  patients,
  patientReports,
  cities,
  stateNames,
  handlePatientNameFilter,
  handleCityNameFilter,
  handleStateNameFilter,
  handleLocationFilter,
  selectedPatientsName,
  selectedCity,
  selectedStateName,
  setPatientReports,
  filteredPt,
  setFilteredPt,
  selectedLocation,
  setFilteredLt,
  filteredLt,
  location,
  isSelectAllPatients,
  setIsSelectAllPatients,
  isSelectAllLocations,
  setIsSelectAllLocations,
  isSelectAllCity,
  setIsSelectAllCity,
  isSelectAllState,
  setIsSelectAllState,
}) {
  const [patientNameListOpen, setPatientNameListOpen] = React.useState(false);
  const [locationListOpen, setLocationListOpen] = React.useState(false);
  const [cityListOpen, setCityListOpen] = React.useState(false);
  const [stateListOpen, setStateListOpen] = React.useState(false);
  const [patientNameSearch, setPatientNameSearch] = React.useState("");
  const [locationSearch, setLocationSearch] = React.useState("");
  const [isPatientIdAse, setIsPatientIdAse] = React.useState(true);
  const [isZipAse, setIsZipAse] = React.useState(true);

  const dropdownRef = React.useRef(null);
  const dropdownRefCity = React.useRef(null);
  const dropdownRefState = React.useRef(null);
  const dropdownLocationRef = React.useRef(null);

  const TABLE_HEADERS = [
    "Patient ID",
    "Patient Name",
    "Location",
    "Patient City",
    "Patient State",
    "Zip",
    "Date of Birth",
  ];
  const DAY_MAP = {
    MONDAY: "M",
    TUESDAY: "T",
    WEDNESDAY: "W",
    THURSDAY: "Th",
    FRIDAY: "F",
    SATURDAY: "Sa",
    SUNDAY: "Su",
  };
  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null || (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
      ? value.map((day) => DAY_MAP[day]).join(",")
      : type === "date"
      ? moment(value).format("MM/DD/YYYY")
      : value;
  };

  const handlePatientListOpen = () => {
    setPatientNameListOpen(!patientNameListOpen);
    setCityListOpen(false);
    setStateListOpen(false);
    if (patientNameListOpen) {
      setPatientNameSearch("");
      setFilteredPt(patients);
    }
  };
  const handleLocationListOpen = () => {
    setLocationListOpen(!locationListOpen);
    setCityListOpen(false);
    setStateListOpen(false);
    if (locationListOpen) {
      setLocationSearch("");
      setFilteredPt(location);
    }
    setLocationSearch("");
    setPatientNameListOpen(false);
  };
  const handleCityListOpen = () => {
    setCityListOpen(!cityListOpen);
    setStateListOpen(false);
    setPatientNameListOpen(false);
    setPatientNameSearch("");
    setLocationSearch("");
    setFilteredPt(patients);
    setFilteredLt(location);
  };
  const handleStateListOpen = () => {
    setStateListOpen(!stateListOpen);
    setPatientNameListOpen(false);
    setPatientNameSearch("");
    setLocationSearch("");
    setFilteredPt(patients);
    setCityListOpen(false);
    setFilteredLt(location);
  };
  const handleSelectAllPatients = () => {
    handlePatientNameFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllPatients,
    });
    setIsSelectAllPatients(!isSelectAllPatients);
  };
  const handleSelectAllLocations = () => {
    handleLocationFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllLocations,
    });
    setIsSelectAllLocations(!isSelectAllLocations);
  };

  const handleSelectAllCities = () => {
    handleCityNameFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllCity,
    });
    setIsSelectAllCity(!isSelectAllCity);
  };
  const handleSelectAllStates = () => {
    handleStateNameFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllState,
    });
    setIsSelectAllState(!isSelectAllState);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setPatientNameListOpen(false);
      setPatientNameSearch("");
      setFilteredPt(patients);
    }
    if (
      dropdownRefCity.current &&
      !dropdownRefCity.current.contains(event.target)
    ) {
      setCityListOpen(false);
    }
    if (
      dropdownRefState.current &&
      !dropdownRefState.current.contains(event.target)
    ) {
      setStateListOpen(false);
    }

    if (
      dropdownLocationRef.current &&
      !dropdownLocationRef.current.contains(event.target)
    ) {
      setLocationListOpen(false);
      setLocationSearch("");
      setFilteredLt(location);
    }
  };
  React.useEffect(() => {
    if (
      patientNameListOpen ||
      cityListOpen ||
      stateListOpen ||
      locationListOpen
    ) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [patientNameListOpen, cityListOpen, stateListOpen, locationListOpen]);
  React.useEffect(() => {
    setIsPatientIdAse(true);
    setIsZipAse(true);
    selectedPatientsName?.length === patients?.length
      ? setIsSelectAllPatients(true)
      : setIsSelectAllPatients(false);
    selectedLocation?.length === location?.length
      ? setIsSelectAllLocations(true)
      : setIsSelectAllLocations(false);
    selectedCity?.length === cities?.length
      ? setIsSelectAllCity(true)
      : setIsSelectAllCity(false);
    selectedStateName?.length === stateNames?.length
      ? setIsSelectAllState(true)
      : setIsSelectAllState(false);
  }, [selectedPatientsName, selectedCity, selectedStateName, selectedLocation]);
  return (
    <>
      <div className="h-full overflow-auto mt-2 w-full">
        <table
          className="w-full "
          id="newPatientsReport"
          style={{ whiteSpace: "nowrap" }}
        >
          <thead>
            <tr className="bg-primarylightgrey">
              {TABLE_HEADERS.map((header, index) => {
                if (header === "Location") {
                  return (
                    <th
                      key={index}
                      className={
                        "px-2 py-3 text-base relative min-w-56 " +
                        (locationListOpen || selectedLocation?.length > 0
                          ? "bg-highlight"
                          : "")
                      }
                      ref={dropdownLocationRef}
                    >
                      <div className="flex flex-row items-center justify-center">
                        <p className="m-0">
                          {header}
                          <span className="dropdown-icon ml-3"></span>
                        </p>
                        <Tooltip title="Sort">
                          <button
                            className="pr-2"
                            onClick={handleLocationListOpen}
                          >
                            <img
                              src={Icons.Sort_Icon}
                              alt="search"
                              className="w-5 h-5 cursor-pointer search-icon"
                            />
                          </button>
                        </Tooltip>{" "}
                        {locationListOpen && (
                          <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16 left-0">
                            <div className="d-flex align-center sticky top-0 bg-white">
                              <input
                                type="search"
                                value={locationSearch}
                                onChange={(e) => {
                                  setLocationSearch(e.target.value);
                                  setFilteredLt(
                                    location.filter((location) => {
                                      return location.name
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase());
                                    })
                                  );
                                }}
                                className="search-input"
                                autoFocus
                                placeholder="Search"
                              />
                              {locationSearch === "" ? (
                                <SearchIcon className="search-icon-input " />
                              ) : (
                                ""
                              )}
                            </div>
                            {filteredLt.length > 0 ? (
                              <>
                                <th
                                  className={classNames(
                                    "px-4 py-3 cursor-pointer text-sm"
                                  )}
                                >
                                  <label className="flex flex-row items-center gap-2 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className="w-4 h-4 checked:lightblue"
                                      checked={
                                        isSelectAllLocations ||
                                        selectedLocation.length ===
                                          location.length
                                      }
                                      onChange={(e) => {
                                        handleSelectAllLocations();
                                      }}
                                    />
                                    <p
                                      className={classNames(
                                        "m-0 cursor-pointer text-sm font-bold"
                                      )}
                                    >
                                      Select All
                                    </p>
                                  </label>
                                </th>
                                {filteredLt.map((location, index) => (
                                  <div
                                    className={classNames(
                                      "hover:bg-primarylightgrey",
                                      location.length - 1 && "border-b"
                                    )}
                                  >
                                    <CheckboxSelector
                                      header={location.name}
                                      isChecked={
                                        selectedLocation.includes(location.id)
                                          ? true
                                          : false
                                      }
                                      handleChange={(_e) =>
                                        handleLocationFilter(location.id, {
                                          selectAll: false,
                                          isSelectAll: !isSelectAllLocations,
                                        })
                                      }
                                      isBold={false}
                                    />
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="no-record-found">
                                No Location Found
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  );
                } else if (header === "Patient City") {
                  return (
                    <th
                      key={index}
                      className={
                        "px-2 py-3 text-base relative city min-w-48 " +
                        (selectedCity?.length > 0 || cityListOpen
                          ? "bg-highlight"
                          : "")
                      }
                      ref={dropdownRefCity}
                    >
                      <div className="flex flex-row items-center justify-center">
                        <p className="m-0">
                          {header} <span className="dropdown-icon ml-3"></span>
                        </p>
                        <Tooltip title="Sort">
                          <button className="pr-2" onClick={handleCityListOpen}>
                            <img
                              src={Icons.Sort_Icon}
                              alt="search"
                              className="w-5 h-5 cursor-pointer search-icon"
                            />
                          </button>
                        </Tooltip>
                        {cityListOpen && (
                          <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16">
                            {cities.length > 0 ? (
                              <>
                                <th
                                  className={classNames(
                                    "px-4 py-3 cursor-pointer text-sm"
                                  )}
                                >
                                  <label className="flex flex-row items-center gap-2 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className="w-4 h-4 checked:lightblue"
                                      checked={
                                        isSelectAllCity ||
                                        selectedCity.length === cities.length
                                      }
                                      onChange={(e) => {
                                        handleSelectAllCities();
                                      }}
                                    />
                                    <p
                                      className={classNames(
                                        "m-0 cursor-pointer text-sm font-bold"
                                      )}
                                    >
                                      Select All
                                    </p>
                                  </label>
                                </th>
                                {cities.map((city, index) => (
                                  <div
                                    className={classNames(
                                      "hover:bg-primarylightgrey",
                                      cities.length - 1 && "border-b"
                                    )}
                                  >
                                    <CheckboxSelector
                                      header={city}
                                      isChecked={
                                        selectedCity.includes(city)
                                          ? true
                                          : false
                                      }
                                      handleChange={(_e) =>
                                        handleCityNameFilter(city, {
                                          selectAll: false,
                                          isSelectAll: !isSelectAllCity,
                                        })
                                      }
                                      isBold={false}
                                    />
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="no-record-found ">
                                No Cities Found
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  );
                } else if (header === "Patient State") {
                  return (
                    <th
                      key={index}
                      className={
                        "px-2 py-3 text-base min-w-40 relative " +
                        (selectedStateName?.length > 0 || stateListOpen
                          ? "bg-highlight"
                          : "")
                      }
                      ref={dropdownRefState}
                    >
                      <div className="flex flex-row items-center justify-center">
                        <p className="m-0">
                          {header}
                          <span className="dropdown-icon ml-3"></span>
                        </p>
                        <Tooltip title="Sort">
                          <button
                            className="pr-2"
                            onClick={handleStateListOpen}
                          >
                            <img
                              src={Icons.Sort_Icon}
                              alt="search"
                              className="w-5 h-5 cursor-pointer search-icon"
                            />
                          </button>
                        </Tooltip>
                        {stateListOpen && (
                          <div className="absolute z-50 flex flex-col w-full no-scrollbar overflow-auto bg-white border  max-h-52 top-16">
                            {stateNames.length > 0 ? (
                              <>
                                <th
                                  className={classNames(
                                    "px-4 py-3 cursor-pointer text-sm"
                                  )}
                                >
                                  <label className="flex flex-row items-center gap-2 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className="w-4 h-4 checked:lightblue"
                                      checked={
                                        isSelectAllState ||
                                        selectedStateName.length ===
                                          stateNames.length
                                      }
                                      onChange={(e) => {
                                        handleSelectAllStates();
                                      }}
                                    />
                                    <p
                                      className={classNames(
                                        "m-0 cursor-pointer text-sm font-bold"
                                      )}
                                    >
                                      Select All
                                    </p>
                                  </label>
                                </th>
                                {stateNames.map((stateName, index) => (
                                  <div
                                    className={classNames(
                                      "hover:bg-primarylightgrey",
                                      stateName.length - 1 && "border-b"
                                    )}
                                  >
                                    <CheckboxSelector
                                      header={stateName}
                                      isChecked={
                                        selectedStateName.includes(stateName)
                                          ? true
                                          : false
                                      }
                                      handleChange={(_e) =>
                                        handleStateNameFilter(stateName, {
                                          selectAll: false,
                                          isSelectAll: !isSelectAllState,
                                        })
                                      }
                                      isBold={false}
                                    />
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="no-record-found ">
                                No States Found
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  );
                } else if (header === "Patient Id") {
                  return (
                    <th key={index} className="px-2 py-3 text-base">
                      <div className="d-flex flex-row items-center jusify-center relative ">
                        <div className="d-flex align-items-center w-full justify-center">
                          <p className="m-0">{header}</p>
                          {isPatientIdAse ? (
                            <Tooltip title="Sort Descending">
                              <ArrowDropUpIcon
                                style={{
                                  fontSize: 30,
                                  color: "#212529",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setIsPatientIdAse(false);
                                  const result = [...patientReports].sort(
                                    (a, b) =>
                                      a.patient_ehr_id - b.patient_ehr_id
                                  );
                                  setPatientReports(result);
                                }}
                                value="asc"
                                className="arrow-up"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Sort Ascending">
                              <ArrowDropDownIcon
                                style={{
                                  fontSize: 30,
                                  color: "#212529",
                                  cursor: "pointer",
                                }}
                                value="des"
                                className="arrow-down"
                                onClick={() => {
                                  setIsPatientIdAse(true);
                                  const result = [...patientReports].sort(
                                    (a, b) =>
                                      b.patient_ehr_id - a.patient_ehr_id
                                  );
                                  setPatientReports(result);
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </th>
                  );
                } else if (header === "Zip") {
                  return (
                    <th
                      key={index}
                      className="px-2 py-3 text-base min-w-28 jusify-center"
                    >
                      <div className="d-flex flex-row  jusify-center relative items-center">
                        <div className="d-flex align-items-center w-full justify-center">
                          <p className="m-0">{header}</p>
                          {isZipAse ? (
                            <Tooltip title="Sort Descending">
                              <ArrowDropUpIcon
                                style={{
                                  fontSize: 30,
                                  color: "#212529",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setIsZipAse(false);
                                  const result = [...patientReports].sort(
                                    (a, b) =>
                                      parseInt(a.zip.split("-")[0]) -
                                      parseInt(b.zip.split("-")[0])
                                  );
                                  setPatientReports(result);
                                }}
                                value="asc"
                                className="arrow-up"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Sort Ascending">
                              <ArrowDropDownIcon
                                style={{
                                  fontSize: 30,
                                  color: "#212529",
                                  cursor: "pointer",
                                }}
                                value="des"
                                className="arrow-down"
                                onClick={() => {
                                  setIsZipAse(true);
                                  const result = [...patientReports].sort(
                                    (a, b) =>
                                      parseInt(b.zip.split("-")[0]) -
                                      parseInt(a.zip.split("-")[0])
                                  );
                                  setPatientReports(result);
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </th>
                  );
                } else {
                  return (
                    <th key={index} className="px-2 py-3 text-base">
                      <div
                        className={`flex items-center  ${
                          header == "Patient Name"
                            ? "justify-start"
                            : "justify-center"
                        }`}
                      >
                        <p className="m-0">{header}</p>
                      </div>
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          {patientReports?.length > 0 ? (
            <tbody>
              {patientReports.map((patient, index) => {
                const isEven = (index + 1) % 2 === 0;
                return (
                  <tr
                    className={`text-base border-bottom text-left text-gray-900 border-bottom ${
                      isEven ? "bg-primarylightgrey" : ""
                    }`}
                  >
                    {/* <td className="px-2 py-3">
                                            {parseNull(newpatient.visit_date, "NA", "date")}
                                        </td> */}
                    <td className="px-2 py-3">{patient.patient_ehr_id}</td>
                    <td className="px-2 py-3 text-left">
                      {patient.patient_name}
                    </td>
                    <td className="px-2 py-3">{parseNull(patient.location)}</td>
                    <td className="px-2 py-3 city">
                      {parseNull(patient.city)}
                    </td>
                    <td className="px-2 py-3">{parseNull(patient.state)}</td>
                    <td className="px-2 py-3">{parseNull(patient.zip)}</td>
                    <td className="px-2 py-3">
                      {parseNull(patient.dob, "NA", "date")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <div
                className="d-flex align-Items-center justify-content-center absolute w-full my-5 py-3"
                style={{ zIndex: 0 }}
              >
                <p className="text-xl font-bold text-primarygrayborder">
                  {" "}
                  No Records Found
                </p>
              </div>
            </tbody>
          )}
        </table>
      </div>
    </>
  );
}
export default NewPatientsReportTable;
