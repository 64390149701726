import React, { useEffect, useState } from "react";
import MenuHeader from "../../../../components/Header/MenuHeader";
import SchedulingSettingsHeader from "../../components/SchedulingSettingsHeader";
import AppointmentTypeFilters from "./components/AppointmentTypeFilters";
import AppointmentTypeTable from "./components/AppointmentTypeTable";
import axios from "axios";
import AppointmentTypeModal from "./components/AppointmentTypeModal";
import { useFetchAppointmentTypesMutation } from "../../../../features/appointment/appointmentApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../features/auth/authSlice";

const AppointmentTypes = ({
  isWelcomePage = false,
  appointmentTypes,
  setAppointmentTypes,
}) => {
  const [opaque, setOpaque] = useState(false);
  const [data, setData] = useState(appointmentTypes || []);
  const [appointmentTypeIdToEdit, setAppointmentTypeIdToEdit] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [ehrFilter, setEhrFilter] = useState([]);

  const user = useSelector(selectUser);

  const handleNameFilter = (name) => {
    setNameFilter(name);
  };

  const handleEhrFilter = (ehr) => {
    if (ehrFilter.includes(ehr)) {
      setEhrFilter(ehrFilter.filter((filter) => filter !== ehr));
    } else {
      setEhrFilter([...ehrFilter, ehr]);
    }
  };

  const [getAppointmentTypes] = useFetchAppointmentTypesMutation();
  const fetchAppointmentTypes = async () => {
    try {
      const { data } = await getAppointmentTypes(user.organisation.id);
      setData(data.data);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const handleOpaque = (opaque) => {
    setOpaque(opaque);
  };

  const handleEditClick = (appointmentTypeId) => {
    if (editModalOpen) {
      setAppointmentTypeIdToEdit(null);
      handleOpaque(false);
    } else {
      setAppointmentTypeIdToEdit(appointmentTypeId);
      handleOpaque(true);
    }
    setEditModalOpen(!editModalOpen);
  };

  const filterAppointmentTypes = (appointmentType) => {
    return (
      appointmentType.appointmentName
        .toLowerCase()
        .includes(nameFilter.toLowerCase()) &&
      (ehrFilter.length === 0 || ehrFilter.includes(appointmentType.ehrName))
    );
  };

  useEffect(() => {
    fetchAppointmentTypes();
  }, []);
  return (
    <div
      className={`flex flex-col w-full relative ${
        isWelcomePage ? "" : "h-[100vh-110px]"
      }`}
    >
      {!isWelcomePage && <SchedulingSettingsHeader title="Visit Types" />}
      <div className="max-h-screen p-4 overflow-hidden">
        {!isWelcomePage && (
          <div className="flex flex-col w-full gap-2 mb-4">
            <div className="flex flex-row items-center justify-between">
              <p className="m-0 text-lg font-bold">
                You have {data?.length || 0} visit types
              </p>
            </div>
            <AppointmentTypeFilters
              appointments={data}
              handleNameFilter={handleNameFilter}
              handleEhrFilter={handleEhrFilter}
              nameFilter={nameFilter}
              ehrFilter={ehrFilter}
            />
          </div>
        )}
        <AppointmentTypeTable
          isWelcomePage={isWelcomePage}
          appointments={data?.filter(filterAppointmentTypes)}
          handleEditClick={handleEditClick}
        />
      </div>
      {editModalOpen && (
        <AppointmentTypeModal
          appointments={data}
          handleEditClick={handleEditClick}
          details={data.find(
            (appointmentType) => appointmentType.id === appointmentTypeIdToEdit
          )}
          setAppointmentTypes={setData}
        />
      )}
    </div>
  );
};

export default AppointmentTypes;
