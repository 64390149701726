import React from "react";
import ReusableModal from "../../../components/Modals/ReusableModal";
import Lock from "../../../assets/svgs/Lock";
import Unlock from "../../../assets/svgs/Unlock";
import classNames from "classnames";
import { Icon } from "@virtuslab/tetrisly-icons";
import CheckSvg from "../../../assets/svgs/CheckSvg";

const AppointmentStatusModal = ({
  open,
  handleCancel,
  handleSubmit,
  status,
}) => {
  return (
    <ReusableModal open={open} hideHeader>
      <div className="flex flex-col gap-3 p-3">
        <div className="flex flex-row gap-2">
          <div
            className={classNames(
              "flex items-center justify-center w-10 h-10 rounded-full",
              status === "SCHEDULED" ? "bg-primaryblue" : "bg-primarygreen"
            )}
          >
            {status === "SCHEDULED" ? (
              <Icon name="20-calendar-check" color={"#fff"} />
            ) : (
              <CheckSvg color={"#fff"} />
            )}
          </div>
          <div className="flex flex-col">
            <p className="m-0 font-semibold">
              {status === "SCHEDULED" ? "Confirm" : "Complete"} Appointment
            </p>
            <p className="m-0 font-light">
              {status === "SCHEDULED" ? (
                <>
                  Are you sure you want to mark this appointment as confirmed?
                </>
              ) : (
                <>
                  Are you sure you want to mark this appointment as completed?
                </>
              )}
            </p>
          </div>
        </div>
        <div className="h-[1px] w-full bg-primarygrayborder" />
        <div className="flex flex-row items-center justify-end gap-3">
          <button
            onClick={handleCancel}
            className="px-4 py-2 font-medium border rounded-md bg-primarylightgrey border-primarygray"
          >
            Cancel
          </button>
          <button
            className={classNames(
              "px-4 py-2 font-medium text-white rounded-md",
              status === "SCHEDULED"
                ? "bg-primaryblue border-[1px] border-primaryblue"
                : "bg-primarygreen border-[1px] border-primarygreen"
            )}
            onClick={async () => {
              await handleSubmit();
              handleCancel();
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default AppointmentStatusModal;
