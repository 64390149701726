import React from "react";
import { Icons } from "../../assets";
import { Toolbar, Tooltip } from "@mui/material";

const IconComponent = ({
  i,
  icon,
  tooltip,
  disabled,
  style,
  onClick = () => {},
}) => {
  return (
    <Tooltip title={tooltip || icon}>
      <button key={i.id} onClick={onClick} disabled={disabled} style={style}>
        {Icons[icon] && (
          <img src={Icons[icon]} alt="icon" style={{ width: 18, height: 18 }} />
        )}
      </button>
    </Tooltip>
  );
};

export default IconComponent;
