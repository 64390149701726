import { Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TagModal from "../Modals/TagModal";
import ForwardModal from "../Modals/ForwardModal";
import useMessenger from "../../hooks/useMessenger";
// import { customToast } from "../../utilities/resuseFunctions";
import Loading from "../Loading";
import TagPatientModal from "../Modals/TagPatientModal";
import DeleteModal from "../Modals/DeleteModal";
import PatientCaseCreatedModal from "../Modals/PatientCaseCreated";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { SocketContext } from "../../context/SocketProvider";
import { v4 } from "uuid";
import { useFetchConversationPatientsMutation } from "../../features/chat/chatApiSlice";

// const DeleteModal = ({ warning, handleWarning, deleteMessage, deleteMsg }) => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//     >
//       <Dialog open={warning} onClose={handleWarning}>
//         <p
//           style={{
//             paddingInline: "12px",
//           }}
//         >
//           Deleting Message?
//         </p>
//         <p
//           style={{
//             paddingInline: "12px",
//           }}
//         >
//           This message will be removed from everyone's history!
//         </p>
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "center",
//           }}
//         >
//           <p
//             style={{
//               flex: 1,
//               textAlign: "center",
//               borderRight: "solid",
//               borderColor: "red",
//               borderWidth: "0.5px",
//               cursor: "pointer",
//             }}
//             className="leave-text"
//             onClick={() => {
//               handleWarning();
//             }}
//           >
//             Cancel
//           </p>
//           <p
//             style={{
//               flex: 1,
//               textAlign: "center",
//               cursor: "pointer",
//             }}
//             onClick={() => {
//               deleteMessage(deleteMsg.msgID, deleteMsg.convID);
//               handleWarning();
//             }}
//           >
//             Delete
//           </p>
//         </div>
//       </Dialog>
//     </div>
//   );
// };

const MessageOptions = ({
  color,
  options,
  message,
  data,
  noSendToAthena,
  tagPatients = [],
}) => {
  const [isApplyTag, setIsApplyTag] = useState(false);
  const [isApplyPatientTag, setIsApplyPatientTag] = useState(false);
  const [forward, setForward] = useState(false);
  const [isMarkedUrgent, setIsMarkedUrgent] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);
  const [warning, setWarning] = useState(false);

  const [deleteMsg, setDeleteMsg] = useState({});

  const [caseModalOpen, setCaseModalOpen] = useState(false);

  const isStarred = message?.is_starred;

  const indexGroups = Object.keys(message?.content?.meta || {})?.map((m) => {
    const [startIndex, endIndex] = m.split("-");
    return {
      startIndex,
      endIndex,
      type: message?.content?.meta?.[m]?.type,
      id: message?.content?.meta?.[m]?.id,
    };
  });

  const taggedPatients = indexGroups?.filter((patient) => {
    return patient.type === "PATIENT";
  });

  const taggedCareTags = indexGroups?.filter((tag) => {
    return tag.type === "CARETAG";
  });

  const isTags = !!taggedCareTags?.length;
  const isPatientTags = !!taggedPatients?.length;
  const isPending = !message?.is_sent_to_ehr;

  const user = useSelector(selectUser);

  const {
    sendMessage,
    currentChat,
    setCurrentChat,
    createConversation,
    onStarMessage,
    onUnstarMessage,
    markUrgent,
    chats,
    setEditMessage,
    deleteMessage,
    updateMessage,
    uploadEHR,
  } = useContext(SocketContext);
  const [fetchConversationPatients] = useFetchConversationPatientsMutation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      // customToast("Message Copied to Clipboard", "success");
      // setCopySuccess("Copied!");
    } catch (err) {
      // setCopySuccess("Failed to copy!");
    }
  };

  const onForward = async (selected) => {
    await selected.map(async (i) => {
      if (i.conversationId) {
        const { id, sender, ...rest } = message;
        sendMessage({
          message: {
            tempId: v4(),
            conversationId: i.conversationId,
            isForwarded: true,
            isUrgent: message.is_urgent,
            isEdited: message.is_edited,
            attachmentId: message.attachment_id,
            content: message.content,
          },
          userIds: i.participants
            ?.filter((p) => p.user_id !== user.id)
            ?.map((d) => d.user_id),
          meta: {
            senderName: user.details?.name || "",
            groupName:
              i.conversationType === "GROUP"
                ? i.name
                : user.details?.name || "",
          },
        });
      } else {
        const { id, ...rest } = message;
        const data = await createConversation({
          userIds: [selected.id],
        });
        sendMessage({
          message: {
            tempId: v4(),
            conversationId: data.id,
            isForwarded: true,
            isUrgent: message.is_urgent,
            isEdited: message.is_edited,
            attachmentId: message.attachment_id,
            content: message.content,
          },
          userIds: [selected.id],
          meta: {
            senderName: user.details?.name || "",
            groupName:
              i.conversationType === "GROUP"
                ? i.name
                : user.details?.name || "",
          },
        });
      }
    });
    setForward(false);
    handleClose();
    // customToast("Message Forwarded", "success");
  };

  const handleApplyTag = (tag) => {
    const meta = message?.content?.meta ? { ...message?.content?.meta } : {};
    const len = message.content?.message?.length;
    const tagName = " #" + tag.name;
    meta[`${len}-${len + tagName.length}`] = {
      type: "CARETAG",
      id: tag.id,
    };
    updateMessage({
      message: {
        id: message.id,
        conversationId: message.conversationId,
        isForwarded: message.isForwarded,
        isEdited: message.isEdited,
        isUrgent: message.isUrgent,
        attachmentId: message?.attachment_id,
        attachment_id: message?.attachment_id,
        content: {
          type: "MESSAGE",
          message: message.content.message + tagName,
          meta,
        },
        sender_id: message.sender_id,
        createdAt: new Date(message.createdAt),
      },
      userIds: data.participants
        ?.filter((p) => p.user_id !== user.id)
        ?.map((d) => `USER:${d.user_id}`),
      meta: {
        senderName: user.details?.name || "",
        groupName:
          data.conversationType === "GROUP"
            ? data.name
            : user.details?.name || "",
      },
      updateMsg: {
        ...message,
        content: {
          type: "MESSAGE",
          message: `${message.content.message}${tagName}`,
          meta,
        },
      },
    });
  };

  const handleApplyPatientTag = ({ patientId, patientName }) => {
    const meta = message?.content?.meta ? { ...message?.content?.meta } : {};
    const len = message.content?.message?.length;
    const pat = ` <${patientName}>`;
    meta[`${len}-${len + pat.length}`] = {
      type: "PATIENT",
      id: patientId,
    };
    console.log(message, "message");
    updateMessage({
      message: {
        id: message.id,
        conversationId: message.conversationId,
        isForwarded: message.isForwarded,
        isEdited: message.isEdited,
        isUrgent: message.isUrgent,
        attachmentId: message?.attachment_id,
        attachment_id: message?.attachment_id,
        content: {
          type: "MESSAGE",
          message: `${message.content.message}${pat}`,
          meta,
        },
        sender_id: message.sender_id,
        createdAt: new Date(message.createdAt),
      },
      userIds: data.participants
        ?.filter((p) => p.user_id !== user.id)
        ?.map((d) => `USER:${d.user_id}`),
      meta: {
        senderName: user.details?.name || "",
        groupName:
          data.conversationType === "GROUP"
            ? data.name
            : user.details?.name || "",
      },
      updateMsg: {
        ...message,
        content: {
          type: "MESSAGE",
          message: `${message.content.message}${pat}`,
          meta,
        },
      },
    });
  };

  const handleMarkedModal = () => setIsMarkedUrgent((prev) => !prev);

  const handleMarkUrgent = () => {
    const chat = chats?.find(
      (c) => c.conversationId === message.conversation_id
    );
    const { sender, ...rest } = message;
    markUrgent({
      message: {
        ...rest,
        isUrgent: true,
      },
      userIds: chat?.participants
        ?.map((m) => m.user_id)
        ?.filter((m) => {
          return m !== user.id;
        }),
      meta: {
        senderName: user.details?.name || "",
        groupName:
          data.conversationType === "GROUP"
            ? data.name
            : user.details?.name || "",
      },
      updateMsg: {
        ...message,
        is_urgent: true,
      },
    });
  };

  const handleWarning = (msg) => {
    // setWarning(!warning);
    if (msg?.id !== undefined && msg?.conversation_id !== undefined) {
      setDeleteMsg(msg);
      setWarning(true);
    } else {
      setDeleteMsg({});
      setWarning(false);
    }
  };

  const handleDelete = () => {
    deleteMessage({
      message: deleteMsg,
      userIds: data.participants
        ?.filter((p) => p.user_id !== user.id)
        ?.map((d) => `USER:${d.user_id}`),
      meta: {
        senderName: user.details?.name || "",
        groupName:
          data.conversationType === "GROUP"
            ? data.name
            : user.details?.name || "",
      },
    });
    handleWarning();
  };
  const sendToEHR = async () => {
    try {
      await uploadEHR({
        message,
      });
      setCaseModalOpen(true);
    } catch (error) {
      console.log(error, "err-send-to-eHR");
    }
  };

  const handleStarMessage = async () => {
    try {
      await onStarMessage({
        id: message.id,
      });
      // customToast("Starred Message", "success");
    } catch (error) {
      console.log(error, "err-star-message");
    }
  };

  const handleUnstarMessage = async () => {
    try {
      await onUnstarMessage({
        id: message.id,
      });
      // customToast("Message Unstarred", "success");
    } catch (error) {
      console.log(error, "err-star-message");
    }
  };

  return (
    <>
      {warning && (
        <DeleteModal
          open={warning}
          handleClose={handleWarning}
          handleDelete={handleDelete}
          title={"Deleting Message?"}
          subtext={"This message will be removed from everyone's history!"}
        />
      )}
      {isMarkedUrgent && (
        <DeleteModal
          open={isMarkedUrgent}
          handleClose={handleMarkedModal}
          handleDelete={handleMarkUrgent}
          title={"Mark this message as Urgent?"}
          subtext={"This message will be shown as urgent to everyone!"}
          primaryText={"Yes"}
          secondaryText={"No"}
        />
      )}
      {/* {loading && <Loading loading={loading} />} */}
      {isApplyTag && (
        <TagModal
          open={isApplyTag}
          handleClose={() => setIsApplyTag(false)}
          handleSubmit={handleApplyTag}
        />
      )}
      {isApplyPatientTag && (
        <TagPatientModal
          tagPatients={tagPatients}
          open={isApplyPatientTag}
          handleClose={() => setIsApplyPatientTag(false)}
          handleSubmit={handleApplyPatientTag}
        />
      )}
      {forward && (
        <ForwardModal
          open={forward}
          handleClose={() => setForward(false)}
          onSubmit={onForward}
        />
      )}
      {caseModalOpen && (
        <PatientCaseCreatedModal
          open={caseModalOpen}
          handleClose={() => setCaseModalOpen(false)}
        />
      )}
      <Tooltip title="Options">
        <KeyboardArrowDownIcon
          role="button"
          id="chat-button"
          aria-controls={open ? "chat-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="options-menu"
          style={{ color: color || "black" }}
        />
      </Tooltip>
      <Menu
        id="basic-menu"
        className="w-100"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options
          ?.filter((i) => {
            return isTags
              ? isPending && !noSendToAthena
                ? isPatientTags
                  ? ![5, 8, 7].includes(i.id)
                  : ![5, 6, 8].includes(i.id)
                : noSendToAthena
                ? !isPatientTags
                  ? ![5, 8, 6].includes(i.id)
                  : ![5, 6, 7, 8].includes(i.id)
                : ![5, 6, 7].includes(i.id)
              : isPatientTags
              ? ![8, 7, 6].includes(i.id)
              : ![8, 6].includes(i.id);
          })
          ?.filter((x) => x.id !== 4 || message.sender_id === user.id)
          ?.filter(
            (x) =>
              x.id !== 11 ||
              (message.sender_id === user.id &&
                moment().diff(message?.createdAt, "minutes") < 15)
          )
          ?.filter((x) => (!!isStarred ? x.id !== 1 : x.id !== 9))
          ?.filter((x) =>
            !!message.isUrgent || !!message.is_urgent ? x.id !== 10 : true
          )
          .map((item, i) => {
            const Icon = item.icon;
            return (
              <MenuItem
                key={i}
                // disabled={item.disabled}
                onClick={async () => {
                  try {
                    if (item?.id === 5) {
                      setIsApplyTag(true);
                    }
                    if (item?.id === 7) {
                      setIsApplyPatientTag(true);
                    }
                    if (item?.id === 6) {
                      sendToEHR();
                    }
                    if (item?.id === 10) {
                      handleMarkedModal();
                    }
                    if (item?.id === 11) {
                      setEditMessage(message);
                      handleClose();
                    }
                    if (item?.id === 2) {
                      setForward(true);
                    }
                    if (item?.id === 1) {
                      handleStarMessage();
                    }
                    if (item?.id === 9) {
                      handleUnstarMessage();
                    }
                    if (item?.id === 4) {
                      handleWarning(message);
                      // deleteMessage(message.id, data.conversationId);
                    }
                    if (item?.id === 3) {
                      copyToClipBoard(message?.content.message);
                      handleClose();
                    }
                    // handleSelect(item);
                    handleClose();
                  } catch (error) {
                    handleClose();
                  }
                }}
                style={{ color: item.color }}
              >
                <Icon className="mx-2" color={item.color} />{" "}
                <span className="mx-2">{item.name}</span>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};

export default MessageOptions;
