export const REQUEST_TIME_OUT_ERROR_MESSAGE = "Request timed out";
export const ALL_ZONES = "All Zones";

export const colors = [
  "#2F80ED",
  "#e2c391",
  "#1D7C4D",
  "#a8b7ab",
  "#9bbec7",
  "#C0C999",
  "#470063",
  "#b30089",
  "#b4adea",
  "#50514f",
  "#0c4767",
  "#566e3d",
];
