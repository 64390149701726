import React from "react";
import classNames from "classnames";
import { Search_Icon } from "../../../assets/images";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import {
  ArrowDownwardTwoTone,
  ArrowUpwardTwoTone,
  FilterList,
} from "@mui/icons-material";
import CheckboxSelector from "../../Schedules/components/CheckboxSelector";
import IconComponent from "../../../components/DataTable/Icon";
import ZoneModal from "../Zones/components/ZoneModal";
import {
  useCreateZoneMutation,
  useFetchZonesMutation,
  useUpdateZoneMutation,
} from "../../../features/zones/zonesApiSlice";
import { Tooltip } from "@mui/material";
import Loading from "../../../components/Loading";
import Search from "../../../components/Search/SearchNew";
import FilterSelect from "../../../components/Filter/FilterSelect";
// import { useGetAllZonesMutation } from "../../../features/zones/zonesApiSlice";

const ZoneList = () => {
  const [selectedZone, setSelectedZone] = React.useState(null);
  const [zoneModalOpen, setZoneModalOpen] = React.useState(false);

  const [statusFilter, setStatusFilter] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState("");

  const [statuses, setStatuses] = React.useState([]);

  const [sortStatusOrder, setSortStatusOrder] = React.useState("none");
  const [sortNameOrder, setSortNameOrder] = React.useState("none");

  const [zones, setZones] = React.useState([]);

  const [getZones, { isLoading }] = useFetchZonesMutation();

  const [edit, { isLoading: editLoading }] = useUpdateZoneMutation();

  const [create, { isLoading: createLoading }] = useCreateZoneMutation();

  const loading = isLoading || createLoading || editLoading;

  const handleSortStatus = (a, b) => {
    if (sortStatusOrder === "none") {
      return 0;
    } else if (sortStatusOrder === "asc") {
      return a.status.localeCompare(b.status);
    } else {
      return b.status.localeCompare(a.status);
    }
  };

  const handleSortName = (a, b) => {
    if (sortNameOrder === "none") {
      return 0;
    } else if (sortNameOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  };

  const handleStatusFilter = (status) => {
    if (statusFilter.indexOf(status) === -1) {
      setStatusFilter([...statusFilter, status]);
    } else {
      setStatusFilter(statusFilter.filter((filter) => filter !== status));
    }
  };

  const fetchZones = async () => {
    try {
      const { data } = await getZones().unwrap();
      setZones(
        data?.map((zone) => {
          return {
            ...zone,
            zipCodes: zone.zip_codes,
          };
        })
      );
      const statuses = Array.from(new Set(data.map((zone) => zone.status)));
      setStatuses(statuses);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    const statuses = Array.from(new Set(zones.map((zone) => zone.status)));

    setStatuses(statuses);
  }, [zones]);

  React.useEffect(() => {
    fetchZones();
  }, []);

  return (
    <div className="flex flex-col items-start py-4 overflow-hidden">
      <Loading loading={loading} />
      <div className="flex flex-row items-center justify-between w-full px-16">
        <div className="flex flex-row items-center w-full gap-12">
          <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
            Zones{" "}
            <span className="ml-2 text-base font-bold text-primaryblue">
              {zones.length}
            </span>
          </h1>
          <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
            <Search value={searchFilter} setValue={setSearchFilter} />
            <FilterSelect
              value={statusFilter}
              handleChange={handleStatusFilter}
              data={statuses}
              placeholder="Filter by Status ..."
            />
          </div>
        </div>
        <button
          onClick={() => {
            setZoneModalOpen(true);
          }}
          className="flex items-center justify-center px-4 py-2 text-sm font-bold text-white rounded-lg bg-primaryblue whitespace-nowrap"
        >
          Add Zone
        </button>
      </div>
      <div className="w-full px-16">
        <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow max-h-[calc(100vh-200px)]">
          <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
            <thead>
              <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Name</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortNameOrder === "none") {
                          setSortNameOrder("asc");
                        }
                        if (sortNameOrder === "asc") {
                          setSortNameOrder("desc");
                        }
                        if (sortNameOrder === "desc") {
                          setSortNameOrder("none");
                        }
                      }}
                    >
                      {sortNameOrder === "asc" ? (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardTwoTone />
                        </Tooltip>
                      ) : sortNameOrder === "desc" ? (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardTwoTone />
                        </Tooltip>
                      ) : (
                        <div className="flex items-center h-4">
                          <div className="w-6 h-[2px] bg-white" />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Zipcodes</p>
                  </div>
                </th>
                <th className="px-4 py-3">Edit</th>
              </tr>
            </thead>
            <tbody>
              {zones
                .filter((zone) =>
                  zone.name.toLowerCase().includes(searchFilter.toLowerCase())
                )
                .filter(
                  (zone) =>
                    statusFilter.length === 0 ||
                    statusFilter.indexOf(zone.status) !== -1
                )
                .sort((a, b) => handleSortName(a, b))
                .sort((a, b) => handleSortStatus(a, b))
                .map((zone, index) => (
                  <tr
                    className={classNames(
                      "text-left text-gray-900 h-24 whitespace-nowrap",
                      index !== zones.length - 1 ? "border-b" : "border-none"
                    )}
                    key={index}
                  >
                    <td className="px-4 py-3 text-left">
                      <div className="flex flex-row items-center gap-4">
                        <p className="m-0 font-semibold underline">
                          {zone.name}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {zone.zipCodes?.join(", ")}
                    </td>
                    <td className="px-4 py-3 text-left">
                      <button
                        onClick={() => {
                          setSelectedZone(zone);
                          setZoneModalOpen(true);
                        }}
                        className="text-white rounded-md"
                      >
                        <IconComponent
                          tooltip="Edit Zone"
                          icon="Edit_Icon"
                          i={zone.id}
                          key={zone.id}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <ZoneModal
        type={selectedZone ? "update" : "create"}
        open={zoneModalOpen}
        setOpen={setZoneModalOpen}
        data={selectedZone}
        setData={setSelectedZone}
        zones={zones}
        setZones={setZones}
        edit={edit}
        create={create}
      />
    </div>
  );
};

export default ZoneList;
