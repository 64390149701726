import moment from "moment";
import React from "react";
import Select from "react-select";
import { Forward_Icon } from "../../assets/images";
import {
  useClosestPatientAppointmentMutation,
  useFetchAppointmentsMutation,
  useFetchAppointmentTypesMutation,
} from "../../features/appointment/appointmentApiSlice";
import classNames from "classnames";
import CloseSvg from "../../assets/svgs/CloseSvg";
import PatientSchedulingModal from "./PatientSchedulingModal";
import { useGetNewPatientsMutation } from "../../features/patient/patientApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";

const options = [
  { value: 1, label: "Least distance added" },
  { value: 2, label: "Least total distance" },
  { value: 3, label: "Least appointments" },
  { value: 4, label: "Smallest patient panel" },
];

const PatientSchedulingWizard = () => {
  const [getClosestPatientAppointment] = useClosestPatientAppointmentMutation();

  const [providerAppointments, setProviderAppointments] = React.useState([]);

  const [filterOpen, setFilterOpen] = React.useState(false);

  const [dates, setDates] = React.useState([]);

  const [dateIndex, setDateIndex] = React.useState(0);

  const [startDate, setStartDate] = React.useState(null);

  const [endDate, setEndDate] = React.useState(null);

  const user = useSelector(selectUser);

  const fetchProviders = async () => {
    try {
      const { data } = await getClosestPatientAppointment({
        date: moment(dates[dateIndex]).format("YYYY-MM-DD"),
        patientId: selectedPatient.value,
      });

      setProviderAppointments(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [providerAppointment, setProviderAppointment] = React.useState(null);

  const [appointments, setAppointments] = React.useState([]);

  const [fetchAppointments] = useFetchAppointmentsMutation();

  const [patients, setPatients] = React.useState([]);

  const [selectedPatient, setSelectedPatient] = React.useState(null);

  const [getPatients] = useGetNewPatientsMutation();

  const [getAppointmentTypes] = useFetchAppointmentTypesMutation();

  const [appointmentTypes, setAppointmentTypes] = React.useState([]);

  const fetchAppointmentTypes = async () => {
    try {
      const { data } = await getAppointmentTypes(user.organisation.id);

      setAppointmentTypes(
        data.data.map((type) => {
          return {
            value: type.id,
            label: type.appointmentName,
          };
        })
      );
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const fetchPatients = async () => {
    try {
      const { data } = await getPatients({
        organisationId: user.organisation.id,
      }).unwrap();

      setPatients(
        data.map((patient) => ({ value: patient.id, label: patient.name }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAppointmentsFunction = async (providerId) => {
    try {
      const { data } = await fetchAppointments({
        providerId: providerId,
        appointmentType: "all",
        locationType: "all",
        locationId: null,
        patientId: null,
        startDate: moment(dates[dateIndex]).format("YYYY-MM-DD"),
        endDate: moment(dates[dateIndex]).format("YYYY-MM-DD"),
      });

      const appointments = Object.values(data.data)[0];

      setAppointments(appointments);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    fetchPatients();
    fetchAppointmentTypes();
  }, []);

  React.useEffect(() => {
    if (filterOpen) {
      document.body.style.overflow = "hidden";
      window.scrollTo(0, 0);
    }

    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [filterOpen]);

  React.useEffect(() => {
    if (dates.length > 0 && selectedPatient !== null) {
      fetchProviders();
    } else {
      setProviderAppointments([]);
    }
  }, [dates, dateIndex, selectedPatient]);

  React.useEffect(() => {
    if (startDate !== null && endDate !== null) {
      const start = moment(startDate);
      const end = moment(endDate);

      const dates = [];

      while (start <= end) {
        dates.push(start.format("YYYY-MM-DD"));
        start.add(1, "days");
      }

      setDates(dates);
    }
  }, [startDate, endDate]);

  const [selectedOption, setSelectedOption] = React.useState(options[0].value);

  return (
    <div className="flex flex-col h-screen gap-4 mt-8">
      <h1 className="px-8 m-0 text-2xl font-bold">
        New Patient Scheduling Optimizer
      </h1>
      <div className="flex items-end justify-start gap-4 px-8 pb-4">
        <div className="flex flex-col w-2/6 gap-2">
          <label htmlFor="patientSelect" className="text-sm font-bold">
            Select New Patient
          </label>
          <div className="w-full">
            <Select
              options={patients}
              isSearchable
              isClearable
              onChange={(selectedOption) => {
                setSelectedPatient(selectedOption);
              }}
            />
          </div>
        </div>
        <div className="relative flex flex-col w-1/6 gap-2">
          <div className="flex justify-between">
            <label className="text-sm font-bold">Start date</label>
            <button
              onClick={() => {
                setStartDate(moment().format("YYYY-MM-DD"));
              }}
            >
              <p className="m-0 text-sm font-medium text-primaryblue">
                Set current date
              </p>
            </button>
          </div>
          <input
            type="date"
            value={startDate}
            placeholder="dd/mm/yyyy"
            min={moment().format("YYYY-MM-DD")}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full h-[38px] p-2 border rounded-md border-primarygrayborder"
          />
        </div>
        <div className="relative flex flex-col w-1/6 gap-2">
          <label htmlFor="endDate" className="text-sm font-bold">
            End Date
          </label>
          <input
            type="date"
            value={endDate}
            placeholder="dd/mm/yyyy"
            min={moment().format("YYYY-MM-DD")}
            onChange={(e) => setEndDate(e.target.value)}
            className="w-full h-[38px] p-2 border rounded-md border-primarygrayborder"
          />
        </div>
      </div>
      <div className="w-full h-[1px] bg-gray-200" />
      <div className="flex flex-col gap-8 px-8 pt-4 overflow-auto">
        {dates.length > 0 && selectedPatient !== null && (
          <div className="flex flex-row items-end">
            <div className="flex items-center w-1/2 gap-8">
              <button
                disabled={dateIndex === 0}
                onClick={() => {
                  setDateIndex(dateIndex - 1);
                }}
                className="relative flex items-center justify-center w-10 rounded-md group aspect-square bg-primarylightgrey disabled:opacity-40"
              >
                <img
                  src={Forward_Icon}
                  alt="Filter"
                  className="object-contain w-5 h-5 rotate-180"
                />
                <p className="absolute px-2 py-1 text-xs transition-all duration-150 rounded-md opacity-[0%] -z-40 whitespace-nowrap -bottom-14 group-hover:z-50 group-hover:opacity-100 bg-primarylightgrey border-[0.5px] border-primarygrayborder shadow-sm">
                  Previous Day
                </p>
              </button>
              <p className="m-0 text-2xl font-bold">
                {moment(dates[dateIndex]).format("dddd MMMM Do, YYYY")}
              </p>
              <button
                onClick={() => {
                  setDateIndex(dateIndex + 1);
                }}
                disabled={dateIndex === dates.length - 1}
                className="relative flex items-center justify-center w-10 rounded-md group aspect-square bg-primarylightgrey disabled:opacity-40"
              >
                <img
                  src={Forward_Icon}
                  alt="Filter"
                  className="object-contain w-5 h-5"
                />
                <p className="absolute px-2 py-1 text-xs transition-all duration-150 rounded-md opacity-[0%] -z-40 whitespace-nowrap -bottom-14 group-hover:z-50 group-hover:opacity-100 bg-primarylightgrey border-[0.5px] border-primarygrayborder shadow-sm">
                  Next Day
                </p>
              </button>
            </div>
            <div className="flex flex-col w-1/2 gap-1">
              <label htmlFor="patientSelect" className="text-sm font-bold">
                Sort By:
              </label>
              <div className="w-1/2">
                <Select
                  options={options}
                  onChange={(selectedOption) => {
                    if (selectedOption.value === 1) {
                      setProviderAppointments(
                        [...providerAppointments].sort(
                          (a, b) => a.difference - b.difference
                        )
                      );
                    } else if (selectedOption.value === 2) {
                      setProviderAppointments(
                        [...providerAppointments].sort(
                          (a, b) => a.distance - b.distance
                        )
                      );
                    } else if (selectedOption.value === 3) {
                      setProviderAppointments(
                        [...providerAppointments].sort(
                          (a, b) => a.appointments - b.appointments
                        )
                      );
                    } else {
                      setProviderAppointments(
                        [...providerAppointments].sort(
                          (a, b) => a.provider.panelSize - b.provider.panelSize
                        )
                      );
                    }

                    setSelectedOption(selectedOption);
                  }}
                  defaultValue={options[0]}
                />
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col w-1/2 gap-4 py-4">
          {providerAppointments.map((providerAppointment, index) => (
            <div
              className="flex flex-row items-start justify-start gap-4"
              key={index}
            >
              <div
                className={classNames(
                  "flex items-center justify-center h-12 rounded-full min-w-12",
                  index === 0
                    ? "bg-primarygreen text-white"
                    : "bg-gray-200 text-black"
                )}
              >
                <p
                  className={classNames(
                    "m-0 text-lg font-bold",
                    index === 0 ? "text-white" : "text-black"
                  )}
                >
                  {index + 1}
                </p>
              </div>
              <div className="flex flex-col justify-between w-full bg-white border border-gray-200 rounded-md">
                <div className="flex items-center justify-between p-4">
                  <div className="flex items-center gap-4">
                    <div className="flex flex-col">
                      <p className="m-0 text-xl font-bold">
                        {providerAppointment.provider.name}
                      </p>
                      <p className="m-0 text-gray-400">
                        {providerAppointment.provider.speciality},{" "}
                        {providerAppointment.provider.panelSize} Patients
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setFilterOpen(true);
                      setProviderAppointment(providerAppointment);
                      fetchAppointmentsFunction(
                        providerAppointment.provider.id
                      );
                    }}
                    className="relative flex items-center justify-center w-12 h-12 rounded-md group aspect-square bg-primarylightgrey disabled:opacity-40"
                  >
                    <img
                      src={Forward_Icon}
                      alt="Filter"
                      className="object-contain w-5 h-5"
                    />
                    <p className="absolute px-2 py-1 text-xs transition-all duration-150 rounded-md opacity-[0%] -z-40 whitespace-nowrap -bottom-14 group-hover:z-50 group-hover:opacity-100 bg-primarylightgrey border-[0.5px] border-primarygrayborder shadow-sm">
                      Show Schedule
                    </p>
                  </button>
                </div>
                <div className="w-full h-[1px] bg-gray-200" />
                <div className="grid grid-cols-2 gap-4 p-4">
                  <p className="m-0">
                    <span className="font-bold">No. of scheduled visits:</span>{" "}
                    {providerAppointment.appointments}
                  </p>
                  <p className="m-0">
                    <span className="font-bold">Total route distance:</span>{" "}
                    {providerAppointment.distance} miles
                  </p>
                  <p className="m-0">
                    <span className="font-bold">Proposed sequence:</span>{" "}
                    {providerAppointment.position} of{" "}
                    {providerAppointment.appointments + 1}
                  </p>
                  <p className="m-0">
                    <span className="font-bold">Distance added:</span> +
                    {providerAppointment.difference}
                    miles
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {filterOpen && providerAppointment !== null && (
        <div className="absolute top-0 w-screen h-full bg-black opacity-35 overflow-clip" />
      )}
      {providerAppointment !== null && (
        <PatientSchedulingModal
          filterOpen={filterOpen}
          providerAppointment={providerAppointment}
          setFilterOpen={setFilterOpen}
          setProviderAppointment={setProviderAppointment}
          appointments={appointments}
          setAppointments={setAppointments}
          dates={dates}
          dateIndex={dateIndex}
          patientId={selectedPatient.value}
          appointmentTypes={appointmentTypes}
        />
      )}
    </div>
  );
};

export default PatientSchedulingWizard;
