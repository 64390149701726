import React from "react";
import LongitudinalSchedules from "../../../assets/images/longitudinal-schedules.png";

const RightBanner = () => {
  return (
    <div className="hidden w-5/12 py-24 overflow-hidden sm:block base:py-36 bg-primarygreen">
      <div className="flex flex-col w-full gap-12 mx-24 base:gap-16">
        <div className="flex flex-col w-full gap-2 m-0 text-left">
          <h1 className="m-0 text-2xl font-bold text-white">
            CareSMS Scheduling
          </h1>
          <h2 className="m-0 text-base font-bold">
            Start creating optimized schedules for your Providers in seconds,
            <br />
            not hours with the CareSMS Scheduling Assistant.
          </h2>
        </div>
        <div className="aspect-video">
          <img
            src={LongitudinalSchedules}
            alt="Calendar View"
            width={960}
            className="object-cover object-top border-2 border-black rounded-2xl aspect-video"
          />
        </div>
      </div>
    </div>
  );
};

export default RightBanner;
