import React, { useContext, useState } from "react";
import {
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  Box,
  Divider,
  ListItemIcon,
  Avatar,
  Typography,
  Collapse,
  Toolbar,
  Menu,
  Tooltip,
  Button,
  MenuItem,
  Badge,
} from "@mui/material";
import "./MenuHeader.css";
import { useLocation, useNavigate } from "react-router-dom";
import menuOptions from "./menuOptions";
import Down from "@mui/icons-material/ArrowDropDownOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import DownArrow from "@mui/icons-material/KeyboardArrowDownRounded";
import Logo from "../../assets/images/CareSMS_Logo.png";
import UserSvg from "../../assets/svgs/user";
import LogoutSvg from "../../assets/svgs/LogoutSvg";
import { useDispatch } from "react-redux";
import { logOut } from "../../features/auth/authSlice";
import { useLogoutMutation } from "../../features/auth/authApiSlice";
import { Wallet } from "@mui/icons-material";
import NotificationsModal from "../Modals/NotificationsModal";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import { SocketContext } from "../../context/SocketProvider";
import { avatarName } from "../../utilities/resuseFunctions";

const settings = [
  {
    id: 1,
    name: "Account",
    icon: UserSvg,
    path: "/account",
  },
  {
    id: 2,
    name: "Sign Out",
    icon: LogoutSvg,
  },
];

const settingsCreator = [
  {
    id: 1,
    name: "Account",
    icon: UserSvg,
    path: "/account",
  },
  {
    id: 4,
    name: "User Management",
    icon: UserSvg,
    path: "/user-management",
  },
  {
    id: 3,
    name: "Billing",
    icon: Wallet,
    path: "/billing",
  },
  {
    id: 2,
    name: "Sign Out",
    icon: LogoutSvg,
  },
];

const MenuHeader = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [listData, setListData] = useState([]);
  const [subListData, setSubListData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { notifications } = useContext(SocketContext);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };
  const handleListItemClick = (path) => {
    navigate(path);
    toggleDrawer(false);
  };

  const list = () => (
    <Box sx={{ width: 350 }} role="presentation">
      <div className="p-2 mx-2 d-flex align-items-center">
        <img src={Logo} alt="logo" className="logo" />
      </div>
      <Divider />
      <List>
        {menuOptions.map(({ icon: Icon, ...i }) => {
          const isSelected = location.pathname === i.path;
          return (
            <div key={i.id}>
              <ListItemButton
                key={i.id}
                className="mt-2"
                onClick={() =>
                  i.hasSubsections
                    ? setSelected(i.id)
                    : handleListItemClick(i.path)
                }
              >
                <div>
                  {i?.hasSubsections ? (
                    <Down style={{ fontSize: 16, color: "#7E8B99" }} />
                  ) : (
                    <span className="mx-2"></span>
                  )}
                </div>
                <ListItemIcon>
                  {i?.noIcon ? (
                    <Icon style={{ color: "#4A545E" }} />
                  ) : (
                    <img src={Icon} alt={i.label} className="sidebar-icon" />
                  )}
                </ListItemIcon>
                <Typography
                  className={isSelected ? "selected-section" : "section-title"}
                >
                  {i.name}
                </Typography>
              </ListItemButton>
              {i?.hasSubsections ? (
                <Collapse in={selected === i.id} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding className="mx-2">
                    {i?.subSections?.map(({ SubIcon, ...sub }) => {
                      const issubSelected = location.pathname === sub.path;
                      return (
                        <ListItemButton
                          key={sub.id}
                          sx={{ pl: 10 }}
                          onClick={() => {
                            if (sub?.hasSubsections) {
                              if (subListData.length === 0) {
                                setSubListData(sub?.subSections || []);
                              } else {
                                setSubListData([]);
                              }
                            } else {
                              handleListItemClick(sub.path);
                            }
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              {sub?.hasSubsections && (
                                <Down
                                  style={{
                                    fontSize: 16,
                                    color: "#7E8B99",
                                    position: "absolute",
                                    left: "-12px",
                                  }}
                                />
                              )}
                              <Typography
                                className={
                                  issubSelected
                                    ? "selected-section"
                                    : "section-title"
                                }
                              >
                                {sub.name}
                              </Typography>
                            </div>
                            {sub?.hasSubsections && (
                              <Collapse in={subListData.length > 0}>
                                <List component="div" disablePadding>
                                  {sub.subSections.map((subOption) => {
                                    const issubSelected =
                                      location.pathname === subOption.path;
                                    return (
                                      <ListItemButton
                                        key={subOption.id}
                                        onClick={() => {
                                          handleCloseNavMenu();
                                          navigate(subOption?.path);
                                        }}
                                        disabled={subOption?.disabled}
                                      >
                                        <Typography
                                          className={
                                            issubSelected
                                              ? "selected-section"
                                              : "section-title"
                                          }
                                        >
                                          {subOption?.name}
                                        </Typography>
                                      </ListItemButton>
                                    );
                                  })}
                                </List>
                              </Collapse>
                            )}
                          </div>
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              ) : null}
            </div>
          );
        })}
      </List>
    </Box>
  );

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElSubNav, setAnchorElSubNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElNotification, setAnchorElNotification] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenNotificationMenu = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseNotificationMenu = () => {
    setAnchorElNotification(null);
  };

  const dispatch = useDispatch();
  const [logout, { isLoading }] = useLogoutMutation();
  const handleCloseUserMenu = async ({ name, path }) => {
    setAnchorElUser(null);
    if (name === "Sign Out") {
      try {
        await logout().unwrap();
        dispatch(logOut());
        return navigate("/signin");
      } catch (error) {
        console.error("Failed to sign out: ", error);
      }
    }
    if (name === "Account") {
      return path && navigate(path);
    }
    if (name === "Billing") {
      return path && navigate(path);
    }
    if (name === "User Management") {
      return path && navigate(path);
    }
  };

  return (
    <>
      <Grid className="mx-3">
        <Toolbar disableGutters>
          <Grid
            role="button"
            onClick={() => navigate("/account")}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
            }}
            className="px-1 align-items-center"
          >
            <img src={Logo} alt="logo" className="logo" />
          </Grid>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(e) => {
                toggleDrawer(true)(e);
              }}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Drawer
            anchor={"left"}
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            {list()}
          </Drawer>
          <Box
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
            }}
            className="align-items-center"
          >
            <img src={Logo} alt="logo" className="logo" />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {menuOptions
              .filter((option) => {
                if (option?.access?.includes("*")) return true;
                const role = option?.access?.find(
                  (role) => role === user?.type?.toString()
                );
                return !(role === undefined);
              })
              .map((option, idx) => {
                const Icon = option.icon;
                return (
                  <Button
                    key={option.id}
                    disabled={option?.disabled}
                    onClick={(e) => {
                      setListData(option?.subSections || []);
                      setSubListData([]);
                      option?.subSections
                        ? handleOpenMenu(e)
                        : navigate(option?.path);
                    }}
                    className={[
                      "d-flex my-2 items-center",
                      location?.pathname === option?.path ||
                      option?.subSections?.some(
                        (sub) => location?.pathname === sub?.path
                      )
                        ? "selected-option"
                        : "normal-option",
                    ].join(" ")}
                  >
                    {option?.noIcon ? (
                      <Icon style={{ color: "#4A545E" }} />
                    ) : (
                      <img
                        src={option?.icon}
                        alt={option.label}
                        className="sidebar-icon"
                      />
                    )}
                    <Typography className="font-14 text-capitalize normal-options">
                      {option?.name}
                    </Typography>
                    {option?.hasSubsections && <DownArrow />}
                  </Button>
                );
              })}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              style={{ padding: "0px" }}
            >
              {listData
                ?.filter((option) => {
                  if (option?.access?.includes("*")) return true;
                  const role = option?.access?.find(
                    (role) => role === user?.type?.toString()
                  );
                  return !(role === undefined);
                })
                ?.filter((sl) => {
                  if (
                    user?.organisation?.data?.isEnterprise &&
                    sl?.isEnterprise === false
                  ) {
                    return false;
                  }
                  return true;
                })
                .map((option) => {
                  return (
                    <>
                      <MenuItem
                        key={option.id}
                        onClick={(e) => {
                          if (option?.hasSubsections) {
                            if (subListData.length === 0) {
                              setSubListData(option?.subSections || []);
                            } else {
                              setSubListData([]);
                            }
                            setAnchorElSubNav(e.currentTarget);
                          } else {
                            handleCloseNavMenu();
                            navigate(option?.path);
                          }
                        }}
                        disabled={option?.disabled}
                        className={[
                          location?.pathname === option?.path
                            ? "selected-option"
                            : "normal-option",
                        ].join(" ")}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div className="-rotate-90">
                              {option?.hasSubsections ? (
                                <Down
                                  style={{ fontSize: 16, color: "#7E8B99" }}
                                />
                              ) : (
                                <span className="mx-2"></span>
                              )}
                            </div>
                            <Typography
                              className="normal-options"
                              textAlign="left"
                            >
                              {option?.name}
                            </Typography>
                          </div>
                          {option?.hasSubsections && (
                            <Collapse in={subListData.length !== 0}>
                              <List component="div" disablePadding>
                                {subListData.map((subOption) => {
                                  return (
                                    <ListItemButton
                                      key={subOption.id}
                                      onClick={() => {
                                        handleCloseNavMenu();
                                        navigate(subOption?.path);
                                      }}
                                      disabled={subOption?.disabled}
                                      className={[
                                        location?.pathname === subOption?.path
                                          ? "selected-option"
                                          : "normal-option",
                                      ].join(" ")}
                                    >
                                      <Typography
                                        className="normal-options"
                                        textAlign="left"
                                      >
                                        {subOption?.name ===
                                          "Upload Scheduling Data" &&
                                        user?.organisation?.data?.isEnterprise
                                          ? "Scheduling Data"
                                          : subOption?.name}
                                      </Typography>
                                    </ListItemButton>
                                  );
                                })}
                              </List>
                            </Collapse>
                          )}
                        </div>
                      </MenuItem>
                    </>
                  );
                })}
            </Menu>
          </Box>
          <Box className="d-flex align-items-center" sx={{ flexGrow: 0 }}>
            <Tooltip title="Notifications">
              <IconButton onClick={handleOpenNotificationMenu} className="mx-3">
                <Badge badgeContent={notifications?.length} color="error">
                  <NotificationsNoneRoundedIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <NotificationsModal
              anchorEl={anchorElNotification}
              open={Boolean(anchorElNotification)}
              onClose={handleCloseNotificationMenu}
            />
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  src={user?.img}
                  style={{ width: 32, height: 32 }}
                  alt={"user profile"}
                >
                  {avatarName(user.details.name)}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {(() => {
                if (user.details?.role === 4) {
                  return settingsCreator;
                }
                return settings;
              })().map(({ name, id, icon: Icon, path }) => (
                <MenuItem
                  key={id}
                  onClick={() => handleCloseUserMenu({ name, path })}
                >
                  <Icon />
                  <Typography className="mx-2" textAlign="center">
                    {name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
        <Divider />
      </Grid>
    </>
  );
};

export default MenuHeader;
