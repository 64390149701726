import io from "socket.io-client";
import { SOCKET_URL } from "../constants/endpoints";
import { useSelector } from "react-redux";
import { selectToken } from "../features/auth/authSlice";
import { useEffect } from "react";

const socket = io(SOCKET_URL, {
  transports: ["websocket", "polling"],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 5,
  forceNew: true,
  timeout: 20000,
  upgrade: true,
  pingInterval: 25000,
  pingTimeout: 60000,
  rememberUpgrade: true,
  secure: true,
  rejectUnauthorized: false,
});

const useSocket = () => {
  const token = useSelector(selectToken);

  useEffect(() => {
    // This effect will run whenever the token changes
    if (socket && token && !socket.connected) {
      // Update the token dynamically
      socket.auth = { token: `Bearer ${token}` || null };
      socket.connect(); // Reconnect with the new token if necessary
    }
  }, [token]); // Dependency on token, so it triggers when the token changes

  return socket;
};

export default useSocket;
