import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "./features/auth/authSlice";
import usePermissions from "./hooks/usePermissions";

const RequireAuth = () => {
  usePermissions();
  const user = useSelector(selectUser);
  const location = useLocation();

  return user?.completedOnboarding || user?.step >= 4 ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
};

export default RequireAuth;
