import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import { Search_Icon } from "../../../assets/images";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import {
  ArrowDownwardTwoTone,
  ArrowUpwardTwoTone,
  EmailOutlined,
  FilterList,
  LocationOnOutlined,
  PeopleSharp,
  Phone,
} from "@mui/icons-material";
import CheckboxSelector from "../../Schedules/components/CheckboxSelector";
import moment from "moment/moment";
import {
  useFetchProviderByIdMutation,
  useFetchProviderCareplansMutation,
  useFetchProviderCommunitiesMutation,
} from "../../../features/provider/providerApiSlice";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import { Tooltip } from "@mui/material";
import Search from "../../../components/Search/SearchNew";
import FilterSelect from "../../../components/Filter/FilterSelect";

const ProviderInfo = () => {
  const [patients, setPatients] = React.useState([]);
  const [communities, setCommunities] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);
  const [provider, setProvider] = React.useState(null);

  const [statusFilter, setStatusFilter] = React.useState([]);
  const [locationFilter, setLocationFilter] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState("");

  const [sortStatusOrder, setSortStatusOrder] = React.useState("none");
  const [sortNameOrder, setSortNameOrder] = React.useState("none");

  const [sortNOPOrder, setSortNOPOrder] = React.useState("none");

  const [tab, setTab] = React.useState("patients");

  const { id } = useParams();

  const handleTabChange = (newTab) => {
    if (newTab !== tab) {
      setTab(newTab);
    }
  };

  const handleSortStatus = (a, b) => {
    if (sortStatusOrder === "none") {
      return 0;
    } else if (sortStatusOrder === "asc") {
      return a.status.localeCompare(b.status);
    } else {
      return b.status.localeCompare(a.status);
    }
  };

  const handleSortName = (a, b) => {
    if (sortNameOrder === "none") {
      return 0;
    } else if (sortNameOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  };

  const handleSortNOP = (a, b) => {
    if (sortNOPOrder === "none") {
      return 0;
    } else if (sortNOPOrder === "asc") {
      return a.noOfPatients - b.noOfPatients;
    } else {
      return b.noOfPatients - a.noOfPatients;
    }
  };

  const navigate = useNavigate();

  const handleCommunityClick = (community) => {
    navigate(`/care-management/communities/${community.id}`);
  };

  const handlePatientClick = (patient) => {
    navigate(`/care-management/patients/${patient.id}`);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleLocationFilter = (location) => {
    if (locationFilter.indexOf(location) === -1) {
      setLocationFilter([...locationFilter, location]);
    } else {
      setLocationFilter(locationFilter.filter((filter) => filter !== location));
    }
  };

  const handleStatusFilter = (status) => {
    if (statusFilter.indexOf(status) === -1) {
      setStatusFilter([...statusFilter, status]);
    } else {
      setStatusFilter(statusFilter.filter((filter) => filter !== status));
    }
  };

  const [getPatients, { isLoading: patientsLoading }] =
    useFetchProviderCareplansMutation();

  const [getProviderById, { isLoading: providerLoading }] =
    useFetchProviderByIdMutation();

  const [getProviderCommunities, { isLoading: communitiesLoading }] =
    useFetchProviderCommunitiesMutation();

  const isLoading = patientsLoading || providerLoading || communitiesLoading;

  const fetchPatients = async () => {
    try {
      const { data } = await getPatients(id).unwrap();

      setPatients(data);
      setLocations(
        Array.from(
          new Set(
            data.map((patient) =>
              patient.locationName === null
                ? "Private Residence"
                : patient.locationName
            )
          )
        )
      );
      setStatuses(Array.from(new Set(data.map((patient) => patient.status))));
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const fetchCommunities = async () => {
    try {
      const { data } = await getProviderCommunities(id).unwrap();

      setCommunities(data);
    } catch (error) {
      console.error(error?.data?.message);
    }
  };

  const fetchProvider = async () => {
    try {
      const { data } = await getProviderById(id).unwrap();

      setProvider(data);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    if (id) {
      fetchProvider();
      fetchPatients();
      fetchCommunities();
    }
  }, [id]);

  const Avatar = ({
    name,
    image,
    size = "w-10 h-10",
    textSize = "text-base",
    fontWeight = "font-normal",
  }) => {
    return (
      <>
        {image !== null ? (
          <div className="flex items-center gap-2">
            <img
              src={image}
              alt={name}
              className={classNames(
                "object-cover w-10 h-10 rounded-full",
                size
              )}
            />
          </div>
        ) : (
          <p
            className={classNames(
              "flex items-center justify-center m-0 text-white rounded-full bg-primaryblue",
              size,
              textSize,
              fontWeight
            )}
          >
            {name
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </p>
        )}
      </>
    );
  };

  return (
    <div className="flex flex-row items-start">
      <Loading loading={isLoading} />
      <div className="w-4/12 py-4 pl-16 pr-8">
        <div className="w-full overflow-x-auto overflow-y-auto h-[calc(100vh-128px)] bg-white rounded-lg shadow border-1 border-primarygrayborder">
          <button className="p-3 text-primaryblue" onClick={handleBack}>
            Back
          </button>
          <div className="flex flex-col items-center justify-end gap-2 p-4 border-b border-b-primarygrayborder">
            <div className="relative">
              <Avatar
                name={provider?.name ?? ""}
                image={null}
                size="w-20 h-20"
                textSize="text-3xl"
                fontWeight="font-semibold"
              />
              <div className="absolute right-0 w-4 h-4 rounded-full bottom-14 bg-primarygreen" />
            </div>
            {provider && (
              <p className="m-0 text-lg font-bold">Dr. {provider?.name}</p>
            )}
            <p className="m-0 text-sm text-primarytextgrey">
              {provider?.speciality}
            </p>
          </div>
          <div className="flex flex-col h-auto gap-4 p-4 border-b border-b-primarygrayborder">
            <div className="flex items-center gap-4">
              {provider && (
                <div className="flex items-center justify-center w-10 h-10 bg-transparent rounded-md">
                  <EmailOutlined
                    style={{
                      color: "#828282",
                    }}
                  />
                </div>
              )}
              <p className="m-0">{provider?.email}</p>
            </div>
            <div className="flex items-center gap-4">
              {provider && (
                <div className="flex items-center justify-center w-10 h-10 bg-transparent rounded-md">
                  <Phone
                    style={{
                      color: "#828282",
                    }}
                  />
                </div>
              )}
              <p className="m-0">{provider?.phone}</p>
            </div>
            <div className="flex items-start gap-4">
              {provider && (
                <div className="flex items-center justify-center w-10 h-10 bg-transparent rounded-md">
                  <LocationOnOutlined
                    style={{
                      color: "#828282",
                    }}
                  />
                </div>
              )}
              <p className="m-0">{provider?.address}</p>
            </div>
          </div>
          {/* <div className="h-auto p-4">
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center w-10 h-10 rounded-md bg-subtleblue">
                <PeopleSharp
                  style={{
                    color: "#3062D4",
                    fontSize: "20px",
                  }}
                />
              </div>
              <p className="m-0 font-semibold text-primaryblue">Patients</p>
            </div>
          </div> */}
          <div className="flex flex-col h-auto gap-4 p-4">
            <button
              onClick={() => handleTabChange("patients")}
              className="flex items-center gap-4 cursor-pointer"
            >
              <div
                className={classNames(
                  "flex items-center justify-center w-10 h-10 rounded-md",
                  tab === "patients" && "bg-subtleblue"
                )}
              >
                <PeopleSharp
                  style={{
                    color: tab === "patients" ? "#3062D4" : "#000",
                    fontSize: "20px",
                  }}
                />
              </div>
              <p
                className={classNames(
                  "m-0 font-semibold",
                  tab === "patients" && "text-primaryblue"
                )}
              >
                Patients ({patients.length})
              </p>
            </button>
            <button
              onClick={() => handleTabChange("communities")}
              className="flex items-center gap-4 cursor-pointer"
            >
              <div
                className={classNames(
                  "flex items-center justify-center w-10 h-10 rounded-md",
                  tab === "communities" && "bg-subtleblue"
                )}
              >
                <PeopleSharp
                  style={{
                    color: tab === "communities" ? "#3062D4" : "#000",
                    fontSize: "20px",
                  }}
                />
              </div>
              <p
                className={classNames(
                  "m-0 font-semibold",
                  tab === "communities" && "text-primaryblue"
                )}
              >
                Communities ({communities.length})
              </p>
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start w-8/12 py-4">
        <div className="flex flex-row items-center justify-between w-full gap-2 pl-8 pr-16">
          <div className="flex flex-row items-center w-full gap-12">
            {/* <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
              {tab === "communities" ? "Communities" : "Patients"}{" "}
              <span className="ml-2 text-base font-bold text-primaryblue">
                {tab === "communities" ? communities.length : patients.length}
              </span>
            </h1> */}
            <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
              <Search value={searchFilter} setValue={setSearchFilter} />
              <FilterSelect
                value={locationFilter}
                handleChange={handleLocationFilter}
                data={locations}
                placeholder="Filter by location..."
              />
              <FilterSelect
                value={statusFilter}
                handleChange={handleStatusFilter}
                data={statuses}
                placeholder="Filter by Status ..."
              />
            </div>
          </div>
          <button
            disabled
            className="flex items-center justify-center px-4 py-2 text-sm font-bold text-white rounded-lg bg-primaryblue whitespace-nowrap"
          >
            Export
          </button>
        </div>
        <div className="w-full pl-8 pr-16">
          <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow  max-h-[calc(100vh-200px)]">
            {tab === "patients" ? (
              <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
                <thead>
                  <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                    <th className="px-4 py-3">
                      <div className="flex flex-row items-center gap-2">
                        <p className="m-0">Name</p>
                        <button
                          className="flex flex-col items-center gap-1"
                          onClick={() => {
                            if (sortNameOrder === "none") {
                              setSortNameOrder("asc");
                            }
                            if (sortNameOrder === "asc") {
                              setSortNameOrder("desc");
                            }
                            if (sortNameOrder === "desc") {
                              setSortNameOrder("none");
                            }
                          }}
                        >
                          {sortNameOrder === "asc" ? (
                            <Tooltip title="Sort Descending">
                              <ArrowDownwardTwoTone />
                            </Tooltip>
                          ) : sortNameOrder === "desc" ? (
                            <Tooltip title="Sort Ascending">
                              <ArrowUpwardTwoTone />
                            </Tooltip>
                          ) : (
                            <div className="flex items-center h-4">
                              <div className="w-6 h-[2px] bg-white" />
                            </div>
                          )}
                        </button>
                      </div>
                    </th>
                    <th className="px-4 py-3">Last Visit Date</th>
                    <th className="px-4 py-3">Date of Birth</th>
                    <th className="px-4 py-3">Gender</th>
                    <th className="px-4 py-3">EHR ID</th>
                    <th className="px-4 py-3">Location</th>
                    <th className="px-4 py-3">
                      <div className="flex flex-row items-center gap-2">
                        <p className="m-0">Status</p>
                        <button
                          className="flex flex-col items-center gap-1"
                          onClick={() => {
                            if (sortStatusOrder === "none") {
                              setSortStatusOrder("asc");
                            }
                            if (sortStatusOrder === "asc") {
                              setSortStatusOrder("desc");
                            }
                            if (sortStatusOrder === "desc") {
                              setSortStatusOrder("none");
                            }
                          }}
                        >
                          {sortStatusOrder === "asc" ? (
                            <Tooltip title="Sort Descending">
                              <ArrowDownwardTwoTone />
                            </Tooltip>
                          ) : sortStatusOrder === "desc" ? (
                            <Tooltip title="Sort Ascending">
                              <ArrowUpwardTwoTone />
                            </Tooltip>
                          ) : (
                            <div className="flex items-center h-4">
                              <div className="w-6 h-[2px] bg-white" />
                            </div>
                          )}
                        </button>
                      </div>
                    </th>
                    {/* <th className="px-4 py-3">Edit</th> */}
                  </tr>
                </thead>
                <tbody>
                  {patients
                    .filter((patient) =>
                      patient.name
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
                    )
                    .filter(
                      (patient) =>
                        statusFilter.length === 0 ||
                        statusFilter.indexOf(patient.status) !== -1
                    )
                    .filter(
                      (patient) =>
                        locationFilter.length === 0 ||
                        locationFilter.indexOf(
                          patient.locationName === null
                            ? "Private Residence"
                            : patient.locationName
                        ) !== -1
                    )
                    .sort((a, b) => handleSortName(a, b))
                    .sort((a, b) => handleSortStatus(a, b))
                    .map((patient, index) => (
                      <tr
                        className={classNames(
                          "text-left text-gray-900 h-24 whitespace-nowrap",
                          index !== patients.length - 1
                            ? "border-b"
                            : "border-none"
                        )}
                        key={index}
                      >
                        <td className="px-4 py-3 text-left">
                          <button
                            className="flex flex-row items-center gap-4"
                            onClick={() => handlePatientClick(patient)}
                          >
                            <Avatar name={patient.name} image={null} />
                            <p className="m-0 font-semibold underline">
                              {patient.name}
                            </p>
                          </button>
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {moment(patient.lvd).format("MM/DD/YYYY")}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {moment(patient.dob).format("MM/DD/YYYY")}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {patient.sex === "F" ? "Female" : "Male"}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {patient.patientEhrId}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {patient.locationName === null
                            ? "Private Residence"
                            : patient.locationName}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          <div
                            className={classNames(
                              patient.status.toUpperCase() === "ACTIVE"
                                ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                                : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                              "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                            )}
                          >
                            <div
                              className={classNames(
                                "w-2 h-2 rounded-full",
                                patient.status.toUpperCase() === "ACTIVE"
                                  ? "bg-primarygreen"
                                  : "bg-primarytextgrey"
                              )}
                            />
                            {patient.status}
                          </div>
                        </td>
                        {/* <td className="px-4 py-3 text-left">
                    <button
                      className="p-2 text-white rounded-md"
                      onClick={() => {}}
                    >
                      <IconComponent icon={"Edit_Icon"} i={provider.id} />
                    </button>
                  </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : (
              <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
                <thead>
                  <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                    <th className="px-4 py-3">
                      <div className="flex flex-row items-center gap-2">
                        <p className="m-0">Name</p>
                        <button
                          className="flex flex-col items-center gap-1"
                          onClick={() => {
                            if (sortNameOrder === "none") {
                              setSortNameOrder("asc");
                            }
                            if (sortNameOrder === "asc") {
                              setSortNameOrder("desc");
                            }
                            if (sortNameOrder === "desc") {
                              setSortNameOrder("none");
                            }
                          }}
                        >
                          {sortNameOrder === "asc" ? (
                            <Tooltip title="Sort Descending">
                              <ArrowDownwardTwoTone />
                            </Tooltip>
                          ) : sortNameOrder === "desc" ? (
                            <Tooltip title="Sort Ascending">
                              <ArrowUpwardTwoTone />
                            </Tooltip>
                          ) : (
                            <div className="flex items-center h-4">
                              <div className="w-6 h-[2px] bg-white" />
                            </div>
                          )}
                        </button>
                      </div>
                    </th>
                    <th className="px-4 py-3">Address</th>
                    {/* <th className="px-4 py-3">
                      <div className="flex flex-row items-center gap-2">
                        <p className="m-0">No of Patients</p>
                        <button
                          className="flex flex-col items-center gap-1"
                          onClick={() => {
                            if (sortNOPOrder === "none") {
                              setSortNOPOrder("asc");
                            }
                            if (sortNOPOrder === "asc") {
                              setSortNOPOrder("desc");
                            }
                            if (sortNOPOrder === "desc") {
                              setSortNOPOrder("none");
                            }
                          }}
                        >
                          {sortNOPOrder === "asc" ? (
                            <ArrowDownwardTwoTone />
                          ) : sortNOPOrder === "desc" ? (
                            <ArrowUpwardTwoTone />
                          ) : (
                            <div className="flex items-center h-4">
                              <div className="w-6 h-[2px] bg-white" />
                            </div>
                          )}
                        </button>
                      </div>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {communities
                    .filter((community) =>
                      community.name
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
                    )
                    .sort((a, b) => handleSortNOP(a, b))
                    .sort((a, b) => handleSortName(a, b))
                    .map((community, index) => (
                      <tr
                        className={classNames(
                          "text-left text-gray-900 h-24 whitespace-nowrap",
                          index !== communities.length - 1
                            ? "border-b"
                            : "border-none"
                        )}
                        key={index}
                      >
                        <td className="px-4 py-3 text-left">
                          <button
                            onClick={() => handleCommunityClick(community)}
                            className="flex flex-row items-center gap-4"
                          >
                            <Avatar name={community.name} image={null} />
                            <p className="m-0 font-semibold underline">
                              {community.name}
                            </p>
                          </button>
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {community.address?.join(", ")}
                        </td>
                        {/* <td className="px-4 py-3 text-left text-gray-700">
                          {community.noOfPatients}
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderInfo;
