import React from "react";
import ReusableModal from "../../../../components/Modals/ReusableModal";

const POCDeleteModal = ({ del, clearInput, pocFirstName, onDeletePOC }) => {
  return (
    <ReusableModal
      open={!!del}
      title="Delete point of contact"
      onClose={() => {
        clearInput();
      }}
    >
      <p className="p-3 text-base">
        Are you sure you want to delete {pocFirstName} as point of contact?
      </p>
      <div className="flex justify-end">
        <div className="flex gap-2 m-3">
          <button
            className="w-28 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
            onClick={clearInput}
          >
            Cancel
          </button>
          <button
            className="w-28 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 rounded-md bg-primaryblue text-white whitespace-nowrap"
            onClick={async () => {
              await onDeletePOC();
              clearInput();
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default POCDeleteModal;
