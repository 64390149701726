import AddIcon from "@mui/icons-material/Add";
import AreaChartIcon from "../../../../../assets/svgs/AreaChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import { CheckBox } from "@mui/icons-material";
import DoubleBarChartImage from "../../../../../assets/images/doublebar-chart-icon.png";
import DoublebarChartIconsvg from "../../../../../assets/svgs/DoublebarChartIconsvg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LinebarChartIconsvg from "../../../../../assets/svgs/linebarChartIconsvg";
import PieChartIcon from "@mui/icons-material/PieChart";
import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import ScatterChart from "../../../../../assets/svgs/ScatterChart";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { SketchPicker } from "react-color";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import { Tooltip } from "@mui/material";
import { XAxis } from "recharts";
import classNames from "classnames";
import lineBarChartImage from "../../../../../assets/images/linebar-chart-icon.png";
import moment from "moment";
import scatteredChartImage from "../../../../../assets/images/scatter-chart-icon.png";

const SidebarSection = ({ title, children, subSection, isOpen, onToggle }) => {
  // const [isOpen, setIsOpen] = React.useState(false);
  // const toggleOpen = () => {
  //     setIsOpen(!isOpen);
  // };

  return (
    <div className={`sidebar-section ${title === "Legends" ? "relative" : ""}`}>
      <div
        className={`sidebar-section-header px-2 cursor-pointer  ${
          subSection ? "" : "py-1"
        }`}
        onClick={onToggle}
        style={{
          backgroundColor: !subSection ? "rgb(16 85 151)" : "#534a4a40",
        }}
      >
        <div className="d-flex justify-between items-center">
          <span
            className={`font-semibold text-sm ${
              subSection ? "text-sm text-gray-600" : "text-base text-gray-500"
            } `}
            style={{
              color: subSection ? "text-sm text-gray-600" : "white",
              paddingLeft: "8px",
            }}
          >
            {title}
          </span>
          <button className="toggle-button">
            {isOpen ? (
              <Tooltip title="Remove">
                <RemoveIcon
                  className={`${
                    subSection ? "text-sm text-gray-600" : "text-white"
                  }`}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Add">
                <AddIcon
                  className={`${
                    subSection ? "text-sm text-gray-600" : "text-white"
                  }`}
                />
              </Tooltip>
            )}
          </button>
        </div>
      </div>
      {isOpen && <div className="sidebar-section-content">{children}</div>}
    </div>
  );
};
function ChartProperties({
  component,
  onPropertyChange,
  setSelectedComponent,
  showAlert,
  flag,
  setFlag,
  reportAreaRef,
  generateChartData,
  echartsRef,
  generatePieChartData,
  generateDoubleChartData,
  openSection,
  setOpenSection,
  isAnimated,
  setIsAnimated,
}) {
  const borderColorRef = React.useRef(null);
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
  const [colorPickerBg, setColorPickerBg] = React.useState(false);
  const [colorPickerText, setColorPickerText] = React.useState(false);
  const colorPickerRefs = React.useRef({});
  const columnDropdownRef = React.useRef(null);
  const [columnListOpen, setColumnListOpen] = React.useState(false);
  const [colorPickerState, setColorPickerState] = React.useState({});
  const [chartListOpen, setChartListOpen] = React.useState(false);
  const [zAxisListOpen, setzAxisListOpen] = React.useState(false);
  const textColorRef = React.useRef(null);
  const backgroundColorRef = React.useRef(null);
  const chartDropDownRef = React.useRef(null);
  const [selectedChartType, setSelectedChartType] = React.useState(
    component?.properties?.chartType == "bar"
      ? { value: 1, type: "Bar", icon: <BarChartIcon color="primary" /> }
      : { value: 2, type: "Pie", icon: <PieChartIcon color="primary" /> }
  );
  const [checkedItems, setCheckedItems] = React.useState(
    component?.properties?.yAxis || []
  );
  const zAxisRef = React.useRef(null);
  const [isLogScaleY, setIsLogScaleY] = React.useState(false);
  const [selectedLogValues, setSelectedLogValues] = React.useState([]); // State for selected log values
  const zAxisValues = component?.properties?.zAxis || []; // Get Z-axis values
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const logDropDownRef = React.useRef(null);

  const DAY_MAP = {
    MONDAY: "M",
    TUESDAY: "T",
    WEDNESDAY: "W",
    THURSDAY: "Th",
    FRIDAY: "F",
    SATURDAY: "Sa",
    SUNDAY: "Su",
  };
  const Charts = [
    {
      value: 1,
      type: "Bar",
      label: "bar",
      icon: (
        <Tooltip title={"Bar chart"}>
          <BarChartIcon
            style={{ color: "#1976d2", padding: "0px", margin: "0px" }}
          />
        </Tooltip>
      ),
    },
    {
      value: 2,
      type: "Pie",
      label: "pie",
      icon: (
        <Tooltip title={"Pie chart"}>
          <PieChartIcon style={{ color: "#1976d2" }} />
        </Tooltip>
      ),
    },
    {
      value: 3,
      type: "Line with Bar",
      label: "barWithLine",
      icon: (
        <LinebarChartIconsvg
          height="24px"
          width="24px"
          tooltipText="LineBar chart"
        />
      ),
    },
    {
      value: 4,
      type: "Stacked",
      label: "stacked",
      icon: (
        <Tooltip title={"StackedBar chart"}>
          <StackedBarChartIcon style={{ color: "#1976d2" }} />
        </Tooltip>
      ),
    },
    {
      value: 5,
      type: "Double",
      label: "double",
      icon: (
        <DoublebarChartIconsvg
          height="23px"
          width="23px"
          tooltipText="DoubleBar chart"
        />
      ),
    },
    {
      value: 6,
      type: "Line",
      label: "line",
      icon: (
        <Tooltip title={"Line Chart"}>
          <ShowChartIcon style={{ color: "#1976d2" }} />
        </Tooltip>
      ),
    },
    {
      value: 7,
      type: "Area",
      label: "area",
      icon: (
        <AreaChartIcon height="24px" width="24px" tooltipText="Area Chart" />
      ),
    },
    {
      value: 8,
      type: "Scatter",
      label: "scatter",
      icon: (
        <ScatterChart height="24px" width="24px" tooltipText="Scatter Chart" />
      ),
    },
    // {value:5,type:'Scatter',icon:<ScatterPlotIcon style={{ fontSize: '2.2rem', color: '#1976d2' }} color='primary'/>}
  ];
  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
      ? value.map((day) => DAY_MAP[day]).join(", ")
      : type === "date"
      ? moment(value).format("MM/DD/YYYY")
      : value;
  };
  const handlePositionChange = (e, property) => {
    const newValue = parseInt(e.target.value, 10);
    let updatedProperties = { [property]: newValue };
    if (newValue < 0) {
      updatedProperties[property] = 0;
    }
    if (property === "top") {
      updatedProperties.bottom =
        reportAreaRef.current.clientHeight -
        newValue -
        component.properties.height;
      // Ensure bottom doesn't go below 0
      if (updatedProperties.bottom < 0) {
        updatedProperties.bottom = 0;
        updatedProperties.top =
          reportAreaRef.current.clientHeight - component.properties.height;
      }
    } else if (property === "bottom") {
      updatedProperties.top =
        reportAreaRef.current.clientHeight -
        newValue -
        component.properties.height;
      // Ensure top doesn't go below 0
      if (updatedProperties.top < 0) {
        updatedProperties.top = 0;
        updatedProperties.bottom =
          reportAreaRef.current.clientHeight - component.properties.height;
      }
    } else if (property === "left") {
      updatedProperties.right =
        reportAreaRef.current.clientWidth -
        newValue -
        component.properties.width;
      if (updatedProperties.right < 0) {
        updatedProperties.right = 0;
        updatedProperties.left =
          reportAreaRef.current.clientWidth - component.properties.width;
      }
    } else if (property === "right") {
      updatedProperties.left =
        reportAreaRef.current.clientWidth -
        newValue -
        component.properties.width;
      if (updatedProperties.left < 0) {
        updatedProperties.left = 0;
        updatedProperties.right =
          reportAreaRef.current.clientWidth - component.properties.width;
      }
    }
    onPropertyChange(updatedProperties);
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        ...updatedProperties,
      },
    }));
    setFlag(false);
    setIsAnimated(false);
  };
  const handleClickOutside = (event) => {
    if (
      textColorRef?.current &&
      !textColorRef?.current?.contains(event.target)
    ) {
      setColorPickerText(false);
    }
    if (
      backgroundColorRef?.current &&
      !backgroundColorRef?.current?.contains(event.target)
    ) {
      setColorPickerBg(false);
    }
    if (
      borderColorRef.current &&
      !borderColorRef.current.contains(event.target)
    ) {
      setColorPickerOpen(false);
    }
    const colorPickerElements = Object.values(colorPickerRefs.current);
    if (!colorPickerElements.some((el) => el && el.contains(event.target))) {
      setColorPickerState({});
    }
    if (
      chartDropDownRef?.current &&
      !chartDropDownRef?.current?.contains(event.target)
    ) {
      setChartListOpen(false);
    }
    if (
      logDropDownRef?.current &&
      !logDropDownRef?.current?.contains(event.target)
    ) {
      setDropdownOpen(false);
    }
  };
  const updateChartAxis = (option) => {
    console.log(option, ">>> option");
    let result;
    if (
      component?.properties?.chartType === "bar" ||
      component?.properties?.chartType === "barWithLine" ||
      component?.properties?.chartType === "stacked" ||
      component?.properties?.chartType === "line" ||
      component?.properties?.chartType === "area" ||
      component?.properties?.chartType === "scatter"
    ) {
      if (option?.line === null) {
        result = generateChartData(
          option?.xAxis,
          option?.yAxis,
          option?.zAxis,
          null,
          false,
          option?.isLogScaleY,
          option?.isLogScaleZ,
          component?.id
        );
      } else {
        result = generateChartData(
          option?.xAxis,
          option?.yAxis,
          option?.zAxis,
          option?.line,
          false,
          option?.isLogScaleY,
          option?.isLogScaleZ,
          component?.id
        );
      }
      const newOption = {
        ...component?.properties?.option,
        series: result?.series,
        xAxis: {
          ...component?.properties?.option?.xAxis,
          data: result?.xValues,
        },
        legend: { data: result?.series?.map((series) => series.name) },
      };
      onPropertyChange({
        option: newOption,
        xAxis: option?.xAxis,
        yAxis: option?.yAxis,
        line: option?.line,
        zAxis: option?.zAxis,
        isLogScaleY: option?.isLogScaleY,
        isLogScaleZ: option?.isLogScaleZ,
        logColumns: {
          ...component?.properties?.logColumns,
          yAxis: option?.isLogScaleY ? `Log(${option?.yAxis})` : null,
          zAxis:
            option?.isLogScaleZ && option?.line == null
              ? `Log(${option?.zAxis[0]})`
              : option?.isLogScaleZ && option?.line !== null
              ? `Log(${option?.line})`
              : null,
        },
      });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          option: newOption,
          xAxis: option?.xAxis,
          yAxis: option?.yAxis,
          line: option?.line,
          zAxis: option?.zAxis,
          isLogScaleY: option?.isLogScaleY,
          isLogScaleZ: option?.isLogScaleZ,
          logColumns: {
            ...component?.properties?.logColumns,
            yAxis: option?.isLogScaleY ? `Log(${option?.yAxis})` : null,
            zAxis:
              option?.isLogScaleZ && option?.line == null
                ? `Log(${option?.zAxis[0]})`
                : option?.isLogScaleZ && option?.line !== null
                ? `Log(${option?.line})`
                : null,
          },
        },
      }));
      setFlag(false);
      setIsAnimated(true);
    } else if (component?.properties?.chartType === "double") {
      //  result = generateChartData( option?.xAxis, option?.yAxis, option?.zAxis, null, false);
      const result = generateDoubleChartData(
        option?.xAxis,
        option?.zAxis,
        true,
        option?.logColumns,
        component?.id
      );
      const newOption = {
        ...component?.properties?.option,
        series: result?.series,
        xAxis: {
          ...component?.properties?.option?.xAxis,
          data: result?.xValues,
        },
        legend: { data: result?.series?.map((series) => series.name) },
      };
      onPropertyChange({
        option: newOption,
        xAxis: option?.xAxis,
        yAxis: option?.yAxis,
        line: option?.line,
        zAxis: option?.zAxis,
        logColumns: {
          ...component?.properties?.logColumns,
          double: option?.logColumns,
        },
      });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          option: newOption,
          xAxis: option?.xAxis,
          yAxis: option?.yAxis,
          line: option?.line,
          zAxis: option?.zAxis,
          logColumns: {
            ...component?.properties?.logColumns,
            double: option?.logColumns,
          },
        },
      }));
      // echartsRef.current.getEchartsInstance().setOption(newOption, true);
      setFlag(false);
      setIsAnimated(true);
    } else {
      let result = generatePieChartData(option?.xAxis);
      const newOption = {
        ...component?.properties?.option,
        series: result?.series,
        // xAxis: {
        //     ...component?.properties?.option?.xAxis,
        //     data: result?.xValues
        // },
        legend: { data: result?.series[0]?.data.map((item) => item?.name) },
      };
      onPropertyChange({
        option: newOption,
        chartType: "pie",
        xAxis: option?.xAxis,
      });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          option: newOption,
          chartType: "pie",
          xAxis: option?.xAxis,
        },
      }));
      setIsAnimated(true);
    }
  };
  const handleColorPickerToggle = (itemName) => {
    setColorPickerState((prev) => ({
      ...prev,
      [itemName]: !prev[itemName],
    }));
  };
  // const generateDoubleChartData   = (data, xHeader, yHeaders, lineHeader, isSetColors) => {
  //     const colors = [
  //         '#003f5c',
  //         '#665191',
  //         '#a05195',
  //         '#d45087',
  //         '#f95d6a',
  //         '#ff7c43',
  //     ]
  //     const barResult = {};
  //     const allYValues = new Set();

  //     // Aggregate data for bars
  //     data.forEach((row) => {
  //         const xValue = row[xHeader];

  //         yHeaders.forEach((yHeader) => {
  //             const yValue = row[yHeader];

  //             if (!barResult[xValue]) {
  //                 barResult[xValue] = {};
  //             }

  //             if (!barResult[xValue][yValue]) {
  //                 barResult[xValue][yValue] = 0;
  //             }

  //             // Assuming patient count is always present and is a number
  //             const count = parseInt(row['Patient Count'], 10) || 0;
  //             barResult[xValue][yValue] += count;
  //             allYValues.add(yValue); // Collect all unique y-values
  //         });
  //     });

  //     const series = [];
  //     const xValues = [...new Set(data.map((row) => row[xHeader]))];
  //     let colorIndex = 0;

  //     // Generate bar series for each unique y-value (e.g., Providers and Visit Types)
  //     allYValues.forEach((yValue) => {
  //         let seriesItem = {
  //             name: yValue,
  //             type: "bar",
  //             data: Array(xValues.length).fill(0),
  //             itemStyle: {
  //                 color: isSetColors ? colors[colorIndex % colors.length] : '#000', // Set color if needed
  //             },
  //         };
  //         series.push(seriesItem);
  //         colorIndex++;

  //         xValues.forEach((xValue, index) => {
  //             seriesItem.data[index] = barResult[xValue] && barResult[xValue][yValue] ? barResult[xValue][yValue] : 0;
  //         });
  //     });
  //     return { series, xValues };
  // };
  const handleCheckboxChange = (item) => {
    // Check if the item is already in the array
    const itemIndex = component?.properties?.yAxis.indexOf(item);

    if (itemIndex === -1) {
      // Item is not in the array, add it
      const newValues = [...component.properties.yAxis, item];
      setCheckedItems(newValues);
      updateChartAxis({
        yAxis: newValues,
        xAxis: component?.properties?.xAxis,
        line: component?.properties?.line,
      });
    } else {
      // Item is in the array, remove it
      const newValues = component?.properties?.yAxis.filter(
        (value) => value !== item
      );
      setCheckedItems(newValues);
      // console.log(newValues, ">>sndjkdsfgsdfg")
      updateChartAxis({
        yAxis: newValues,
        xAxis: component?.properties?.xAxis,
        line: component?.properties?.line,
      });
    }
  };
  const handleCheckboxChangeLog = (item) => {
    const itemIndex = component?.properties?.logColumns?.double?.indexOf(item);
    if (itemIndex === -1) {
      const newValues = [...component.properties.logColumns.double, item];
      setCheckedItems(newValues);
      updateChartAxis({
        zAxis: component?.properties?.zAxis,
        yAxis: "",
        xAxis: component?.properties?.xAxis,
        line: component?.properties?.line,
        logColumns: newValues,
      });
    } else {
      const newValues = component?.properties?.logColumns?.double?.filter(
        (value) => value !== item
      );
      setCheckedItems(newValues);
      updateChartAxis({
        zAxis: component?.properties?.zAxis,
        xAxis: component?.properties?.xAxis,
        yAxis: "",
        line: component?.properties?.line,
        logColumns: newValues,
      });
    }
    setIsAnimated(true);
  };
  const handleCheckboxChangeZAxis = (item) => {
    const itemIndex = component?.properties?.zAxis.indexOf(item);
    console.log(component?.properties?.logColumns, ">>.ms eurjhuei hriu");
    if (itemIndex === -1) {
      const newValues = [...component.properties.zAxis, item];
      setCheckedItems(newValues);
      updateChartAxis({
        zAxis: newValues,
        yAxis: "",
        xAxis: component?.properties?.xAxis,
        line: component?.properties?.line,
        logColumns: component?.properties?.logColumns?.double,
      });
    } else {
      const newValues = component?.properties?.zAxis?.filter(
        (value) => value !== item
      );
      setCheckedItems(newValues);
      updateChartAxis({
        zAxis: newValues,
        xAxis: component?.properties?.xAxis,
        yAxis: "",
        line: component?.properties?.line,
        logColumns: component?.properties?.logColumns?.double?.filter(
          (value) => value !== `Log(${item})`
        ),
      });
    }
    setIsAnimated(true);
  };
  const handleChartTypeSelect = (item) => {
    setIsAnimated(true);
    setSelectedChartType(item);
    const headers = component?.properties?.columns;
    if (item?.value === 1) {
      const result = generateChartData(
        headers[0],
        headers[1],
        [],
        null,
        true,
        false,
        false,
        component?.id
      );
      let newOption = {
        ...component?.properties?.option,
        series: result?.series,
        xAxis: {
          ...component?.properties?.option.xAxis,
          data: result?.xValues,
        },
        legend: {
          ...component?.properties?.option.legend,
          data: result?.series?.map((series) => series.name),
        },
      };
      onPropertyChange({
        option: newOption,
        chartType: "bar",
        xAxis: headers[0],
        yAxis: headers[1],
        line: null,
        zAxis: [],
        isLogScaleY: false,
        isLogScaleZ: false,
        logColumns: { double: [], zAxis: null, yAxis: null },
      });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          option: newOption,
          chartType: "bar",
          xAxis: headers[0],
          yAxis: headers[1],
          line: null,
          isLogScaleY: false,
          isLogScaleZ: false,
          logColumns: { double: [], zAxis: null, yAxis: null },
        },
      }));
      setFlag(false);
    } else if (item?.value === 2) {
      const result = generatePieChartData(headers[0]);
      const newOption = {
        ...component?.properties?.option,
        series: result?.series,
        yAxis: {
          ...component?.properties?.option?.yAxis,
          interval: 1,
        },
        // xAxis: {
        //     ...component?.properties?.option?.xAxis,
        //     data: result?.xValues
        // },
        legend: { data: result?.series[0]?.data.map((item) => item?.name) },
      };
      onPropertyChange({
        option: newOption,
        chartType: "pie",
        xAxis: headers[0],
        zAxis: [],
        isLogScaleY: false,
        isLogScaleZ: false,
        logColumns: { double: [], zAxis: null, yAxis: null },
      });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          option: newOption,
          chartType: "pie",
          xAxis: headers[0],
          zAxis: [],
          isLogScaleY: false,
          isLogScaleZ: false,
          logColumns: { double: [], zAxis: null, yAxis: null },
        },
      }));
    } else if (item?.value === 3) {
      const result = generateChartData(
        headers[0],
        headers[1],
        [],
        headers[2],
        true,
        false,
        false,
        component?.id
      );
      let newOption = {
        ...component?.properties?.option,
        series: result?.series,
        xAxis: {
          ...component?.properties?.option.xAxis,
          data: result?.xValues,
        },
        legend: {
          ...component?.properties?.option.legend,
          data: result?.series?.map((series) => series.name),
        },
      };
      onPropertyChange({
        option: newOption,
        chartType: "barWithLine",
        xAxis: headers[0],
        yAxis: headers[1],
        line: headers[2],
        zAxis: [],
        isLogScaleY: false,
        isLogScaleZ: false,
        logColumns: { double: [], zAxis: null, yAxis: null },
      });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          option: newOption,
          chartType: "barWithLine",
          xAxis: headers[0],
          yAxis: headers[1],
          line: headers[2],
          zAxis: [],
          isLogScaleY: false,
          isLogScaleZ: false,
          logColumns: { double: [], zAxis: null, yAxis: null },
        },
      }));
      setFlag(false);
    } else if (item.value == 4) {
      const result = generateChartData(
        headers[0],
        headers[1],
        [],
        null,
        true,
        false,
        false,
        component?.id
      );
      let newOption = {
        ...component?.properties?.option,
        series: result?.series,
        xAxis: {
          ...component?.properties?.option.xAxis,
          data: result?.xValues,
        },
        legend: {
          ...component?.properties?.option.legend,
          data: result?.series?.map((series) => series.name),
        },
      };
      onPropertyChange({
        option: newOption,
        chartType: "stacked",
        xAxis: headers[0],
        yAxis: headers[1],
        line: null,
        zAxis: [],
        isLogScaleY: false,
        isLogScaleZ: false,
        logColumns: { double: [], zAxis: null, yAxis: null },
      });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          option: newOption,
          chartType: "stacked",
          xAxis: headers[0],
          yAxis: headers[1],
          line: null,
          zAxis: [],
          isLogScaleY: false,
          isLogScaleZ: false,
          logColumns: { double: [], zAxis: null, yAxis: null },
        },
      }));
      setFlag(false);
    } else if (item.value == 5) {
      if (headers.length <= 2) {
        showAlert(
          "At least 3 headers are required to generate a multi-bar chart.",
          "error"
        );
        return;
      }
      {
        const result = generateChartData(
          headers[0],
          "",
          [headers[1]],
          null,
          true,
          false,
          false,
          component?.id
        );
        let newOption = {
          ...component?.properties?.option,
          series: result?.series,
          xAxis: {
            ...component?.properties?.option.xAxis,
            data: result?.xValues,
          },
          legend: {
            ...component?.properties?.option.legend,
            data: result?.series?.map((series) => series.name),
          },
        };
        onPropertyChange({
          option: newOption,
          chartType: "double",
          xAxis: headers[0],
          yAxis: "",
          line: null,
          zAxis: [headers[1]],
          isLogScaleY: false,
          isLogScaleZ: false,
          logColumns: { double: [], zAxis: null, yAxis: null },
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            option: newOption,
            chartType: "double",
            xAxis: headers[0],
            yAxis: "",
            line: null,
            zAxis: [headers[1]],
            isLogScaleY: false,
            isLogScaleZ: false,
            logColumns: { double: [], zAxis: null, yAxis: null },
          },
        }));
        setFlag(false);
      }
    } else if (item?.value == 6) {
      const result = generateChartData(
        headers[0],
        headers[1],
        [],
        null,
        true,
        false,
        false,
        component?.id
      );
      let newOption = {
        ...component?.properties?.option,
        series: result?.series,
        xAxis: {
          ...component?.properties?.option.xAxis,
          data: result?.xValues,
        },
        legend: {
          ...component?.properties?.option.legend,
          data: result?.series?.map((series) => series.name),
        },
      };
      onPropertyChange({
        option: newOption,
        chartType: "line",
        xAxis: headers[0],
        yAxis: headers[1],
        line: null,
        zAxis: [],
        isLogScaleY: false,
        isLogScaleZ: false,
        logColumns: { double: [], zAxis: null, yAxis: null },
      });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          option: newOption,
          chartType: "line",
          xAxis: headers[0],
          yAxis: headers[1],
          line: null,
          zAxis: [],
          isLogScaleY: false,
          isLogScaleZ: false,
          logColumns: { double: [], zAxis: null, yAxis: null },
        },
      }));
      setFlag(false);
    } else if (item?.value == 7) {
      const result = generateChartData(
        headers[0],
        headers[1],
        [],
        null,
        true,
        false,
        false,
        component?.id
      );
      let newOption = {
        ...component?.properties?.option,
        series: result?.series,
        xAxis: {
          ...component?.properties?.option.xAxis,
          data: result?.xValues,
        },
        legend: {
          ...component?.properties?.option.legend,
          data: result?.series?.map((series) => series.name),
        },
      };
      onPropertyChange({
        option: newOption,
        chartType: "area",
        xAxis: headers[0],
        yAxis: headers[1],
        line: null,
        zAxis: [],
        isLogScaleY: false,
        isLogScaleZ: false,
        logColumns: { double: [], zAxis: null, yAxis: null },
      });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          option: newOption,
          chartType: "area",
          xAxis: headers[0],
          yAxis: headers[1],
          line: null,
          zAxis: [],
          isLogScaleY: false,
          isLogScaleZ: false,
          logColumns: { double: [], zAxis: null, yAxis: null },
        },
      }));
      setFlag(false);
    } else if (item?.value == 8) {
      const result = generateChartData(
        headers[0],
        headers[1],
        [],
        null,
        true,
        false,
        false,
        component?.id
      );
      let newOption = {
        ...component?.properties?.option,
        series: result?.series,
        xAxis: {
          ...component?.properties?.option.xAxis,
          data: result?.xValues,
        },
        legend: {
          ...component?.properties?.option.legend,
          data: result?.series?.map((series) => series.name),
        },
      };
      onPropertyChange({
        option: newOption,
        chartType: "scatter",
        xAxis: headers[0],
        yAxis: headers[1],
        line: null,
        zAxis: [],
        isLogScaleY: false,
        isLogScaleZ: false,
        logColumns: { double: [], zAxis: null, yAxis: null },
      });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          option: newOption,
          chartType: "scatter",
          xAxis: headers[0],
          yAxis: headers[1],
          line: null,
          zAxis: [],
          isLogScaleY: false,
          isLogScaleZ: false,
          logColumns: { double: [], zAxis: null, yAxis: null },
        },
      }));
      setFlag(false);
    }
    setFlag(false);
  };
  const Fonts = [
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Times",
    "Courier New",
    "Courier",
    "Verdana",
    "Georgia",
    "Palatino",
    "Garamond",
    "Bookman",
    "Trebuchet MS",
    "Comic Sans MS",
    "Impac",
  ];
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // React.useEffect(()=>{
  // if(setFlag === true){
  //     set
  // }
  // },[component])
  // const [openSection, setOpenSection] = React.useState(null);

  const handleToggle = (section) => {
    setOpenSection(openSection === section ? null : section);
  };
  return (
    <>
      <div>
        <span className="flex flex-row items-start justify-start w-full gap-1 py-3 px-3 items-center">
          <p className="font-semibold text-sm  text-gray-500 m-0">Name:</p>
          <input
            type="text"
            className="block p-1 text-sm text-gray-900 bg-white border-custome rounded w-full  focus:ring-blue-500 focus:border-blue-500"
            value={component?.properties?.name || ""}
            onChange={(e) => {
              const newValue = e.target.value;
              onPropertyChange({ name: newValue });
              setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                  ...prev.properties,
                  name: newValue,
                },
              }));
              setFlag(false);
              setIsAnimated(false);
            }}
          />
        </span>
        <span className="d-flex py-2 px-3 pb-3 gap-1 flex-col">
          <p className="font-semibold text-sm text-gray-500 m-0">
            Types of Charts:
          </p>
          <span className="d-flex flex-col items-center gap-2 mt-2">
            <span className="flex justify-center border border-gray-300 lg:w-full sm:w-72 px-2 py-1 bg-white rounded-md shadow-sm">
              <span className="flex flex-row items-center justify-center lg:gap-3 sm:gap-1">
                {Charts?.map((item) => {
                  return (
                    <div
                      style={{
                        height: "30px",
                        width: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleChartTypeSelect(item)}
                      className={`hover:bg-slate-200 d-flex items-center justify-center ${
                        item?.label === component?.properties?.chartType
                          ? "bg-slate-300"
                          : "bg-slate-50"
                      }`}
                    >
                      {item?.icon}
                    </div>
                  );
                })}
              </span>
              {/* <div className="flex flex-row items-center justify-center gap-4">
                                <BarChartIcon style={{ fontSize: '2.8rem', color: '#1976d2' }} />
                                <PieChartIcon style={{ fontSize: '2.5rem', color: '#1976d2' }} />
                                <StackedBarChartIcon style={{ fontSize: '2.6rem', color: '#1976d2' }} />
                                <img
                                    src={scatteredChartImage}
                                    alt="Scatter Chart Icon" 
                                />
                                <img
                                    src={lineBarChartImage}
                                    alt="LineBar Chart Icon" 
                                />
                            </div> */}
            </span>
          </span>
        </span>
        <SidebarSection
          title="Position"
          subSection={false}
          isOpen={openSection === "position"}
          onToggle={() => handleToggle("position")}
        >
          <span className="d-flex px-2 py-2 justify-center">
            <span className="d-flex flex-col">
              <span className="d-flex py-2">
                <p className="prop-label mb-1 self-end">Position (px):</p>
                <span className="mx-2">
                  <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-700">
                    Left
                  </p>
                  <input
                    type="number"
                    className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                    value={Math?.round(component?.properties?.left)}
                    onChange={(e) => handlePositionChange(e, "left")}
                  />
                </span>
                <span className="mx-2">
                  <p className="m-0 font-medium text-tiny text-gray-700 whitespace-nowrap">
                    Top
                  </p>
                  <input
                    type="number"
                    className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                    value={Math?.round(component?.properties?.top)}
                    onChange={(e) => handlePositionChange(e, "top")}
                  />
                </span>
              </span>
              <span className="d-flex py-2 self-end">
                <span className="mx-2">
                  <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-700">
                    Right
                  </p>
                  <input
                    type="number"
                    className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                    value={Math?.round(component?.properties?.right)}
                    onChange={(e) => handlePositionChange(e, "right")}
                  />
                </span>
                <span className="mx-2">
                  <p className="m-0 font-medium text-tiny text-gray-700 whitespace-nowrap">
                    Bottom
                  </p>
                  <input
                    type="number"
                    className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                    value={Math?.round(component?.properties?.bottom)}
                    onChange={(e) => handlePositionChange(e, "bottom")}
                  />
                </span>
              </span>
            </span>
          </span>
        </SidebarSection>
        <SidebarSection
          title="Appearance"
          subSection={false}
          isOpen={openSection === "appearance"}
          onToggle={() => handleToggle("appearance")}
        >
          <span className="justify-center px-2 py-2 pt-3 space-x-2 flex sm:flex-row lg:flex-row">
            <span className="m-0 lg:self-end lg:pb-0 mb-1 sm:self-center sm:pb-7">
              <p className="m-0 prop-label lg:self-end mb-0">Size (px):</p>
            </span>
            <span className="flex lg:flex-row sm:flex-col lg:space-x-2 sm:space-y-1 lg:space-y-0">
              <span className="flex flex-col space-x-2">
                <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-700 pl-2">
                  Height
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.height || 0}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    onPropertyChange({ height: Number(newValue) });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        height: Number(newValue),
                      },
                    }));
                    setFlag(false);
                    setIsAnimated(false);
                  }}
                />
              </span>
              <span className="flex flex-col space-x-2">
                <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-700 pl-2">
                  Width
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.width || 0}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    onPropertyChange({ width: Number(newValue) });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        width: Number(newValue),
                      },
                    }));
                    setFlag(false);
                    setIsAnimated(false);
                  }}
                />
              </span>
            </span>
          </span>
          <div className="flex lg:flex-row sm:flex-col justify-center items-center space-x-4">
            <span className="flex  py-2 space-x-4 lg:ml-5 sm:ml-16">
              <p className="m-0  whitespace-nowrap prop-label self-center">
                Border:
              </p>
              <select
                value={component?.properties?.borderType || "solid"}
                onChange={(e) => {
                  onPropertyChange({ borderType: e.target.value });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      borderType: e.target.value,
                    },
                  }));
                  setFlag(false);
                  setIsAnimated(false);
                }}
                className="block ml-2 p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded  focus:ring-blue-500 focus:border-blue-500"
              >
                <option value={-1}>Select Border Type</option>
                {["Solid", "Dotted", "Dashed", "Double", "None"].map(
                  (item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  )
                )}
              </select>
            </span>
            <span
              className="relative flex sm:flex-row sm:space-x-4 lg:space-x-0 sm:py-2 sm:pr-20 lg:pr-0"
              ref={borderColorRef}
            >
              <p className="m-0  whitespace-nowrap prop-label self-center sm:block lg:hidden">
                Border Color:
              </p>
              <span className="flex items-center border-custome ">
                <span
                  className="w-6 h-6 sketch-border"
                  style={{
                    backgroundColor:
                      component?.properties?.borderColor || "#000000",
                  }}
                />
                <span
                  className="w-5 h-6 text-center cursor-pointer"
                  onClick={() => {
                    setColorPickerOpen(!colorPickerOpen);
                    setColorPickerBg(false);
                    setColorPickerText(false);
                  }}
                >
                  <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                </span>
              </span>
              {colorPickerOpen && (
                <div className="absolute z-10 right-0 bottom-0 mb-[38px] cursor-pointer">
                  <SketchPicker
                    color={
                      component?.properties?.borderColor || "rgba(0,0,0,1)"
                    }
                    onChange={(color) => {
                      const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                      onPropertyChange({ borderColor: rgbaColor });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          borderColor: rgbaColor,
                        },
                      }));
                      setFlag(false);
                      setIsAnimated(false);
                    }}
                  />
                </div>
              )}
            </span>
          </div>
          <span className="d-flex justify-center py-2 space-x-4 lg:mr-[126px] sm:mr-6">
            <p className="m-0  whitespace-nowrap prop-label self-center">
              Border Width:
            </p>
            <input
              type="number"
              className="block p-1  text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500 "
              value={component?.properties?.borderWidth || 0}
              onChange={(e) => {
                let newValue = e.target.value;
                if (newValue !== "") {
                  newValue = newValue.replace(/^0+/, "") || "0";
                }
                if (parseInt(newValue) < 0) {
                  newValue = "0";
                }
                onPropertyChange({ borderWidth: newValue });
                setSelectedComponent((prev) => ({
                  ...prev,
                  properties: {
                    ...prev.properties,
                    borderWidth: newValue,
                  },
                }));
                setFlag(false);
                setIsAnimated(false);
              }}
            />
          </span>
          <div className="d-flex justify-center py-2 pb-3 space-x-4 lg:mr-[197px] sm:mr-24">
            <p className="m-0 prop-label whitespace-nowrap  self-center">
              Background Color:
            </p>
            <span className="relative" ref={backgroundColorRef}>
              <span className="flex items-center border-custome ">
                <span
                  className="w-6 h-6 sketch-border"
                  style={{
                    backgroundColor:
                      component?.properties?.option?.backgroundColor || "#fff",
                  }}
                />
                <span
                  className="w-5 h-6 text-center cursor-pointer"
                  onClick={() => {
                    setColorPickerBg(!colorPickerBg);
                    setColorPickerOpen(false);
                    setColorPickerText(false);
                  }}
                >
                  <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                </span>
              </span>
              {colorPickerBg && (
                <div className="absolute z-10 bottom-0 mb-[32px] cursor-pointer">
                  <SketchPicker
                    color={
                      component?.properties?.option?.backgroundColor ||
                      "rgba(255,255,255,1)"
                    }
                    onChange={(color) => {
                      const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                      const option = {
                        ...component?.properties?.option,
                        backgroundColor: rgbaColor,
                      };
                      onPropertyChange({ option: option });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          option: option,
                        },
                      }));
                      setFlag(false);
                      setIsAnimated(false);
                    }}
                  />
                </div>
              )}
            </span>
          </div>
        </SidebarSection>

        <SidebarSection
          title={component?.properties?.chartType === "pie" ? "Slice" : "Axis"}
          subSection={false}
          isOpen={openSection === "axis"}
          onToggle={() => handleToggle("axis")}
        >
          <div className="mt-2">
            {component?.properties?.chartType === "pie" ? (
              <span className="d-flex justify-center py-2 space-x-3 lg:ml-8 sm:ml-9">
                <p className="m-0  whitespace-nowrap prop-label self-center">
                  Slice:
                </p>
                <select
                  value={component?.properties?.xAxis || ""}
                  onChange={(e) => {
                    if (
                      e.target.value === component?.properties?.yAxis &&
                      component?.properties?.chartType === "bar"
                    ) {
                      showAlert(
                        "The x-axis and y-axis values cannot be the same",
                        "error"
                      );
                    } else {
                      updateChartAxis({
                        xAxis: e.target.value,
                        yAxis: component?.properties?.yAxis,
                      });
                    }
                  }}
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded cursor-pointer focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value={-1}>Select x-axis value</option>
                  {component?.properties?.columns.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </span>
            ) : (
              <div className="d-flex justify-center py-2 space-x-3 lg:mr-1 sm:mr-5">
                <p className="m-0  whitespace-nowrap prop-label self-center">
                  X-axis:
                </p>
                <select
                  value={component?.properties?.xAxis || ""}
                  onChange={(e) => {
                    if (
                      e.target.value === component?.properties?.yAxis ||
                      e.target.value === component?.properties?.line
                    ) {
                      showAlert(
                        "The x-axis cannot be same as y-axis or line values",
                        "error"
                      );
                    } else {
                      const zAxisValue = component?.properties?.zAxis?.filter(
                        (item) => item !== e?.target?.value
                      );
                      if (component?.properties?.chartType == "double") {
                        const newValue = component?.properties?.zAxis?.filter(
                          (item) => item !== e?.target?.value
                        );
                        updateChartAxis({
                          xAxis: e.target.value,
                          yAxis: "",
                          zAxis: newValue,
                          logColumns: component?.properties?.logColumns?.double,
                        });
                      } else {
                        updateChartAxis({
                          xAxis: e.target.value,
                          yAxis: component?.properties?.yAxis,
                          zAxis: zAxisValue,
                          isLogScaleY: component?.properties?.isLogScaleY,
                          isLogScaleZ: component?.properties?.isLogScaleZ,
                        });
                      }
                    }
                  }}
                  className="block  p-1 text-xs lg:w-[110px] sm:w-[90px] text-gray-900 bg-white border-custome cursor-pointer  rounded  focus:ring-blue-500 focus:border-blue-500"
                >
                  {/* <option value={-1}>Select x-axis value</option> */}
                  {component?.properties?.columns.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          {component?.properties?.chartType !== "pie" && (
            <span>
              {component?.properties?.chartType == "double" ? (
                <>
                  <div className="d-flex  space-x-3 justify-center py-2 ">
                    <p className="m-0  whitespace-nowrap prop-label self-center">
                      Y-axis:
                    </p>
                    <div
                      className="relative flex bg-white  border-custome flex-row items-center gap-1 divide-x-2 rounded lg:w-[110px] "
                      ref={chartDropDownRef}
                    >
                      <span className=" flex flex-row border-0 items-center h-full  overflow-scroll flex-nowrap no-scrollbar border-gray-400 ">
                        <div className="m-0 d-flex items-center  text-xs pr-1 pl-[7px] text-gray-800 whitespace-nowrap">
                          Select y-axis
                        </div>
                      </span>
                      <button
                        className="border-0"
                        onClick={() => {
                          setChartListOpen(!chartListOpen);
                          setDropdownOpen(false);
                        }}
                      >
                        <KeyboardArrowDownIcon className="text-gray-800"></KeyboardArrowDownIcon>
                      </button>
                      {chartListOpen && (
                        <div
                          className="absolute flex flex-col overflow-auto custom-scrollbar bg-white border rounded-md max-h-52 top-9 z-10"
                          style={{
                            boxShadow:
                              "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          {" "}
                          {component?.properties?.columns
                            .filter(
                              (item) => item !== component?.properties?.xAxis
                            )
                            .map((item, index) => (
                              <div
                                className={classNames(
                                  "hover:bg-primarylightgrey",
                                  "border-b",
                                  "d-flex px-2 py-2 items-center gap-2"
                                )}
                              >
                                <label className="flex flex-row items-center gap-2 cursor-pointer">
                                  <input
                                    type="checkbox"
                                    className="w-4 h-4 checked:lightblue"
                                    checked={
                                      component?.properties?.zAxis.includes(
                                        item
                                      ) || component?.properties?.yAxis === item
                                    }
                                    onChange={() =>
                                      handleCheckboxChangeZAxis(item)
                                    }
                                  />
                                  <p
                                    className={classNames(
                                      "m-0 cursor-pointer text-xs font-base"
                                    )}
                                  >
                                    {item}
                                  </p>
                                </label>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <span className="d-flex space-x-3 justify-center py-2 ml-[15px]">
                    <p className="m-0 whitespace-nowrap prop-label self-center">
                      Log:
                    </p>
                    <div
                      className="relative flex bg-white  border-custome flex-row items-center gap-4 divide-x-2 rounded  w-[110px]"
                      ref={logDropDownRef}
                    >
                      <span className=" flex flex-row border-0 items-center  h-full   overflow-scroll flex-nowrap no-scrollbar border-gray-400 ">
                        <span className="m-0 d-flex items-center gap-1 text-xs pl-[7px] text-gray-800 whitespace-nowrap ">
                          Select log
                        </span>
                      </span>
                      <button
                        className=" border-0 "
                        onClick={() => {
                          setDropdownOpen(!dropdownOpen);
                          setChartListOpen(false);
                        }}
                      >
                        <KeyboardArrowDownIcon className="text-gray-800"></KeyboardArrowDownIcon>
                      </button>
                      {dropdownOpen && (
                        <span
                          className="absolute flex flex-col w-fit overflow-auto custom-scrollbar bg-white border rounded-md max-h-52 top-9 z-10"
                          style={{
                            boxShadow:
                              "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          {zAxisValues.map((value, index) => (
                            <span
                              className={classNames(
                                "hover:bg-primarylightgrey",
                                "border-b",
                                "d-flex px-2 py-2 items-center gap-2"
                              )}
                            >
                              <label
                                key={index}
                                className="flex flex-row items-center gap-2 cursor-pointer"
                              >
                                <input
                                  type="checkbox"
                                  className="w-4 h-4 checked:lightblue pr-1"
                                  checked={component?.properties?.logColumns?.double?.includes(
                                    `Log(${value})`
                                  )}
                                  onChange={() =>
                                    handleCheckboxChangeLog(`Log(${value})`)
                                  }
                                />
                                <p
                                  className={classNames(
                                    "m-0 cursor-pointer text-xs font-base"
                                  )}
                                >
                                  log({value})
                                </p>
                              </label>
                            </span>
                          ))}
                        </span>
                      )}
                    </div>
                  </span>
                </>
              ) : (
                <span className="d-flex py-2 items-center justify-center  lg:space-x-3 sm:space-x-2  sm:ml-[69px] lg:ml-[110px]">
                  <div className="d-flex justify-center  space-x-3 ">
                    <p className="m-0  whitespace-nowrap prop-label self-center">
                      Y-axis:
                    </p>
                    <select
                      value={component?.properties?.yAxis || "solid"}
                      onChange={(e) => {
                        setIsLogScaleY(false);
                        if (
                          e.target.value === component?.properties?.xAxis ||
                          e.target.value === component?.properties?.line
                        ) {
                          showAlert(
                            "The y-axis value cannot be same as x-axis or line values",
                            "error"
                          );
                        } else {
                          const zAxisValue =
                            component?.properties?.zAxis?.filter(
                              (item) => item !== e?.target?.value
                            );
                          updateChartAxis({
                            yAxis: e.target.value,
                            xAxis: component?.properties?.xAxis,
                            zAxis: zAxisValue,
                            line: component?.properties?.line,
                            isLogScaleY: false,
                            isLogScaleZ: component?.properties?.isLogScaleZ,
                          });
                        }
                      }}
                      className="block lg:w-[110px] sm:w-[90px] p-1 text-xs text-gray-900 cursor-pointer bg-white border-custome  rounded focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value={-1} className="bg-white">
                        Select y-axis value
                      </option>
                      {component?.properties?.columns.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <span className="d-flex sm:w-20 lg:w-[100px]">
                    <input
                      type="checkbox"
                      className="self-center cursor-pointer"
                      checked={component?.properties?.isLogScaleY}
                      onChange={() => {
                        updateChartAxis({
                          yAxis: component?.properties?.yAxis,
                          xAxis: component?.properties?.xAxis,
                          zAxis: component?.properties?.zAxis,
                          line: component?.properties?.line,
                          isLogScaleY: !component?.properties?.isLogScaleY,
                          isLogScaleZ: component?.properties?.isLogScaleZ,
                        });
                      }}
                    />
                    <Tooltip title={`Log (${component?.properties?.yAxis})`}>
                      <p className="m-0 whitespace-nowrap prop-label self-center pl-1 sm:w-[60px] lg:w-[82px] text-xs cursor-pointer ">
                        Log (
                        <span className="truncate inline-block align-middle sm:w-[20px] lg:w-fit">
                          {/* {component?.properties?.yAxis?.length > 6
                                                    ? `${component?.properties?.yAxis.slice(0, 5)}...`
                                                    : component?.properties?.yAxis} */}
                          {component?.properties?.yAxis
                            ? component?.properties?.yAxis.length > 6
                              ? `${component?.properties?.yAxis.slice(0, 5)}...`
                              : component?.properties?.yAxis
                            : null}
                        </span>
                        )
                      </p>
                    </Tooltip>
                  </span>
                </span>
              )}
              {component?.properties?.chartType !== "pie" &&
                component?.properties?.chartType !== "double" &&
                component?.properties?.chartType !== "barWithLine" && (
                  <span className="d-flex py-2 items-center justify-center  lg:space-x-3 sm:space-x-2  sm:ml-[69px] lg:ml-[110px]">
                    <div className="d-flex justify-center  space-x-3 ">
                      <p className="m-0  whitespace-nowrap prop-label self-center">
                        Z-axis:
                      </p>
                      <select
                        value={component?.properties?.zAxis[0] || "solid"}
                        onChange={(e) => {
                          if (
                            e.target.value === component?.properties?.xAxis ||
                            e.target.value === component?.properties?.line ||
                            e.target.value === component?.properties?.yAxis
                          ) {
                            showAlert(
                              "The z-axis value cannot be same as x-axis,y-axis or line values",
                              "error"
                            );
                          } else {
                            updateChartAxis({
                              yAxis: component?.properties?.yAxis,
                              xAxis: component?.properties?.xAxis,
                              zAxis:
                                e.target.value === "" ? [] : [e.target.value],
                              line: component?.properties?.line,
                              isLogScaleY: component?.properties?.isLogScaleY,
                              isLogScaleZ: false,
                            });
                          }
                        }}
                        className="block lg:w-[110px] sm:w-[90px] p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value={""} className="bg-white">
                          Select z-axis value
                        </option>
                        {component?.properties?.columns.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <span className="d-flex sm:w-20 lg:w-[100px]">
                      <input
                        type="checkbox"
                        checked={component?.properties?.isLogScaleZ}
                        className="self-center cursor-pointer"
                        onChange={() => {
                          updateChartAxis({
                            yAxis: component?.properties?.yAxis,
                            xAxis: component?.properties?.xAxis,
                            zAxis: component?.properties?.zAxis,
                            line: component?.properties?.line,
                            isLogScaleY: component?.properties?.isLogScaleY,
                            isLogScaleZ: !component?.properties?.isLogScaleZ,
                          });
                        }}
                      />
                      <Tooltip title={`Log (${component?.properties?.zAxis})`}>
                        <p className="m-0 whitespace-nowrap prop-label self-center pl-1 sm:w-[60px] lg:w-[82px] text-xs cursor-pointer ">
                          Log (
                          <span className="truncate inline-block align-middle sm:w-[20px] lg:w-fit">
                            {Array.isArray(component?.properties?.zAxis) &&
                            component?.properties?.zAxis.length > 0
                              ? component?.properties?.zAxis[0]?.length > 6
                                ? `${component?.properties?.zAxis[0].slice(
                                    0,
                                    5
                                  )}...`
                                : component?.properties?.zAxis[0]
                              : ""}
                          </span>
                          )
                        </p>
                      </Tooltip>
                    </span>
                  </span>
                )}
              {component?.properties?.chartType === "barWithLine" && (
                <span className="d-flex py-2 items-center justify-center  lg:space-x-3 sm:space-x-2  sm:ml-[69px] lg:ml-[110px]">
                  <div className="d-flex justify-center  space-x-3 ">
                    <p className="m-0  whitespace-nowrap prop-label self-center">
                      Z-Axis:
                    </p>
                    <select
                      value={component?.properties?.line || "none"}
                      onChange={(e) => {
                        if (
                          e.target.value === component?.properties?.xAxis ||
                          e.target.value === component?.properties?.yAxis
                        ) {
                          showAlert(
                            "Line value cannot be the same as the selected x-axis or y-axis values.",
                            "error"
                          );
                        } else {
                          if (e.target?.value === "none") {
                            updateChartAxis({
                              yAxis: component?.properties?.yAxis,
                              xAxis: component?.properties?.xAxis,
                              zAxis: [],
                              line: null,
                              isLogScaleY: component?.properties?.isLogScaleY,
                              isLogScaleZ: false,
                            });
                          } else {
                            updateChartAxis({
                              yAxis: component?.properties?.yAxis,
                              xAxis: component?.properties?.xAxis,
                              zAxis: [],
                              line: e.target.value,
                              isLogScaleY: component?.properties?.isLogScaleY,
                              isLogScaleZ: false,
                            });
                          }
                        }
                      }}
                      className="block lg:w-[110px] sm:w-[90px] p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value={null}>none</option>
                      {component?.properties?.columns.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <span className="d-flex sm:w-20 lg:w-[100px]">
                    <input
                      type="checkbox"
                      checked={component?.properties?.isLogScaleZ}
                      className="self-center cursor-pointer"
                      onChange={() => {
                        updateChartAxis({
                          yAxis: component?.properties?.yAxis,
                          xAxis: component?.properties?.xAxis,
                          zAxis: component?.properties?.zAxis,
                          line: component?.properties?.line,
                          isLogScaleY: component?.properties?.isLogScaleY,
                          isLogScaleZ: !component?.properties?.isLogScaleZ,
                        });
                      }}
                    />
                    <Tooltip title={`Log (${component?.properties?.line})`}>
                      <p className="m-0 whitespace-nowrap prop-label self-center pl-1 sm:w-[60px] lg:w-[82px] text-xs cursor-pointer ">
                        Log (
                        <span className="truncate inline-block align-middle sm:w-[20px] lg:w-fit">
                          {component?.properties?.line
                            ? component?.properties?.line?.length > 6
                              ? `${component?.properties?.line?.slice(0, 5)}...`
                              : component?.properties?.line
                            : null}
                        </span>
                        )
                      </p>
                    </Tooltip>
                  </span>
                </span>
              )}
              <span className="d-flex justify-center py-2 space-x-3 mr-[15px]">
                <p className="m-0  whitespace-nowrap prop-label self-center">
                  Min:
                </p>
                <input
                  type="number"
                  className="block p-1  text-xs text-gray-900 bg-white border-custome  rounded w-[80px]  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.option?.yAxis?.min}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue !== "") {
                      newValue = newValue.replace(/^0+/, "") || "0";
                    }
                    if (parseInt(newValue) < 0) {
                      newValue = "0";
                    }
                    const option = {
                      ...component?.properties?.option,
                      yAxis: {
                        ...component?.properties?.option?.yAxis,
                        min: Number(newValue),
                      },
                    };
                    onPropertyChange({ option: option });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        option: option,
                      },
                    }));
                    setFlag(false);
                    setIsAnimated(false);
                  }}
                />
              </span>
            </span>
          )}
          <div className="d-flex justify-center py-2 space-x-3 mr-[37px]">
            <p className="m-0  whitespace-nowrap prop-label self-center">
              Interval:
            </p>
            <input
              type="number"
              className="block p-1  text-xs text-gray-900 bg-white border-custome  rounded w-[80px]  focus:ring-blue-500 focus:border-blue-500"
              value={component?.properties?.option?.yAxis?.interval}
              onChange={(e) => {
                let newValue = e.target.value;
                if (newValue !== "") {
                  newValue = newValue.replace(/^0+/, "") || "0";
                }
                if (parseInt(newValue) < 0) {
                  newValue = "0";
                }
                const option = {
                  ...component?.properties?.option,
                  yAxis: {
                    ...component?.properties?.option?.yAxis,
                    interval: Number(newValue),
                  },
                };
                onPropertyChange({ option: option });
                setSelectedComponent((prev) => ({
                  ...prev,
                  properties: {
                    ...prev.properties,
                    option: option,
                  },
                }));
                setFlag(false);
                setIsAnimated(false);
              }}
            />
          </div>
          <span className=" space-x-3  flex lg:flex-row sm:flex-col justify-center items-center">
            <span className="flex  py-2 space-x-3 lg:ml-[83px] sm:ml-7">
              <p className="m-0  whitespace-nowrap prop-label self-center">
                Labels:
              </p>
              <select
                value={
                  component?.properties?.option?.xAxis?.axisLabel?.fontFamily
                }
                onChange={(e) => {
                  const option = {
                    ...component?.properties?.option,
                    xAxis: {
                      ...component?.properties?.option?.xAxis,
                      axisLabel: {
                        ...component?.properties?.option?.xAxis?.axisLabel,
                        fontFamily: e.target.value,
                      },
                    },
                    yAxis: {
                      ...component?.properties?.option?.yAxis,
                      axisLabel: {
                        ...component?.properties?.option?.yAxis?.axisLabel,
                        fontFamily: e.target.value,
                      },
                    },
                  };
                  onPropertyChange({ option: option });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      option: option,
                    },
                  }));
                  setFlag(false);
                  setIsAnimated(false);
                }}
                className="block p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded  focus:ring-blue-500 focus:border-blue-500"
              >
                <option value={"Inter"}>Select Font Family</option>
                {Fonts.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </span>
            <span
              className="relative flex sm:flex-row sm:space-x-3 lg:space-x-0 sm:py-2 sm:pr-[100px] lg:pr-0"
              ref={textColorRef}
            >
              <p className="m-0  whitespace-nowrap prop-label self-center sm:block lg:hidden">
                Label Color:
              </p>
              <span className="flex items-center border-custome ">
                <span
                  className="w-6 h-6 sketch-border"
                  style={{
                    backgroundColor:
                      component?.properties?.option?.xAxis?.axisLabel?.color ||
                      "#fff",
                  }}
                />
                <span
                  className="w-5 h-6 text-center cursor-pointer"
                  onClick={() => {
                    setColorPickerText(!colorPickerText);
                    setColorPickerBg(false);
                    setColorPickerOpen(false);
                  }}
                >
                  <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                </span>
              </span>
              {colorPickerText && (
                <div className="absolute z-10 bottom-0 right-0 mb-[38px] cursor-pointer">
                  <SketchPicker
                    color={
                      component?.properties?.option?.xAxis?.axisLabel?.color ||
                      "rgba(0, 0, 0, 1)"
                    }
                    onChange={(color) => {
                      const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                      const option = {
                        ...component?.properties?.option,
                        xAxis: {
                          ...component?.properties?.option?.xAxis,
                          axisLabel: {
                            ...component?.properties?.option?.xAxis?.axisLabel,
                            color: rgbaColor,
                          },
                        },
                        yAxis: {
                          ...component?.properties?.option?.yAxis,
                          axisLabel: {
                            ...component?.properties?.option?.yAxis?.axisLabel,
                            color: rgbaColor,
                          },
                        },
                      };
                      onPropertyChange({ option: option });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          option: option,
                        },
                      }));
                      setFlag(false);
                      setIsAnimated(false);
                    }}
                  />
                </div>
              )}
            </span>
          </span>
          <span className="d-flex justify-center py-2 space-x-3 mr-[60px]">
            <p className="block  m-0  whitespace-nowrap prop-label self-center">
              Labels Size:
            </p>
            <input
              type="number"
              className="block p-1  text-xs text-gray-900 bg-white border-custome  rounded w-[80px]  focus:ring-blue-500 focus:border-blue-500 lg:ml-0 ml-2"
              value={
                component?.properties?.option?.xAxis?.axisLabel?.fontSize || 0
              }
              onChange={(e) => {
                let newValue = e.target.value;
                if (newValue !== "") {
                  newValue = newValue.replace(/^0+/, "") || "0";
                }
                if (parseInt(newValue) < 0) {
                  newValue = "0";
                }
                const option = {
                  ...component?.properties?.option,
                  xAxis: {
                    ...component?.properties?.option?.xAxis,
                    axisLabel: {
                      ...component?.properties?.option?.xAxis?.axisLabel,
                      fontSize: newValue,
                    },
                  },
                  yAxis: {
                    ...component?.properties?.option?.yAxis,
                    axisLabel: {
                      ...component?.properties?.option?.yAxis?.axisLabel,
                      fontSize: newValue,
                    },
                  },
                };
                onPropertyChange({ option: option });
                setSelectedComponent((prev) => ({
                  ...prev,
                  properties: {
                    ...prev.properties,
                    option: option,
                  },
                }));
                setFlag(false);
                setIsAnimated(false);
              }}
            />
          </span>
          {component?.properties?.chartType !== "pie" && (
            <span className="d-flex justify-center py-2 space-x-3 mr-[130px] sm:ml-10 lg:ml-0 mb-2">
              <p className="m-0  whitespace-nowrap prop-label self-center lg:block sm:hidden">
                Labels Rotation(x-axis):
              </p>
              <p className="m-0  whitespace-nowrap prop-label self-center sm:block lg:hidden">
                Label Rotation(x):
              </p>
              <input
                type="number"
                className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[80px]  focus:ring-blue-500 focus:border-blue-500 lg:ml-0 ml-2"
                value={component?.properties?.option?.xAxis?.axisLabel?.rotate}
                onChange={(e) => {
                  let newValue = e.target.value;
                  // if (newValue !== '') {
                  //     newValue = newValue.replace(/^0+/, '') || '0';
                  // }
                  // if (parseInt(newValue) < 0) {
                  //     newValue = '0';
                  // }
                  const option = {
                    ...component?.properties?.option,
                    xAxis: {
                      ...component?.properties?.option?.xAxis,
                      axisLabel: {
                        ...component?.properties?.option?.xAxis?.axisLabel,
                        rotate: newValue,
                      },
                    },
                  };
                  onPropertyChange({ option: option });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      option: option,
                    },
                  }));
                  setFlag(false);
                  setIsAnimated(false);
                }}
              />
            </span>
          )}
        </SidebarSection>

        <SidebarSection
          title="Legends"
          subSection={false}
          isOpen={openSection === "legends"}
          onToggle={() => handleToggle("legends")}
        >
          <div
            style={{ maxHeight: "44vh", overflow: "auto" }}
            className="py-2 custom-scrollbar"
          >
            {component?.properties?.chartType === "bar" ||
            component?.properties?.chartType === "barWithLine" ||
            component?.properties?.chartType === "stacked" ||
            component?.properties?.chartType === "double" ||
            component?.properties?.chartType === "line" ||
            component?.properties?.chartType === "area" ||
            component?.properties?.chartType === "scatter"
              ? component?.properties?.option?.series.map((item) => (
                  <div className="d-flex items-center gap-2 px-3 py-1">
                    <div
                      ref={(el) => (colorPickerRefs.current[item.name] = el)}
                    >
                      <input
                        type="text"
                        className="block p-1 text-sm text-gray-900  border border-gray-300  w-full focus:ring-blue-500 focus:border-blue-500"
                        value={""}
                        onClick={() => {
                          handleColorPickerToggle(item.name);
                        }}
                        readOnly
                        style={{
                          backgroundColor: item?.itemStyle?.color || "#fff",
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                      />
                      {colorPickerState[item.name] && (
                        <div
                          className="absolute z-10   mt-1 mx-1"
                          style={{
                            top: "-180px",
                            left: "40px",
                            cursor: "pointer",
                          }}
                        >
                          <SketchPicker
                            color={item?.itemStyle?.color || "rgba(0, 0, 0, 1)"}
                            onChange={(color) => {
                              const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b},${color.rgb.a})`;
                              const option = {
                                ...component?.properties?.option,
                                series:
                                  component?.properties?.option?.series.map(
                                    (seriesItem) => {
                                      if (seriesItem?.name === item?.name) {
                                        return {
                                          ...seriesItem, // Copy existing series item
                                          itemStyle: {
                                            ...seriesItem.itemStyle, // Copy existing itemStyle
                                            color: rgbaColor, // Update color
                                          },
                                        };
                                      }
                                      return seriesItem;
                                    }
                                  ),
                              };
                              onPropertyChange({ option: option });
                              setSelectedComponent((prev) => ({
                                ...prev,
                                properties: {
                                  ...prev.properties,
                                  option: option,
                                },
                              }));
                              setFlag(false);
                              setIsAnimated(false);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="m-0 prop-label whitespace-nowrap  self-center">
                      {parseNull(item?.name, "NA")}
                    </div>
                  </div>
                ))
              : component?.properties?.option?.series[0]?.data.map((item) => (
                  <div className="d-flex items-center gap-2 px-3 py-1">
                    <div
                      ref={(el) => (colorPickerRefs.current[item.name] = el)}
                    >
                      <input
                        type="text"
                        className="block p-1 text-sm text-gray-900  border border-gray-300  w-full focus:ring-blue-500 focus:border-blue-500"
                        value={""}
                        onClick={() => {
                          handleColorPickerToggle(item.name);
                        }}
                        readOnly
                        style={{
                          backgroundColor: item?.itemStyle?.color || "#fff",
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                      />
                      {colorPickerState[item.name] && (
                        <div
                          className="absolute z-10   mt-1 mx-1"
                          style={{
                            top: "-180px",
                            left: "40px",
                            cursor: "pointer",
                          }}
                        >
                          <SketchPicker
                            color={item?.itemStyle?.color || "rgba(0, 0, 0, 1)"}
                            onChange={(color) => {
                              const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b},${color.rgb.a})`;
                              const updatedSeries =
                                component?.properties?.option?.series[0]?.data.map(
                                  (seriesItem) =>
                                    seriesItem.name === item.name
                                      ? {
                                          ...seriesItem,
                                          itemStyle: {
                                            ...seriesItem.itemStyle,
                                            color: rgbaColor,
                                          },
                                        }
                                      : seriesItem
                                );
                              const updatedOption = {
                                ...component?.properties?.option,
                                series: [
                                  {
                                    ...component?.properties?.option?.series[0],
                                    data: updatedSeries,
                                  },
                                ],
                              };
                              onPropertyChange({ option: updatedOption });
                              setSelectedComponent((prev) => ({
                                ...prev,
                                properties: {
                                  ...prev.properties,
                                  option: updatedOption,
                                },
                              }));
                              setFlag(false);
                              setIsAnimated(false);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="m-0 prop-label whitespace-nowrap  self-center">
                      {parseNull(item?.name, "NA")}
                    </div>
                  </div>
                ))}
          </div>
        </SidebarSection>
      </div>
    </>
  );
}
export default ChartProperties;
