import { apiSlice } from "../../app/api/apiSlice";

export const chatApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchChats: builder.mutation({
      query: () => ({
        url: "/conversation/",
        method: "GET",
      }),
    }),
    createConversation: builder.mutation({
      query: (credentials) => ({
        url: "/conversation/",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    updateConversation: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/${credentials.id}`,
        method: "PUT",
        body: { ...credentials },
      }),
    }),
    deleteConversation: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/${credentials.id}`,
        method: "DELETE",
      }),
    }),
    addParticipant: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/${credentials.id}/participant/${credentials.userId}`,
        method: "POST",
      }),
    }),
    removeParticipant: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/${credentials.id}/participant/${credentials.userId}`,
        method: "DELETE",
      }),
    }),
    updateParticipantAuthorization: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/${credentials.id}/participant/${credentials.userId}`,
        method: "PUT",
        body: { ...credentials },
      }),
    }),
    fetchConversationMessages: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/${credentials.id}/messages`,
        method: "GET",
      }),
    }),
    fetchConversationById: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/${credentials.id}`,
        method: "GET",
      }),
    }),
    fetchStarredMessages: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/starred-messages/${credentials.id}`,
        method: "GET",
      }),
    }),
    fetchTaggedPatients: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/tagged-patients/${credentials.id}`,
        method: "GET",
      }),
    }),
    fetchTaggedPatientsMessages: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/tagged-patients/${credentials.id}/messages/${credentials.patientId}`,
        method: "GET",
      }),
    }),
    fetchTaggedUsers: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/tagged-users/${credentials.id}`,
        method: "GET",
      }),
    }),
    fetchTaggedUsersMessages: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/tagged-users/${credentials.id}/messages/${credentials.userId}`,
        method: "GET",
      }),
    }),
    fetchTaggedCaretags: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/tagged-caretags/${credentials.id}`,
        method: "GET",
      }),
    }),
    fetchTaggedCaretagsMessages: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/tagged-caretags/${credentials.id}/messages/${credentials.careTagId}`,
        method: "GET",
      }),
    }),
    fetchConversationPatients: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/conversation-patients/${credentials.id}`,
        method: "GET",
      }),
    }),
    fetchConversationAttachments: builder.mutation({
      query: (credentials) => ({
        url: `/conversation/conversation-attachments/${credentials.id}`,
        method: "GET",
      }),
    }),
    fetchLatestMessages: builder.mutation({
      query: (credentials) => ({
        url: `/message/latest`,
        method: "GET",
      }),
    }),
    starMessage: builder.mutation({
      query: (credentials) => ({
        url: `/message/star/${credentials.id}`,
        method: "PATCH",
      }),
    }),
    unstarMessage: builder.mutation({
      query: (credentials) => ({
        url: `/message/unstar/${credentials.id}`,
        method: "PATCH",
      }),
    }),
    uploadDocument: builder.mutation({
      query: (fileData) => ({
        url: `/attachment/upload`,
        method: "POST",
        body: fileData,
      }),
    }),
    uploadEHR: builder.mutation({
      query: (credentials) => ({
        url: `/message/send-to-ehr/${credentials.messageId}`,
        method: "PATCH",
        body: credentials,
      }),
    }),
  }),
});

export const {
  useFetchChatsMutation,
  useCreateConversationMutation,
  useUpdateConversationMutation,
  useDeleteConversationMutation,
  useAddParticipantMutation,
  useRemoveParticipantMutation,
  useUpdateParticipantAuthorizationMutation,
  useFetchConversationMessagesMutation,
  useFetchConversationByIdMutation,
  useStarMessageMutation,
  useUnstarMessageMutation,
  useFetchStarredMessagesMutation,
  useFetchTaggedPatientsMutation,
  useFetchTaggedPatientsMessagesMutation,
  useFetchTaggedUsersMutation,
  useFetchTaggedUsersMessagesMutation,
  useFetchTaggedCaretagsMutation,
  useFetchTaggedCaretagsMessagesMutation,
  useFetchConversationPatientsMutation,
  useFetchLatestMessagesMutation,
  useFetchConversationAttachmentsMutation,
  useUploadDocumentMutation,
  useUploadEHRMutation,
} = chatApiSlice;
