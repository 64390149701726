import { apiSlice } from "../../app/api/apiSlice";

export const athenaApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    isPublishEnabled: builder.mutation({
      query: (credentials) => ({
        url: "/appointment/fetch",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    publishToAthena: builder.mutation({
      query: (credentials) => ({
        url: `/athena/publish`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    uploadDocumentAthena: builder.mutation({
      query: (fileData) => ({
        url: `/athena/documents/clinicaldocuments`,
        method: "POST",
        body: fileData,
      }),
    }),
  }),
});

export const {
  useIsPublishEnabledMutation,
  usePublishToAthenaMutation,
  useUploadDocumentAthenaMutation,
} = athenaApiSlice;
