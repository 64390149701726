import React from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { createImageWithAlphabets } from "../../utilities/resuseFunctions";
import { useNavigate } from "react-router-dom";
import Home from "../../assets/images/Home.png";
import CloseSvg from "../../assets/svgs/CloseSvg";

const bgColorsMapHashed = {
  Monday: "#272E35",
  Tuesday: "#6792F4",
  Wednesday: "#27AE60",
  Thursday: "#F59638",
  Friday: "#EB5757",
  Saturday: "#828282",
  Sunday: "#828282",
};

const containerStyle = {
  width: "650px",
  height: "650px",
};

const co_ordinates = [
  {
    lat: 33.6735627,
    lng: -112.2497552,
    type: "provider",
    day: null,
  },
  { lat: 33.6173801, lng: -111.951563, type: "visit", day: "Monday" },
  { lat: 33.5993044, lng: -111.905388, type: "visit", day: "Tuesday" },
  { lat: 33.5993144, lng: -111.905488, type: "visit", day: "Wednesday" },
];

const MapV2 = ({ locationOnMap, selectedProvider }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    // setMap(null);
  }, []);

  const [infoWindowShown, setInfoWindowShown] = React.useState(false);

  const [locationIndex, setLocationIndex] = React.useState(null);

  // clicking the marker will toggle the infowindow
  const handleMarkerClick = (location) => {
    if (location.id === locationIndex) {
      setLocationIndex(null);
    } else {
      setLocationIndex(location.id);
    }
    setInfoWindowShown((isShown) => !isShown);
  };

  // if the maps api closes the infowindow, we have to synchronize our state
  const handleClose = React.useCallback(() => setInfoWindowShown(false), []);

  const navigate = useNavigate();

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      center={{
        lat: locationOnMap[0]?.locations[0]?.coordinates?.latitude || 33.4484,
        lng: locationOnMap[0]?.locations[0]?.coordinates?.longitude || -112.074,
      }}
      zoom={11}
      onUnmount={onUnmount}
    >
      <>
        <Marker
          onClick={() => handleMarkerClick(location)}
          position={{
            lat: selectedProvider.coords[0],
            lng: selectedProvider.coords[1],
          }}
          options={{
            icon: {
              url: Home,
              scaledSize: new window.google.maps.Size(30, 30),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(25, 25),
            },
          }}
        />
        {locationOnMap.map((dayLocations) =>
          dayLocations.locations.map((location, index) => (
            <Marker
              key={index}
              onClick={() => handleMarkerClick(location)}
              position={{
                lat: location?.coordinates?.latitude,
                lng: location?.coordinates?.longitude,
              }}
              options={{
                icon: {
                  url: createImageWithAlphabets(
                    location.minSequence === location.maxSequence
                      ? location.minSequence
                      : `${location.minSequence}-${location.maxSequence}`,
                    "",
                    bgColorsMapHashed[dayLocations.day],
                    "#fff",
                    true
                  ),
                  scaledSize: new window.google.maps.Size(30, 30),
                },
              }}
            >
              {infoWindowShown && locationIndex === location.id && (
                <InfoWindow
                  position={{
                    lat: location?.coordinates?.latitude,
                    lng: location?.coordinates?.longitude,
                  }}
                  onClose={handleClose}
                >
                  <div className="flex flex-col gap-4 w-fit max-h-48 no-scrollbar">
                    <div>
                      <p className="m-0 text-sm font-bold">{location.name}</p>
                      <p className="m-0 text-sm font-light">
                        {location.address}
                      </p>
                    </div>
                    <div className="flex flex-col gap-3">
                      {location.patients.map((patient, index) => (
                        <div
                          key={index}
                          className="flex flex-row items-center gap-2"
                        >
                          <div className="flex items-center justify-center w-4 h-4 text-[10px] text-white rounded-full bg-primaryblue">
                            {patient.sequenceNo}
                          </div>
                          <p
                            className="m-0 text-xs font-semibold underline cursor-pointer text-primaryblue"
                            onClick={() => {
                              navigate(
                                `/care-management/patients/${patient.id}`
                              );
                            }}
                          >
                            {patient.name}
                          </p>
                        </div>
                      ))}
                    </div>
                    <CloseSvg
                      role="button"
                      className="w-5 h-5 mt-1 mr-1 rounded-full close-marker outline outline-[1px]"
                      onClick={() => handleMarkerClick(location)}
                    />
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))
        )}
      </>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default MapV2;
