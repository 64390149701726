import React, { useEffect, useState } from "react";
import DateColumn from "./components/DateColumn";
import { useLSStore } from "../../context/ls-store";
import { flushSync } from "react-dom";
import moment from "moment";
import AppointmentFilterModal from "./components/AppointmentFilterModal";
import AppointmentSelectModal from "./components/AppointmentSelectModal";
import AppointmentOptions from "./components/AppointmentOptions";
import AppointmentAddModal from "./components/AppointmentAddModal";
import PublishAppointmentModal from "./components/PublishAppointmentModal";
import PublishSuccessCard from "./components/PublishSuccessCard";
import {
  useFetchAppointmentsMutation,
  useFetchProviderFiltersMutation,
  useFilterAppointmentOptionsMutation,
  useRescheduleAppointmentMutation,
} from "../../features/appointment/appointmentApiSlice";
import { useGetProvidersMutation } from "../../features/organisation/organisationApiSlice";
import { selectUser } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import ReusableModal from "../../components/Modals/ReusableModal";
import AvailibilityWarningModal from "./components/AvailibilityWarningModal";
import MapV2 from "../../components/Maps/MapV2";
import CloseSvg from "../../assets/svgs/CloseSvg";
import classNames from "classnames";
import { Tooltip } from "@mui/material";
import Loading from "../../components/Loading";

const bgColorsMap = {
  Monday: "bg-primaryblack",
  Tuesday: "bg-primaryblue",
  Wednesday: "bg-primarygreen",
  Thursday: "bg-primaryorange",
  Friday: "bg-primaryred",
  Saturday: "bg-primarytextgrey",
  Sunday: "bg-primarytextgrey",
};

const textColorsMap = {
  Monday: "text-primaryblack",
  Tuesday: "text-primaryblue",
  Wednesday: "text-primarygreen",
  Thursday: "text-primaryorange",
  Friday: "text-primaryred",
  Saturday: "text-primarytextgrey",
  Sunday: "text-primarytextgrey",
};

const LongitudinalSchedules = () => {
  const [appointments, setAppointments] = useState([]);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [loaderVisible, setLoaderVisible] = useState(true);
  const [weeks, setWeeks] = useState(null);
  const [recallSchedule, setRecallSchedule] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [publishOpen, setPublishOpen] = useState(false);

  const [appointmentStatus, setAppointmentStatus] = useState("All");
  const [locationType, setLocationType] = useState("All");
  const [appointmentType, setAppointmentType] = useState("All");
  const [community, setCommunity] = useState("All");
  const [patient, setPatient] = useState("All");
  const selectedAppointment = useLSStore((state) => state.selectedAppointment);

  const [successCard, setSuccessCard] = useState(false);

  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [patients, setPatients] = useState([]);

  const filterOpen = useLSStore((state) => state.filterOpen);
  const draggingAppointment = useLSStore((state) => state.draggingAppointment);
  const defaultWeekIndx = useLSStore((state) => state.defaultWeekIndx);

  const setFilterOpen = useLSStore((state) => state.setFilterOpen);

  const [rescheduleAppointments, { isLoading }] =
    useRescheduleAppointmentMutation();

  const [startDate, setStartDate] = useState("");
  const [startDateOpen, setStartDateOpen] = useState(false);

  const [endDate, setEndDate] = useState("");
  const [endDateOpen, setEndDateOpen] = useState(false);

  const [filterOptions, setFilterOptions] = useState(null);
  const [moveData, setMoveData] = useState(null);

  useEffect(() => {
    if (moveData !== null) {
      console.log("moveData", moveData);
    }
  }, [moveData]);

  const onDrop = async (
    column,
    index,
    isAvailable,
    suggestedAppointment = null
  ) => {
    if (draggingAppointment === null && suggestedAppointment === null) return;

    let appointmentId;

    if (suggestedAppointment) appointmentId = suggestedAppointment;
    else appointmentId = draggingAppointment;

    if (!isAvailable) {
      setMoveData({
        column,
        index,
        appointmentId,
      });
      setWarningModalOpen(true);
      return;
    }

    const { data } = await rescheduleAppointments({
      dayToBeMoved: weeks[defaultWeekIndx][column].date,
      indexToBeMoved: index,
      appointmentId: appointmentId,
    }).unwrap();

    const { fromDate, appointmentsTo, appointmentsFrom } = data;

    const newAppointments = {
      ...appointments,
      [weeks[defaultWeekIndx][column].date]: appointmentsTo,
      [fromDate]: appointmentsFrom,
    };

    if (document.startViewTransition) {
      document.startViewTransition(() => {
        flushSync(() => {
          setAppointments(newAppointments);
        });
      });
    } else {
      setAppointments(newAppointments);
    }

    setMoveData(null);
  };

  const onAdd = (colIndx, appt) => {
    const newAppts = appointments.map((x, ind) => {
      const appts = appointments[ind];

      if (ind === colIndx) {
        appts.push(appt);

        return appts;
      }

      return appts;
    });

    setAppointments(newAppts);
  };

  const getDatesInMonth = (month, year) => {
    const startDate = moment().startOf("week").add(1, "day");
    const endDate = moment(startDate).add(364, "day");

    const weeks = [];
    let currentWeek = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      currentWeek.push({
        date: currentDate.format("YYYY-MM-DD"),
        day: currentDate.format("ddd").toUpperCase(),
      });

      if (
        currentDate.isoWeekday() === 7 ||
        currentDate.isSame(endDate, "day")
      ) {
        weeks.push(currentWeek);
        currentWeek = [];
      }

      currentDate = currentDate.clone().add(1, "day");
    }

    return weeks;
  };

  const fetchAppointmentsFunction = async () => {
    try {
      const { data } = await fetchAppointments({
        providerId: selectedProvider?.id,
        appointmentType:
          appointmentType === "0" || appointmentType === "All"
            ? "All"
            : Number(appointmentType),
        locationType,
        appointmentStatus,
        locationId: community === "All" ? null : community,
        patientId: patient === "All" ? null : Number(patient),
        startDate: weeks?.[defaultWeekIndx]?.[0]?.date,
        endDate:
          weeks?.[defaultWeekIndx]?.[weeks?.[defaultWeekIndx]?.length - 1]
            ?.date,
      });

      setAppointments(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const [locationOnMap, setLocationOnMap] = useState(null);

  useEffect(() => {
    if (appointments != undefined && weeks != null) {
      const uniqueAppointmentLocationsByDate = weeks[defaultWeekIndx].map(
        (day) => {
          const date = day.date;
          const appointmentsForDate = appointments[date];

          const uniqueLocationIds = new Set();

          const uniqueLocations = new Set();

          const uniqueLocationMinSequence = new Map();
          const uniqueLocationMaxSequence = new Map();

          if (appointmentsForDate != undefined) {
            appointmentsForDate.forEach((appointment) => {
              if (!uniqueLocationIds.has(appointment.location.id)) {
                uniqueLocationIds.add(appointment.location.id);
                uniqueLocations.add(appointment.location);

                uniqueLocationMinSequence.set(
                  appointment.location.id,
                  appointment.sequenceNo
                );
                uniqueLocationMaxSequence.set(
                  appointment.location.id,
                  appointment.sequenceNo
                );
              } else {
                const minSequence = uniqueLocationMinSequence.get(
                  appointment.location.id
                );
                const maxSequence = uniqueLocationMaxSequence.get(
                  appointment.location.id
                );

                if (appointment.sequenceNo < minSequence) {
                  uniqueLocationMinSequence.set(
                    appointment.location.id,
                    appointment.sequenceNo
                  );
                }

                if (appointment.sequenceNo > maxSequence) {
                  uniqueLocationMaxSequence.set(
                    appointment.location.id,
                    appointment.sequenceNo
                  );
                }
              }
            });
          }

          const patientNameByLocation = [];

          if (appointmentsForDate != undefined) {
            appointmentsForDate.forEach((appointment) => {
              patientNameByLocation.push({
                locationId: appointment.location.id,
                patientId: appointment.patient.id,
                patientName: appointment.patient.name,
                sequenceNo: appointment.sequenceNo,
              });
            });
          }

          return {
            date,
            day:
              day.day === "SUN"
                ? "Sunday"
                : day.day === "MON"
                ? "Monday"
                : day.day === "TUE"
                ? "Tuesday"
                : day.day === "WED"
                ? "Wednesday"
                : day.day === "THU"
                ? "Thursday"
                : day.day === "FRI"
                ? "Friday"
                : "Saturday",
            locations: Array.from(uniqueLocations).map((location) => {
              const patients = patientNameByLocation
                .filter((x) => x.locationId === location.id)
                .map((x) => ({
                  id: x.patientId,
                  name: x.patientName,
                  sequenceNo: x.sequenceNo,
                }));

              return {
                ...location,
                minSequence: uniqueLocationMinSequence.get(location.id),
                maxSequence: uniqueLocationMaxSequence.get(location.id),
                patients,
              };
            }),
          };
        }
      );

      setLocationOnMap(uniqueAppointmentLocationsByDate);
    }
  }, [appointments]);

  const fetchFilterOptions = async () => {
    try {
      if (selectedProvider !== null) {
        const { data } = await filterAppointmentOptions({
          providerId: selectedProvider.id,
        });

        setFilterOptions(data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const user = useSelector(selectUser);

  const getProvidersFunction = async () => {
    try {
      const { data } = await getProviders(user.organisation.id).unwrap();

      setProviders(data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchProviderFilters = async (provider) => {
    const { data } = await fetchProviderFiltersAPI({
      providerId: provider.providerId || provider.id,
    }).unwrap();
    setAppointmentTypes(data.appointmentTypes);
    setCommunities(data.communities);
    setPatients(data.patientsList);
  };

  useEffect(() => {
    const newWeeks = getDatesInMonth(1, 2024);
    newWeeks.pop();
    setWeeks(newWeeks);
    getProvidersFunction();
    fetchAppointmentsFunction();
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    fetchFilterOptions();
  }, [selectedProvider]);

  useEffect(() => {
    if (providers.length && selectedProvider === null && user) {
      setSelectedProvider(
        user.type === 1
          ? providers[0]
          : providers.find((x) => x.id === user.details.provider_id)
      );
    }
  }, [providers, appointments]);

  useEffect(() => {
    if (weeks !== null && selectedProvider !== null) {
      setAppointments([]);
      fetchAppointmentsFunction();
    }
  }, [defaultWeekIndx, selectedProvider, weeks]);

  useEffect(() => {
    if (recallSchedule) {
      // setAppointments([]);
      fetchAppointmentsFunction();
      setRecallSchedule(false);
    }
  }, [recallSchedule]);

  useEffect(() => {
    if (selectedProvider !== null) {
      fetchProviderFilters(selectedProvider);
    }
  }, [selectedProvider]);

  useEffect(() => {
    if (appointments.length !== 0) {
      setLoaderVisible(false);
    }
  }, [appointments]);

  const [fetchAppointments, { isLoading: isAppointmentsLoading }] =
    useFetchAppointmentsMutation();
  const [getProviders, { isLoading: isProvidersLoading }] =
    useGetProvidersMutation();
  const [filterAppointmentOptions, { isLoading: isFiltersLoading }] =
    useFilterAppointmentOptionsMutation();
  const [fetchProviderFiltersAPI, { isLoading: isProviderFiltersLoading }] =
    useFetchProviderFiltersMutation();

  const [warningModalOpen, setWarningModalOpen] = useState(false);

  const loading =
    isLoading ||
    isAppointmentsLoading ||
    isProvidersLoading ||
    isProviderFiltersLoading ||
    isFiltersLoading;

  const closeWarningModal = () => {
    setWarningModalOpen(false);
  };

  const [openMap, setOpenMap] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);

  useEffect(() => {
    if (openMap) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [openMap]);

  return (
    <div className="relative z-10 h-screen overflow-x-hidden">
      {loading && <Loading loading={loading} />}
      <PublishSuccessCard
        successCard={successCard}
        setSuccessCard={setSuccessCard}
      />
      {/* <MenuHeader /> */}
      <div className="relative w-full">
        {weeks !== null && (
          <AppointmentOptions
            filterOpen={filterOpen}
            addOpen={addOpen}
            setFilterOpen={setFilterOpen}
            enableFilter={
              appointmentTypes.length !== 0 &&
              communities.length !== 0 &&
              patients.length !== 0
            }
            providers={providers}
            loaderVisible={loaderVisible}
            selectedProvider={selectedProvider}
            setAddOpen={setAddOpen}
            setSelectedProvider={setSelectedProvider}
            publishOpen={publishOpen}
            setPublishOpen={setPublishOpen}
            weeks={weeks[defaultWeekIndx]}
            weeksList={weeks?.map((week, idx) => {
              return {
                name: `${week[0].date} - ${week[week.length - 1].date}`,
                id: idx,
              };
            })}
            filtersOn={
              appointmentStatus !== "All" ||
              locationType !== "All" ||
              appointmentType !== "All" ||
              community !== "All" ||
              patient !== "All"
            }
            setOpenMap={setOpenMap}
          />
        )}
        <div className="flex flex-row h-full overflow-auto flex-nowrap">
          {weeks !== null &&
            weeks[defaultWeekIndx].map((x, indx) => (
              <DateColumn
                key={indx}
                id={indx}
                info={{
                  date: +x.date.split("-")[2],
                  day: x.day,
                  fulldate: x.date,
                  isAvailable:
                    selectedProvider?.workingDays.find(
                      (y) => y.substring(0, 3) === x.day
                    ) !== undefined,
                }}
                cards={
                  appointments[x.date] === undefined ? [] : appointments[x.date]
                }
                onDrop={onDrop}
                loaderVisible={loaderVisible}
                addOpen={addOpen}
                publishOpen={publishOpen}
                isToday={moment(new Date()).format("YYYY-MM-DD") === x.date}
              />
            ))}
        </div>
        {selectedAppointment && (
          <AppointmentSelectModal
            selectedProvider={selectedProvider}
            setRecallSchedule={setRecallSchedule}
          />
        )}
        {weeks !== null && filterOptions !== null && (
          <AppointmentAddModal
            selectedProvider={selectedProvider}
            addOpen={addOpen}
            setAddOpen={setAddOpen}
            weeks={weeks[defaultWeekIndx]}
            onAdd={onAdd}
            filterOptions={filterOptions}
            setRecallSchedule={setRecallSchedule}
          />
        )}
        {appointmentTypes.length !== 0 &&
          communities.length !== 0 &&
          patients.length !== 0 && (
            <AppointmentFilterModal
              filterOpen={filterOpen}
              setFilterOpen={setFilterOpen}
              appointmentStatus={appointmentStatus}
              setAppointmentStatus={setAppointmentStatus}
              locationType={locationType}
              setLocationType={setLocationType}
              appointmentTypes={appointmentTypes}
              communities={communities}
              patients={patients}
              appointmentType={appointmentType}
              setAppointmentType={setAppointmentType}
              community={community}
              setCommunity={setCommunity}
              patient={patient}
              setPatient={setPatient}
              setRecallSchedule={setRecallSchedule}
            />
          )}
      </div>
      <AvailibilityWarningModal
        open={warningModalOpen}
        closeWarningModal={closeWarningModal}
        onDrop={onDrop}
        moveData={moveData}
      />
      {weeks !== null && publishOpen && (
        <PublishAppointmentModal
          publishOpen={publishOpen}
          setPublishOpen={setPublishOpen}
          endDate={endDate}
          setEndDate={setEndDate}
          endDateOpen={endDateOpen}
          setEndDateOpen={setEndDateOpen}
          startDate={startDate}
          setStartDate={setStartDate}
          startDateOpen={startDateOpen}
          setStartDateOpen={setStartDateOpen}
          weeks={weeks[defaultWeekIndx]}
          setSuccessCard={setSuccessCard}
          selectedProvider={selectedProvider}
          user={user}
        />
      )}
      <ReusableModal open={openMap} hideHeader>
        <div className="flex flex-row items-start gap-4 p-4">
          <div className="border border-black rounded-lg overflow-clip">
            <MapV2
              locationOnMap={locationOnMap?.filter(
                (day) => selectedDay === null || day.day === selectedDay
              )}
              selectedProvider={selectedProvider}
            />
          </div>
          <div className="flex flex-col gap-2">
            {[
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ].map((day, idx) => (
              <button
                onClick={() => {
                  if (day === selectedDay) {
                    setSelectedDay(null);
                  } else {
                    setSelectedDay(day);
                  }
                }}
                className={classNames(
                  "flex flex-row items-center gap-2 p-2 font-semibold bg-opacity-10 rounded-lg",
                  selectedDay === day ? bgColorsMap[day] : "bg-none"
                )}
                key={idx}
              >
                <div
                  className={classNames(
                    "w-5 h-5 rounded-full",
                    bgColorsMap[day]
                  )}
                />
                <p className={classNames("m-0", textColorsMap[day])}>{day}</p>
              </button>
            ))}
          </div>
          <Tooltip title="Close">
            <button onClick={() => setOpenMap(false)} className="flex">
              <CloseSvg />
            </button>
          </Tooltip>
        </div>
      </ReusableModal>
    </div>
  );
};

export default LongitudinalSchedules;
