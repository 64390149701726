import { apiSlice } from "../../app/api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.mutation({
      query: () => ({
        url: "/user",
        method: "GET",
      }),
    }),
    getAllUser: builder.mutation({
      query: () => ({
        url: "/user/organisation",
        method: "GET",
      }),
    }),
    createProviderUser: builder.mutation({
      query: (credentials) => ({
        url: "/user/provider",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    createBackOfficeUser: builder.mutation({
      query: (credentials) => ({
        url: "/user/back-office",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    createStaffUser: builder.mutation({
      query: (credentials) => ({
        url: "/user/community-staff",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    updateStep: builder.mutation({
      query: (data) => ({
        url: "/user/step",
        method: "POST",
        body: data,
      }),
    }),
    updateTestData: builder.mutation({
      query: (data) => ({
        url: "/user/showTestData",
        method: "POST",
        body: data,
      }),
    }),
    supportRequest: builder.mutation({
      query: (data) => ({
        url: "/user/support",
        method: "POST",
        body: data,
      }),
    }),
    removeUser: builder.mutation({
      query: (credentials) => ({
        url: "/user",
        method: "DELETE",
        body: { ...credentials },
      }),
    }),
    updateUser: builder.mutation({
      query: (credentials) => ({
        url: "/user",
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
  }),
});

export const {
  useGetUserMutation,
  useGetAllUserMutation,
  useCreateProviderUserMutation,
  useCreateBackOfficeUserMutation,
  useCreateStaffUserMutation,
  useUpdateStepMutation,
  useSupportRequestMutation,
  useUpdateTestDataMutation,
  useRemoveUserMutation,
  useUpdateUserMutation,
} = userApiSlice;
