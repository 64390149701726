import React from "react";
import { makeStyles } from "@mui/styles";
import { colors, fonts } from "../../styles";

const useStyles = makeStyles({
  button: {
    // padding: 10,
    // margin: 10,
    borderRadius: 6,
    backgroundColor: colors.OFFICE_COLOR,
    alignSelf: "center",
    // maxWidth: 350,
    color: colors.WHITE_COLOR,
    fontSize: 24,
    fontFamily: fonts.MEDIUM_FONT,
    outline: "none",
    border: "none",
    "&:hover": {
      opacity: "0.9",
    },
    cursor: "pointer",
  },
});

const CustomButton = ({ children, className, style, ...rest }) => {
  const classes = useStyles();
  return (
    <button
      {...rest}
      className={classes.button + " " + className}
      style={{
        opacity: rest.disabled ? 0.6 : 1,
        cursor: rest.disabled ? "default" : "pointer",
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </button>
  );
};

export default CustomButton;
