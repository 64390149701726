import React, { useState } from "react";
import CloseSvg from "../../../../../assets/svgs/CloseSvg";
import classNames from "classnames";
import { Icons } from "../../../../../assets";
import CheckboxSelector from "../../../components/CheckboxSelector";
import { Tooltip } from "@mui/material";

const AppointmentTypeFilters = ({
  appointments,
  handleNameFilter,
  handleEhrFilter,
  nameFilter,
  ehrFilter,
}) => {
  const [ehrListOpen, setEhrListOpen] = useState(false);

  const handleEhrListOpen = () => {
    setEhrListOpen(!ehrListOpen);
  };

  return (
    <div className="flex flex-col md:flex-row w-full gap-8 h-fit">
      <div className="relative flex flex-row items-center gap-2 divide-x-2 rounded-md outline outline-gray-200 w-full md:w-80">
        <div className="pl-2">
          <Tooltip title="Search">
            <img
              src={Icons.Search_Icon}
              alt="search"
              className="w-5 h-5 cursor-pointer search-icon"
            />
          </Tooltip>
        </div>
        <input
          type="text"
          placeholder="Search by Name ..."
          className="w-full h-full p-2 text-base outline-none"
          value={nameFilter}
          onChange={(e) => handleNameFilter(e.target.value)}
        />
      </div>
      <div className="relative flex flex-row items-center gap-2 divide-x-2 rounded-md outline outline-gray-200 w-full md:w-80">
        <Tooltip title="Filter">
          <button onClick={handleEhrListOpen} className="pl-2">
            <img
              src={Icons.Sort_Icon}
              alt="search"
              className="w-5 h-5 cursor-pointer search-icon"
            />
          </button>
        </Tooltip>
        <div className="flex flex-row items-center w-full h-full gap-2 p-2 overflow-scroll flex-nowrap no-scrollbar">
          {ehrFilter.length === 0 && (
            <p className="m-0 text-base text-primarygrayborder whitespace-nowrap">
              Filter by EHR ...
            </p>
          )}
          {ehrFilter.map((name, index) => (
            <div
              key={index}
              className="w-fit flex flex-row gap-2 items-center px-2 py-[2px] m-0 text-base text-white rounded-full bg-primarygrayborder"
            >
              <p aria-multiline={false} className="m-0 whitespace-nowrap">
                {name}
              </p>
              <Tooltip title="Remove">
                <button
                  onClick={() => handleEhrFilter(name)}
                  className="flex items-center justify-center bg-white border border-white rounded-full aspect-square"
                >
                  <CloseSvg color={"#BDBDBD"} />
                </button>
              </Tooltip>
            </div>
          ))}
        </div>
        {ehrListOpen && (
          <div className="absolute flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-14">
            {Array.from(
              new Set(appointments.map((appointment) => appointment.ehrName))
            ).map((name, index) => (
              <div
                className={classNames(
                  "hover:bg-primarylightgrey",
                  index !== 7 && "border-b"
                )}
              >
                <CheckboxSelector
                  key={index}
                  header={name}
                  isChecked={ehrFilter.includes(name) ? true : false}
                  handleChange={(_e) => handleEhrFilter(name)}
                  isBold={false}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppointmentTypeFilters;
