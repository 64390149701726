import React, { useState } from "react";
import { colors } from "../../styles";
import { Dialog, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import "./AddRegionModal.css";
import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const UploadScreen = ({
  open: isOpen,
  handleClose,
  InputBox,
  file,
  fileType,
  selectedImage,
  message,
  onKeyDown,
  setMessage,
  users,
  hashTags,
  tagPatients,
  refList,
  onSend,
  onAddTag,
  disable,
}) => {
  const [open, setOpen] = useState(!!isOpen);

  const onClose = () => {
    setOpen(true);
    handleClose();
  };

  const format = file.name.split(".")[1];

  const other = ["doc", "docx", "xlsx", "csv"].includes(format);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <Grid>
        <Grid
          className="d-flex justify-content-between py-2 px-3 align-items-center"
          style={{
            backgroundColor: colors.OFFICE_COLOR,
          }}
        >
          <div className="d-flex align-items-center">
            <Typography className="region-modal-title mx-2">
              {"Upload Document"}
            </Typography>
          </div>
          <Tooltip title="Close">
            <IconButton onClick={onClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid className="px-4">
          {fileType === "image" ? (
            <img alt="pic" src={selectedImage} className="chat-image" />
          ) : null}

          {fileType === "doc" && !other ? (
            <iframe
              title="PDF"
              src={URL.createObjectURL(file)}
              style={{ width: "100%", height: 200, margin: "10px 0px" }}
            />
          ) : null}
          {fileType === "doc" && other ? (
            <Grid className="d-flex align-items-center my-2">
              <InsertDriveFileIcon
                style={{ width: 50, height: 50, color: "#272E35" }}
              />
              <Typography className="px-2">{file?.name}</Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid style={{ backgroundColor: "#272E35", padding: "10px" }}>
          <InputBox
            className="mx-0"
            message={message}
            onKeyDown={onKeyDown}
            setMessage={setMessage}
            users={users}
            hashTags={hashTags}
            tagPatients={tagPatients}
            refList={refList}
            onSend={onSend}
            onAddTag={onAddTag}
            disabled={!message?.trim() || disable}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default UploadScreen;
