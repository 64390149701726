import React from "react";
import Logo from "../../../assets/images/CareSMS_Logo.png";
import { PlayArrow } from "@mui/icons-material";
import { useSignUpMutation } from "../../../features/auth/authApiSlice";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../../features/auth/authSlice";

const AccountInfo = ({ step, handleStepChange, email }) => {
  const [errMsg, setErrMsg] = React.useState(null);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [signUp, { isLoading }] = useSignUpMutation();

  const isButtonDisabled = () => {
    return (
      !firstName ||
      !lastName ||
      !phone ||
      !password ||
      !confirmPassword ||
      isLoading
    );
  };

  const dispatch = useDispatch();

  const handleAccountCreation = async () => {
    if (password !== confirmPassword) {
      setErrMsg("Passwords don't match!");
      return;
    }
    try {
      const { data } = await signUp({
        userData: {
          type: 1,
          email,
          password,
          phone,
        },
        staffData: {
          organisation_id: null,
          firstname: firstName,
          lastname: lastName,
          role: 4,
        },
      }).unwrap();

      dispatch(
        setCredentials({
          token: data.accessToken,
          user: data.user,
        })
      );

      handleStepChange(step + 1);
    } catch (error) {
      if (!error.status) setErrMsg("No response from server");
      else if (error.status === 400 || error.status === 404)
        setErrMsg(error.data.message);
      else setErrMsg("An error occurred. Please try again.");
    }
  };
  const formatPhoneNumber = (input) => {
    const cleaned = ("" + input).replace(/\D/g, "");

    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    return match
      ? [match[1], match[2], match[3]].filter(Boolean).join("-")
      : "";
  };

  return (
    <div className="relative flex flex-col w-full gap-8 mt-8 sm:w-11/12 base:mt-16">
      <div className="flex flex-col gap-8">
        <img src={Logo} alt="logo" className="-ml-2 logo" />
        <h1 className="m-0 text-2xl font-bold">Tell us about yourself</h1>
      </div>
      <div className="flex flex-col w-80 gap-6">
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            First name
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="text"
            name="text"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            placeholder="John"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Last name
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="text"
            name="text"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            placeholder="Doe"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Phone
            <span className="text-red-500">*</span>
          </label>
          <input
            type="tel"
            id="tel"
            name="tel"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            placeholder="xxx-xxx-xxxx"
            value={phone}
            onChange={(e) => {
              if (e.target.value.length >= 13) {
                return;
              }
              setPhone(formatPhoneNumber(e.target.value));
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Email address
            <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            value={email}
            disabled
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Password
            <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            id="text"
            name="text"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Confirm Password
            <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            id="text"
            name="text"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            placeholder="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        {errMsg && <p className="m-0 text-xs text-danger">{errMsg}</p>}
        <button
          disabled={isButtonDisabled()}
          onClick={handleAccountCreation}
          className="flex items-center justify-center w-full gap-2 p-2 mt-8 font-semibold text-white rounded-lg bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
        >
          Next
          <div className="relative flex flex-row">
            <PlayArrow
              style={{
                color: "#fff",
                width: "18px",
                height: "18px",
                objectFit: "contain",
              }}
            />
            <PlayArrow
              style={{
                color: "#fff",
                width: "18px",
                height: "18px",
                objectFit: "contain",
                position: "absolute",
                left: -5,
              }}
            />
          </div>
        </button>
        <button
          disabled={isLoading}
          onClick={() => handleStepChange(step - 1)}
          className="flex items-center justify-center w-full gap-2 p-2 mt-8 font-semibold text-black bg-white rounded-lg shadow-sm"
        >
          Go back to previous step
        </button>
      </div>
      <p className="w-full mt-12 text-xs text-center">
        2024 CareSMS Inc. All rights reserved.
      </p>
    </div>
  );
};

export default AccountInfo;
