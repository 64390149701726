import { Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LeftArrowSvg from "../../assets/svgs/LeftArrowSvg";
import ReferencedPatientCard from "../../components/Cards/ReferencedPatientCard";
import { calculateAge } from "../../utilities/resuseFunctions";
import MessageCard from "../../components/Cards/MessageCard";
import { useFetchTaggedPatientsMessagesMutation } from "../../features/chat/chatApiSlice";

const ReferencedPatients = ({ onReferenceClick, referenced, chat }) => {
  const [specific, setSpecific] = useState(null);
  const [detailed, setDetailed] = useState(null);

  const onSpecificBack = () => {
    setSpecific(!specific);
    setDetailed(!specific);
  };

  const [fetchTaggedPatients, { isLoading }] =
    useFetchTaggedPatientsMessagesMutation();

  useEffect(() => {
    if (detailed !== null) {
      fetchTaggedPatients({
        id: chat.conversationId,
        patientId: detailed.patientId,
      }).then(({ data }) => {
        setSpecific(data.data);
      });
    }
  }, [detailed]);

  return (
    <Grid
      sx={{
        width: {
          xs: "300px",
          sm: "300px",
          md: "350px",
        },
        height: "100vh",
        backgroundColor: "#fff",
      }}
      className="py-3 overflow-hidden"
    >
      {specific ? (
        <>
          <div className={`d-flex align-items-center px-2`}>
            <div role="button" className="close-box " onClick={onSpecificBack}>
              <Tooltip title="Close">
                <LeftArrowSvg className="close-icon-1" />
              </Tooltip>
            </div>
            <Typography className="px-3 add-patients-modal-title group-header d-flex items-center">
              {detailed?.patientName}{" "}
              <Grid className="unread mx-2">{specific?.length || 0}</Grid>
            </Typography>
          </div>
          <Grid className="my-2 px-2 group-content">
            {specific?.map((dataX, ind) => (
              <MessageCard
                key={ind}
                message={{ ...dataX, conversationId: chat.conversationId }}
                data={chat}
                starred={true}
                tagPatients={[]}
              />
            ))}
          </Grid>
        </>
      ) : (
        <>
          <div className={`d-flex align-items-center px-3`}>
            <div
              role="button"
              className="close-box "
              onClick={onReferenceClick}
            >
              {/* <LeftIcon className="close-icon-1" /> */}
              <Tooltip title="Close">
                <LeftArrowSvg className="close-icon-1" />
              </Tooltip>
            </div>
            <Typography className="px-3 add-patients-modal-title group-header">
              Filter by Patients
            </Typography>
          </div>
          <Grid className="my-2 px-2 group-content">
            {(referenced || [])?.map((item, idx) => {
              const patientName = item.name;
              const patientId = parseInt(item.id, 10);
              // const messages = item.items.map((data) => data.Message);
              const messages = [];
              return (
                <ReferencedPatientCard
                  key={idx}
                  patientName={patientName}
                  patientID={patientId}
                  messages={messages}
                  count={item.count}
                  onClick={() =>
                    setDetailed({
                      patientId,
                      patientName,
                    })
                  }
                />
              );
            })}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ReferencedPatients;
