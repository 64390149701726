import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Steps from "../components/Steps";
import Content from "../components/Content";
import StepButtons from "../components/StepButtons";
import ProvidersScreen from "../../Schedules/Configure/ProvidersConfig/ProvidersScreen";
import { useSchedulerStore } from "../../../context/scheduler.store";
import { useGetProvidersMutation } from "../../../features/organisation/organisationApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, updateUser } from "../../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useUpdateStepMutation } from "../../../features/user/userApiSlice";

const Scheduler = ({ handleStepChange, step }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [opaque, setOpaque] = useState(false);
  const user = useSelector(selectUser);
  const providers = useSchedulerStore((state) => state.providers);
  const setProviders = useSchedulerStore((state) => state.setProviders);
  const [updateStep] = useUpdateStepMutation();

  const [getProviders] = useGetProvidersMutation();

  const fetchProviders = async () => {
    try {
      const { data } = await getProviders(user.organisation.id).unwrap();

      const updatedProviders = data.map((provider) => {
        return {
          ...provider,
          isSelected: !provider.isSelected,
        };
      });

      setProviders(updatedProviders);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const onNext = async () => {
    const { data } = await updateStep({
      userId: user?.id,
      step: 8,
      hasCompletedOnboarding: true,
    }).unwrap();
    dispatch(
      updateUser({
        user: { ...user, step: 8, completedOnboarding: true },
      })
    );
    navigate("/schedules/run-scheduler");
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  return (
    <div className="relative flex flex-col h-screen px-6 pt-8">
      <Header setOpaque={setOpaque} />
      <Steps step={5} total={6} />
      <Content
        header={"Run the CareSMS Intelligent Scheduler"}
        content={`You’re a click away from instantly building optimized visit schedules for each of your Providers! Please review the scheduling settings below, decide each Provider’s scheduling horizon, and press ‘Run Scheduler’ to watch the magic happen.`}
        note={`You will have the ability to re-run the CareSMS Intelligent Scheduler at any time to create new daily visit schedules. Once you complete this step, we will send you to your full CareSMS experience to manage these schedules, review interactive mapping and reporting, and more.`}
      />
      <div className="flex flex-col m-4 grow">
        <ProvidersScreen
          setOpaque={setOpaque}
          providers={providers}
          setProviders={setProviders}
          isGlobalShow={true}
          isRunScheduler={true}
          isWelComePage={true}
        />
      </div>
      <StepButtons
        primaryText={"Next"}
        secondaryText={"Go back to previous step"}
        primaryClick={onNext}
        secondaryClick={() => handleStepChange(step - 1)}
      />
      <Footer />
    </div>
  );
};

export default Scheduler;
