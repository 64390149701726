import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Icons } from "../../assets";
import "./VisitCancelModal.css";
import CustomButton from "../Buttons/CustomButton";
import { colors } from "../../styles";

const DeleteModal = ({
  open: isOpen,
  handleClose,
  handleDelete,
  title,
  subtext,
  primaryText,
  secondaryText,
}) => {
  const [open, setOpen] = useState(isOpen);

  const onClose = () => {
    setOpen(false);
    handleClose();
  };
  const onDelete = () => {
    onClose();
    handleDelete();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <Grid
        className="d-flex justify-content-between py-2 px-3 align-items-center"
        style={{
          backgroundColor: colors.OFFICE_COLOR,
        }}
      >
        {/* <Grid className="d-flex justify-content-between align-items-center mx-3 mt-3"> */}
        <Grid className="d-flex align-items-center">
          {/* <img src={Icons.Cancel_Icon} className="icon" alt="cancel icon" /> */}
          <Typography className="cancel-title px-2 text-white">
            {title}
          </Typography>
        </Grid>
        <Tooltip title="Close">
          <IconButton onClick={handleClose}>
            <CloseIcon className="text-white" />
          </IconButton>
        </Tooltip>
        {/* </Grid> */}
      </Grid>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="cancel-subtext"
        >
          {subtext}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogActions>
          <CustomButton className={"button-dont"} onClick={onClose}>
            {secondaryText || "Cancel"}
          </CustomButton>
          <CustomButton className={"button-cancel"} onClick={onDelete}>
            {primaryText || "Delete"}
          </CustomButton>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
