import { Placeholder_Image } from '../../../../../assets/images'
import React from 'react';

const ImageComponent = ({ src, properties, }) => {
    const placeholderImage = Placeholder_Image;
    const imageSizingStyle = () => {
        if (!properties?.imageSizing) {
            return { width: '100%', height: '100%', };
        }

        switch (properties.imageSizing) {
            case 'fit':
                return { width: '100%', height: '100%', objectFit: 'fill' };
            case 'auto-size':
                return { width: 'auto', height: 'auto' };
            case 'fit-proportional':
                return { width: '100%', height: '100%', objectFit: 'contain' };
            case 'clip':
                return { width: '100%', height: '100%', objectFit: 'cover' };
            default:
                return { width: '100%', height: '100%' };
        }
    };
    const imageRotationStyle = () => {
        return {
            transform: `rotate(${properties?.rotation || 0}deg)`,
            transition: 'transform 0.3s ease',
        };
    };
    return (

        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                position: 'relative',
                border: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                backgroundColor: `${properties?.backgroundColor}`,
            }}
        >
            <span
                style={{
                    paddingBottom: "3px",
                    boxSizing: 'border-box',
                    color: `${properties?.textColor}`,
                    fontSize: `${properties?.fontSize + "px"}`,
                    fontWeight: `${properties?.fontWeight} `,
                    fontFamily: `${properties?.fontFamily}`,
                    textAlign: `${properties?.align}`,
                }}
            >
                {properties?.name}
            </span>
            <div
                style={{
                    flex: '1',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img
                    src={src ? `data:image/jpeg;base64,${src}` : placeholderImage}
                    alt="Selected"
                    style={{
                        ...imageSizingStyle(),
                        ...imageRotationStyle(),
                        boxSizing: 'border-box',
                        borderRadius: `${properties?.borderRadius}%`,
                    }}
                />
            </div>
        </div>
    );
};

export default ImageComponent;