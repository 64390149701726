import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { Tab, Tabs } from "@mui/material";
import { useFilterTableDataMutation, useUpdateTemplatesMutation } from "../../../../../features/report/reportApiSlice";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddIcon from "@mui/icons-material/Add";
import CheckboxSelector from "../../../../Schedules/components/CheckboxSelector";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Delete_Icon } from "../../../../../assets/images";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { HTML5Backend } from 'react-dnd-html5-backend';
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { Icons } from "../../../../../assets";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";
import RedoIcon from "@mui/icons-material/Redo";
import RemoveIcon from "@mui/icons-material/Remove";
import ReusableModal from "../../../../../components/Modals/ReusableModal";
import ReusableReportModal from "../ReusableReportModal";
import SearchIcon from '@mui/icons-material/Search';
import { SketchPicker } from "react-color";
import Tooltip from "@mui/material/Tooltip";
import UndoIcon from "@mui/icons-material/Undo";
import classNames from "classnames";
import moment from "moment";

const ItemTypes = {
  COLUMN: 'column',
};

const Column = ({ item, removeColumn, showCloseIcon }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.COLUMN,
    item: { column: item },  // Passing the column name only
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className={`relative pl-1 pr-1 text-[#374151] bg-white border-custome rounded-md flex items-center justify-between mb-2 mr-2 ${isDragging ? 'opacity-50' : ''
        }`}
      style={{ minWidth: '50px', fontSize: " 0.75rem", borderRadius: "5px" }}
    >
      {item}
      {/* <button className="ml-2 text-gray-400 hover:text-red-600" onClick={() => removeColumn(item)}>
        <CloseIcon className="text-sm text-gray-400" />
      </button> */}
      {showCloseIcon && (
        <button className="ml-2 text-gray-400 hover:text-red-600" onClick={() => removeColumn(item)}>
          <CloseIcon className="text-sm text-gray-400" />
        </button>
      )}
    </div>
  );
};

const DropZone = ({ accept, onDrop, children, title }) => {
  const [{ isOver }, drop] = useDrop({
    accept,
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <>
      <p className="prop-label m-0">{title}</p>
      <div
        ref={drop}
        className={`flex flex-wrap overflow-auto custom-scrollbar min-h-10 max-h-20 border-custome rounded w-full pt-2 pl-2 ${isOver ? 'bg-blue-100' : ''
          }`}
      >
        {children}
      </div>
    </>
  );
};

const SidebarSection = ({ title, children, subSection, isOpen, onToggle }) => {
  return (
    <div className="sidebar-section">
      <div
        className={`sidebar-section-header px-2 cursor-pointer  ${subSection ? "" : "py-1"
          }`}
        onClick={onToggle}
        style={{
          backgroundColor: !subSection ? "rgb(16 85 151)" : "#534a4a40",
        }}
      >
        <div className="d-flex justify-between items-center">
          <span
            className={`font-semibold text-sm ${subSection ? "text-sm text-gray-600" : "text-base text-gray-500"
              } `}
            style={{
              color: subSection ? "text-sm text-gray-600" : "white",
              paddingLeft: "8px",
            }}
          >
            {title}
          </span>
          <button className="toggle-button">
            {isOpen ? (
              <Tooltip title="close">
                <RemoveIcon
                  className={`${subSection ? "text-sm text-gray-600" : "text-white"
                    }`}
                />
              </Tooltip>
            ) : (
              <Tooltip title="open">
                <AddIcon
                  className={`${subSection ? "text-sm text-gray-600" : "text-white"
                    }`}
                />

              </Tooltip>
            )}
          </button>
        </div>
      </div>
      {isOpen && <div className="sidebar-section-content">{children}</div>}
    </div>
  );
};

function TableProperties({
  component,
  onPropertyChange,
  setSelectedComponent,
  showAlert,
  flag,
  setFlag,
  reportAreaRef,
  setIsSaveClicked,
  isSaveClicked,
  selectedTemplate,
  setIsCreateNewTemplate,
  dashboardId,
  isNewReportActive,
  setCurrentReportData,
  getUserTemplates,
  openItem,
  openSection,
  setOpenItem,
  setOpenSection,
}) {
  const [name, setName] = React.useState("");
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
  const [colorPickerBg, setColorPickerBg] = React.useState(false);
  const [colorPickerText, setColorPickerText] = React.useState(false);
  const [borderStyle, setBorderStyle] = React.useState("solid");
  const [isAddColumn, setIsAddColumn] = React.useState(false);
  const [newCol, setNewCol] = React.useState("");
  const [columnListOpen, setColumnListOpen] = React.useState(false);
  const [selectedColumn, setSelectedColumn] = React.useState(
    component?.properties?.columns
  );
  const columnRef = React.useRef(null);
  const [deselectedColumns, setDeselectedColumns] = React.useState([]);
  const [updateTemplates] = useUpdateTemplatesMutation();
  const [filterData] = useFilterTableDataMutation();
  const [open, setOpen] = React.useState(false);
  const backgroundColorRef = React.useRef(null);
  const textColorRef = React.useRef(null);
  const borderColorRef = React.useRef(null);
  const colorPickerRefs = React.useRef({});
  const columnDropdownRef = React.useRef(null);
  const [tempRow, setTempRow] = React.useState(null);
  const [tmpColumnName, setTmpColumnName] = React.useState("");

  const [newColType, setNewColType] = React.useState("VARCHAR");
  const [colorPickerState, setColorPickerState] = React.useState({});
  const [isAddRowOpen, setIsAddRowOpen] = React.useState(false);
  const [IsAddNewColumnOpen, setIsAddNewColumnOpen] = React.useState(false);
  const [computedColAListOpen, setComputedColAListOpen] = React.useState(false);
  const [computedColBListOpen, setComputedColBListOpen] = React.useState(false);
  const [operationListOpen, setOperationListOpen] = React.useState(false);
  const [isError, setIserror] = React.useState(false);
  const colADropdownRef = React.useRef(null);
  const colBDropdownRef = React.useRef(null);

  const [selectedOperation, setSelectedOperation] = React.useState(null);
  const [selectedColumns, setSelectedColumns] = React.useState([]);
  const [inputExpression, setInputExpression] = React.useState("");
  const [computedColumns, setComputedColumns] = React.useState({});
  const [computedColListOpen, setComputedColListOpen] = React.useState(false);
  const [expressionHistory, setExpressionHistory] = React.useState([]);
  const [redoHistory, setRedoHistory] = React.useState([]);
  const inputRef = React.useRef(null);
  const [newComputedColumn, setNewComputedColumn] = React.useState({
    status: null,
  });
  const [isEditing, setIsEditing] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [originalColumnName, setOriginalColumnName] = React.useState("");
  const [isColumnEdited, setIsColumnEdited] = React.useState(false);
  const columns = component?.properties?.columns?.filter((item) => !component?.properties?.rowFields?.includes(item) && !component?.properties?.colFields?.includes(item))
  const [selectedCols, setSelectedCols] = React.useState(columns);
  const [rowFields, setRowFields] = React.useState([]);
  const [colFields, setColFields] = React.useState([]);
  const [pivotData, setPivotData] = React.useState([]);
  const [rangeValue, setRangeValue] = React.useState(0);
  const [columnToFilter, setColumnToFilter] = React.useState({ name: "", type: "" });
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const backgroundColor = `linear-gradient(to right, #0a3a67 ${rangeValue}%, #ddd ${rangeValue}%)`;
  const [filterObj, setFilterObj] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [selectAll, setSelectAll] = React.useState(false);
  const [filteredItems, setFilteredItems] = React.useState(columnToFilter?.colData || []);
  const dropdownRef = React.useRef(null);
  const dropdownRefs = React.useRef({});
  const [dropdowns, setDropdowns] = React.useState([{ id: 1, columnToFilter: '' }]);
  const selectedValues = component?.properties?.drilledColumns?.map((dropdown) => dropdown.columnToFilter);
  const [openDropdowns, setOpenDropdowns] = React.useState({});
  const MAX_COLUMNS = component?.properties?.columns?.length;
  const [columnCount, setColumnCount] = React.useState(1);

  const stringTypeConditions = [
    { value: "CONTAINS", label: "Contains" },
    { value: "BEGIN_WITH", label: "Begin with" },
    { value: "MATCHES", label: "Matches" },
    { value: "ENDS_WITH", label: "Ends with" },
    { value: "DOES_NOT_CONTAINS", label: 'Does not contain' },
    { value: "DOES_NOT_BEGIN_WITH", label: "Does not begin with" },
    { value: "DOES_NOT_MATCHES", label: "Does not match" },
    { value: "DOES_NOT_ENDS_WITH", label: "Does not end with" }
  ]
  const numericTypeConditions = [
    { value: "EQUALS_TO", label: "Equals to" },
    { value: "GREATER_THAN", label: "Greater than" },
    { value: "LESS_THAN", label: "Less than" },
    { value: "GREATER_THAN_EQUAL", label: "Greater than equal" },
    { value: "LESS_THAN_EQUAL", label: "Less than equal" },
    { value: "DOES_NOT_EQUAL", label: "Does not equal" }
  ]
  const handleToggleDropdown = (event) => {
    event.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleAddNewColumn = async () => {
    if (component?.properties?.columns.includes(newCol)) {
      showAlert(`${newCol} already exists`, "error");
      setNewCol("");
      return;
    } else {
      if (dashboardId !== null || isNewReportActive) {
        const newColumn = component?.properties?.columns.concat([`${newCol}`]);
        const newData = component?.properties?.data.map((row) => ({
          ...row,
          [newCol]: "",
        }));
        const newColumnProperties =
          component?.properties?.columnProperties.concat([
            {
              id: newColumn.length - 1,
              name: newCol,
              align: "center",
              borderType: component?.properties?.borderType,
              borderColor: component?.properties?.borderColor,
              borderWidth: component?.properties?.borderWidth,
              isIncoming: false,
              type: newColType,
              isComputedColumn: false,
              computedColumns: {},
            },
          ]);
        onPropertyChange({
          columns: newColumn,
          data: newData,
          columnProperties: newColumnProperties,
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            columns: newColumn,
            data: newData,
            columnProperties: newColumnProperties,
          },
        }));
        handleColumnNameFilter(newCol, newData, newColumnProperties);
        setFlag(false);
        setNewCol("");
      } else {
        setOpen(true);
      }
    }
  };
  const handleItemToggle = (itemName) => {
    setOpenItem((prevOpenItem) =>
      prevOpenItem === itemName ? null : itemName
    );
  };
  const handleHeaderChange = async (columnName, newName) => {
    //handleColumnPropertyChange(columnName, { name: newName });
    const updatedColumns = component?.properties?.columns.map((item) =>
      item === columnName ? newName : item
    );
    const updatedData = component?.properties?.data.map((row) => {
      const newRow = { ...row };
      newRow[newName] = row[columnName];
      delete newRow[columnName];
      return newRow;
    });
    const updatedProperties = { name: newName };
    setSelectedComponent((prev) => {
      const updatedColumnProperties =
        component?.properties?.columnProperties.map((col) => {
          if (col.isComputedColumn) {
            // Check if this computed column uses the old column name
            const updatedComputedColumns = { ...col.computedColumns };
            if (updatedComputedColumns.colA === columnName) {
              updatedComputedColumns.colA = newName;
            }
            if (updatedComputedColumns.colB === columnName) {
              updatedComputedColumns.colB = newName;
            }
            return {
              ...col,
              name: col.name.replace(columnName, newName), // Update the name
              computedColumns: updatedComputedColumns,
            };
          }
          return {
            ...col,
            name: col.name === columnName ? newName : col.name,
          };
        });
      return {
        ...prev,
        properties: {
          ...prev.properties,
          columns: updatedColumns,
          data: updatedData,
          columnProperties: updatedColumnProperties,
        },
      };
    });
    const updatedColumnProperties = component?.properties?.columnProperties.map(
      (col) => {
        if (col.isComputedColumn) {
          // Check if this computed column uses the old column name
          const updatedComputedColumns = { ...col.computedColumns };
          if (updatedComputedColumns.colA === columnName) {
            updatedComputedColumns.colA = newName;
          }
          if (updatedComputedColumns.colB === columnName) {
            updatedComputedColumns.colB = newName;
          }
          return {
            ...col,
            name: col.name.replace(columnName, newName), // Update the name
            computedColumns: updatedComputedColumns,
          };
        }
        return {
          ...col,
          name: col.name === columnName ? newName : col.name,
        };
      }
    );
    onPropertyChange({
      data: updatedData,
      columns: updatedColumns,
      columnProperties: updatedColumnProperties,
    });
    setSelectedColumn(
      selectedColumn.map((item) => (item === columnName ? newName : item))
    );
    setDeselectedColumns(
      deselectedColumns.map((item) => (item === columnName ? newName : item))
    );
    setFlag(false);
  };
  const handleColumnPropertyChange = (columnName, updatedProperties) => {
    setSelectedComponent((prev) => {
      const updatedColumnProperties = prev.properties.columnProperties.map(
        (col) =>
          col.name === columnName ? { ...col, ...updatedProperties } : col
      );
      return {
        ...prev,
        properties: {
          ...prev.properties,
          columnProperties: updatedColumnProperties,
        },
      };
    });
    const updatedColumnProperties = component.properties.columnProperties.map(
      (col) =>
        col.name === columnName ? { ...col, ...updatedProperties } : col
    );
    onPropertyChange({ columnProperties: updatedColumnProperties });
    setFlag(false);
  };
  const handleColorPickerToggle = (columnName, pickerType) => {
    setColorPickerState((prev) => ({
      ...prev,
      [columnName]: {
        ...prev[columnName],
        [pickerType]: !prev[columnName]?.[pickerType],
      },
    }));
  };
  const InputHistory = (expression) => {
    const combinedPattern = /(`[^`]+`)|(\d+(\.\d+)?)|([+\-*/()])|([^\s]+)/g;
    let parts = [];
    let match;
    while ((match = combinedPattern.exec(expression)) !== null) {
      if (match[1]) {
        parts.push({ type: "column", value: match[1] });
      } else if (match[2]) {
        parts.push({ type: "number", value: match[2] });
      } else if (match[4]) {
        parts.push({ type: "operation", value: match[4] });
      } else if (match[5]) {
        console.warn(`Unexpected text part: "${match[5]}"`);
      }
    }
    let tempHistory = [];
    parts.forEach((part) => {
      if (part.type === "number") {
        for (const digit of part.value) {
          if (digit === ".") {
            tempHistory.push({ type: "number", value: digit });
          } else {
            tempHistory.push({ type: "number", value: parseFloat(digit) });
          }
        }
      } else if (part.type === "column") {
        tempHistory.push(part);
      } else if (part.type === "operation") {
        tempHistory.push(part);
      }
    });
    setExpressionHistory(tempHistory);
  };
  const handleEdit = (expression, name) => {
    setInputExpression(expression);
    setIsAddNewColumnOpen(true);
    setActiveTab(1);
    setOriginalColumnName(name);
    setIsColumnEdited(true);
    InputHistory(expression);
    // setNewComputedColumn({ colName: name })
    //setExpressionHistory({ type: 'number', value: `\`${expression}\`` })
  };
  const handleRangeChange = (event) => {
    const value = event.target.value;
    setRangeValue(value);
    // Update state for the range value
    setFilterObj({ ...filterObj, max: Number(value) })
  };
  const handleDropToSelected = (item) => {
    if (!selectedCols.includes(item.column)) {
      setSelectedCols((prev) => [...prev, item.column]);
      const newRows = component?.properties?.rowFields?.filter((col) => col !== item?.column)
      const newCols = component?.properties?.colFields?.filter((col) => col !== item?.column)
      onPropertyChange({ rowFields: newRows, colFields: newCols });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          rowFields: newRows,
          colFields: newCols
        },
      }));
      setFlag(false);
    }
  };
  const handleDropToRow = (item) => {
    if (!component?.properties?.rowFields.includes(item.column)) {
      const updatedColFields = component?.properties?.colFields?.filter(col => col !== item.column);
      const newRowFields = [...component.properties.rowFields, item.column];
      setSelectedCols((prev) => prev.filter((col) => col !== item.column));
      onPropertyChange({ rowFields: newRowFields, colFields: updatedColFields });

      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          rowFields: newRowFields,
          colFields: updatedColFields,
        },
      }));
      setFlag(false);
    }
  };
  const handleDropToCol = (item) => {
    if (!component?.properties?.colFields.includes(item.column)) {
      // Remove the column from rowFields if it exists there
      const updatedRowFields = component?.properties?.rowFields?.filter(row => row !== item.column);

      // Add the column to colFields
      const newColFields = [...component.properties.colFields, item.column];
      setSelectedCols((prev) => prev.filter((col) => col !== item.column));
      // Update the component properties
      onPropertyChange({ rowFields: updatedRowFields, colFields: newColFields });

      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          rowFields: updatedRowFields,
          colFields: newColFields,
        },
      }));
      setFlag(false);
    }
  };
  const removeSelectedColumn = (column) => {
    setSelectedCols((prev) => prev.filter((col) => col !== column));
  };
  const removeRowField = (column) => {
    const updatedRowFields = component?.properties?.rowFields?.filter(row => row !== column);
    setRowFields((prev) => prev.filter((col) => col !== column));
    setSelectedCols([...selectedCols, column])
    onPropertyChange({ rowFields: updatedRowFields, });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        rowFields: updatedRowFields,
      },
    }));
    setFlag(false);
  };
  const removeColField = (column) => {
    const updatedColFields = component?.properties?.colFields?.filter(col => col !== column);
    setSelectedCols([...selectedCols, column])
    setColFields((prev) => prev.filter((col) => col !== column));
    onPropertyChange({ colFields: updatedColFields });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        colFields: updatedColFields,
      },
    }));
    setFlag(false);
  };
  const generatePivotTable = () => {
    const groupedData = {};
    component?.properties?.data.forEach((item) => {
      const rowKey = component?.properties?.rowFields.map((field) => item[field]).join('|');
      const colKey = component?.properties?.colFields.map((field) => item[field]).join('|');
      if (!groupedData[rowKey]) {
        groupedData[rowKey] = {};
      }
      if (!groupedData[rowKey][colKey]) {
        groupedData[rowKey][colKey] = [];
      }
      groupedData[rowKey][colKey].push(item);
    });
    const aggregatedData = {};
    Object.keys(groupedData).forEach((rowKey) => {
      aggregatedData[rowKey] = {};
      Object.keys(groupedData[rowKey]).forEach((colKey) => {
        const group = groupedData[rowKey][colKey];
        switch (component?.properties?.aggType) {
          case 'Count':
            aggregatedData[rowKey][colKey] = group.length;
            break;
          case 'Sum':
            aggregatedData[rowKey][colKey] = group.reduce((sum, item) => sum + parseFloat(item['Billing Rate'] || 0), 0);
            break;
          default:
            aggregatedData[rowKey][colKey] = group.length;
            break;
        }
      });
    });
    onPropertyChange({
      pivotData: aggregatedData,
      isPivot: true
    });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        pivotData: aggregatedData,
        isPivot: true
      },
    }));
    setPivotData(aggregatedData);
  };

  React.useEffect(() => {
    generatePivotTable();
  }, [component?.properties?.rowFields, component?.properties?.colFields]);

  const Fonts = [
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Times",
    "Courier New",
    "Courier",
    "Verdana",
    "Georgia",
    "Palatino",
    "Garamond",
    "Bookman",
    "Trebuchet MS",
    "Comic Sans MS",
    "Impac",
  ];
  const fontWeights = [
    { name: "Thin", value: 100 },
    { name: "Extra Light", value: 200 },
    { name: "Light", value: 300 },
    { name: "Normal", value: 400 },
    { name: "Medium", value: 500 },
    { name: "Semi Bold", value: 600 },
    { name: "Bold", value: 700 },
    { name: "Extra Bold", value: 800 },
  ];
  const operations = [
    { value: 1, name: "OpenBracket", icon: "(" },
    { value: 2, name: "CloseBracket", icon: ")" },
    { value: 3, name: "Addition", icon: "+" },
    { value: 4, name: "Subtraction", icon: "-" },
    { value: 5, name: "Multiplication", icon: "*" },
    { value: 6, name: "Division", icon: "/" },
  ];
  const handleColumnNameFilter = (column, newData, newColumnProperties) => {
    if (selectedColumn.includes(column)) {
      setDeselectedColumns((prev) => [...prev, column]);
      setSelectedCols(selectedCols?.filter((item) => item !== column))
      const newColumns = selectedColumn?.filter((filter) => filter !== column);
      if (newData && newData?.length > 0) {
        onPropertyChange({
          columns: newColumns,
          data: newData,
          columnProperties: newColumnProperties,
          rowFields: component?.properties?.rowFields?.filter(item => item !== column),
          colFields: component?.properties?.colFields?.filter(item => item !== column)
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            columns: newColumns,
            data: newData,
            columnProperties: newColumnProperties,
            rowFields: component?.properties?.rowFields?.filter(item => item !== column),
            colFields: component?.properties?.colFields?.filter(item => item !== column)
          },
        }));
        setCurrentReportData({
          data: newData,
          columns: newColumns,
          isNewReport: false,
          templateId: component?.properties?.templateId,
          isColumnChanged: true,
        });
      } else {
        onPropertyChange({
          columns: newColumns, rowFields: component?.properties?.rowFields?.filter(item => item !== column),
          colFields: component?.properties?.colFields?.filter(item => item !== column)
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            columns: newColumns,
            rowFields: component?.properties?.rowFields?.filter(item => item !== column),
            colFields: component?.properties?.colFields?.filter(item => item !== column)
          },
        }));
      }
      setSelectedColumn(selectedColumn?.filter((filter) => filter !== column));
      setCurrentReportData({
        columns: newColumns,
        data: component?.properties?.data,
        isNewReport: false,
        templateId: component?.properties?.templateId,
        isColumnChanged: true,
      });
      setFlag(false);
    } else {
      setDeselectedColumns((prev) => prev?.filter((item) => item !== column));
      setSelectedCols([...selectedCols, column]);
      if (newData && newData?.length > 0) {
        onPropertyChange({
          columns: [...selectedColumn, column],
          data: newData,
          columnProperties: newColumnProperties,
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            columns: [...selectedColumn, column],
            data: newData,
            columnProperties: newColumnProperties,
          },
        }));
        setCurrentReportData({
          columns: [...selectedColumn, column],
          data: component?.properties?.data,
          isNewReport: false,
          templateId: component?.properties?.templateId,
          isColumnChanged: true,
        });
      } else {
        const columnsToStore = [...selectedColumn, column];
        const columnExists = component?.properties?.columnProperties.some(
          (col) => col.name === column
        );

        if (!columnExists) {
          const newColumnProperties =
            component?.properties?.columnProperties.concat([
              {
                id: columnsToStore.length - 1,
                name: column,
                align: "center",
                borderType: component?.properties?.borderType,
                borderColor: component?.properties?.borderColor,
                borderWidth: component?.properties?.borderWidth,
                isIncoming: false,
                type: "NUMBER",
                isComputedColumn: false,
                computedColumns: {},
              },
            ]);
          onPropertyChange({
            columns: [...selectedColumn, column],
            columnProperties: newColumnProperties,
          });
          setSelectedComponent((prev) => ({
            ...prev,
            properties: {
              ...prev.properties,
              columns: [...selectedColumn, column],
              columnProperties: newColumnProperties,
            },
          }));
          setCurrentReportData({
            columns: [...selectedColumn, column],
            data: component?.properties?.data,
            isNewReport: false,
            templateId: component?.properties?.templateId,
            isColumnChanged: true,
          });
        } else {
          onPropertyChange({ columns: [...selectedColumn, column] });
          setSelectedComponent((prev) => ({
            ...prev,
            properties: {
              ...prev.properties,
              columns: [...selectedColumn, column],
            },
          }));
          setCurrentReportData({
            columns: [...selectedColumn, column],
            data: component?.properties?.data,
            isNewReport: false,
            templateId: component?.properties?.templateId,
            isColumnChanged: true,
          });
        }
        setSelectedColumn([...selectedColumn, column]);
        setFlag(false);
      }
    }
  };
  const filterDataByColumns = (data, columns) => {
    return data.map((row) => {
      let filteredRow = {};
      columns.forEach((column) => {
        filteredRow[column] = Object.prototype.hasOwnProperty.call(row, column)
          ? row[column]
          : ""; // Add empty string for missing columns
      });
      return filteredRow;
    });
  };
  const handlePositionChange = (e, property) => {
    const newValue = parseInt(e.target.value, 10);
    let updatedProperties = { [property]: newValue };
    if (newValue < 0) {
      updatedProperties[property] = 0;
    }
    if (property === "top") {
      updatedProperties.bottom =
        reportAreaRef.current.clientHeight -
        newValue -
        component.properties.height;
      // Ensure bottom doesn't go below 0
      if (updatedProperties.bottom < 0) {
        updatedProperties.bottom = 0;
        updatedProperties.top =
          reportAreaRef.current.clientHeight - component.properties.height;
      }
    } else if (property === "bottom") {
      updatedProperties.top =
        reportAreaRef.current.clientHeight -
        newValue -
        component.properties.height;
      // Ensure top doesn't go below 0
      if (updatedProperties.top < 0) {
        updatedProperties.top = 0;
        updatedProperties.bottom =
          reportAreaRef.current.clientHeight - component.properties.height;
      }
    } else if (property === "left") {
      updatedProperties.right =
        reportAreaRef.current.clientWidth -
        newValue -
        component.properties.width;
      if (updatedProperties.right < 0) {
        updatedProperties.right = 0;
        updatedProperties.left =
          reportAreaRef.current.clientWidth - component.properties.width;
      }
    } else if (property === "right") {
      updatedProperties.left =
        reportAreaRef.current.clientWidth -
        newValue -
        component.properties.width;
      if (updatedProperties.left < 0) {
        updatedProperties.left = 0;
        updatedProperties.right =
          reportAreaRef.current.clientWidth - component.properties.width;
      }
    }
    onPropertyChange(updatedProperties);
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        ...updatedProperties,
      },
    }));
    setFlag(false);
  };
  const handleClickOutside = (event) => {
    if (textColorRef.current && !textColorRef.current.contains(event.target)) {
      setColorPickerText(false);
    }
    if (
      backgroundColorRef.current &&
      !backgroundColorRef.current.contains(event.target)
    ) {
      setColorPickerBg(false);
    }
    if (
      borderColorRef.current &&
      !borderColorRef.current.contains(event.target)
    ) {
      setColorPickerOpen(false);
    }
    if (
      columnDropdownRef.current &&
      !columnDropdownRef.current.contains(event.target)
    ) {
      setColumnListOpen(false);
    }
    if (
      colADropdownRef.current &&
      !colADropdownRef.current.contains(event.target)
    ) {
      setComputedColListOpen(false);
    }
    if (
      colBDropdownRef.current &&
      !colBDropdownRef.current.contains(event.target)
    ) {
      setComputedColBListOpen(false);
    }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
    const isOutsideColorPickers = Object.keys(colorPickerRefs.current).every(
      (columnName) => {
        const refs = colorPickerRefs.current[columnName];
        return (
          !refs ||
          Object.keys(refs).every((pickerType) => {
            const ref = refs[pickerType];
            return ref && !ref.contains(event.target);
          })
        );
      }
    );
    if (isOutsideColorPickers) {
      setColorPickerState({});
    } else {
      // Only close color pickers that are not being targeted
      setColorPickerState((prevState) => {
        const newState = { ...prevState };
        Object.keys(colorPickerRefs.current).forEach((columnName) => {
          const refs = colorPickerRefs.current[columnName];
          Object.keys(refs).forEach((pickerType) => {
            const ref = refs[pickerType];
            if (ref && !ref.contains(event.target)) {
              if (newState[columnName]) {
                newState[columnName][pickerType] = false;
              }
            }
          });
        });
        return newState;
      });
    }
    // if (columnTextColorRef.current && !columnTextColorRef.current.contains(event.target)) {
    //     setColorPickerState({});
    // }
    // if (columnBackgroundRef.current && !columnBackgroundRef.current.contains(event.target)) {
    //     setColorPickerState({});
    // }
  };
  React.useEffect(() => {
    if (flag) {
      setDeselectedColumns([]);
      setSelectedColumn(component?.properties?.columns);
      setTempRow(
        component?.properties?.columns?.reduce(
          (acc, column) => ({ ...acc, [column]: "" }),
          {}
        )
      );
      const columns = component?.properties?.columns?.filter((item) => !component?.properties?.rowFields?.includes(item) && !component?.properties?.colFields?.includes(item))
      setSelectedCols(columns);
      setRowFields([]);
      setColFields([]);
    }
    if (tempRow == null) {
      setTempRow(
        component?.properties?.columns?.reduce(
          (acc, column) => ({ ...acc, [column]: "" }),
          {}
        )
      );
    }
  }, [component]);
  React.useEffect(() => {
    if (
      isSaveClicked === true &&
      component?.properties?.templateId != null &&
      component?.properties?.templateId != undefined
    ) {
      setOpen(true);
    } else {
      setIsSaveClicked(false);
    }
  }, [isSaveClicked]);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  React.useEffect(() => {
    if (inputRef.current && inputExpression !== "") {
      inputRef.current.focus();
    }
  }, [inputExpression]);
  const handleToggle = (section) => {
    setOpenSection(openSection === section ? null : section);
  };
  const handleTabChange = (event, newValue) => {
    onPropertyChange({ tableView: newValue });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        tableView: newValue
      },
    }));
    setFlag(false);
  }
  const handleSelectColumnToFilter = async (value) => {
    await clearFilter();
    const colType = component?.properties?.columnProperties?.find((item) => item?.name === value)?.type;
    const colData = component?.properties?.data?.map((item) => item[value]);
    value == -1 ?
      setColumnToFilter({ name: null, type: null, min: 0, max: 0, colData: [...new Set(colData)] }) : setColumnToFilter({ name: value, type: colType, min: 0, max: 0, colData: [...new Set(colData)] });
    setFilterObj({ columnName: value, columnType: colType, condition: null, condition_value: "", min: null, max: null, from: null, to: null, values: [] });
    if (colType == "INTEGER") {
      const col = component?.properties?.data.map(item => parseInt(item[value]));
      const min = Math.min(...col);
      const max = Math.max(...col);
      min === max ? setRangeValue(max) : setRangeValue(min);
      setColumnToFilter({
        name: value,
        type: colType,
        min: min,
        max: max,
        colData: [...new Set(colData)]
      });
      setFilterObj({
        columnName: value,
        columnType: "INT",
        condition: null,
        condition_value: "",
        min: min,
        max: max,
        from: null,
        to: null,
        values: []
      });
    }
  }
  const clearFilter = async () => {
    if (filterObj?.columnName !== undefined) {
      try {
        const result = await filterData({ ...filterObj, columnName: filterObj?.columnName, columnType: "VARCHAR", condition: null, condition_value: null, values: [], tableName: component?.properties?.tableName }).unwrap();
        onPropertyChange({
          data: result?.data,
          drilledData: Object?.entries(component?.properties?.drilledData).length > 0 ?
            processData(result?.data, component?.properties?.drilledColumns) : []
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            data: result?.data,
            drilledData: Object?.entries(component?.properties?.drilledData).length > 0 ?
              processData(result?.data, component?.properties?.drilledColumns) : []
          },
        }));
        // Object?.entries(component?.properties?.drilledData).length > 0 ? setDrillDownData(component?.properties?.drilledColumns, result?.data) : ""
        setFlag(false);
      } catch (err) {
        showAlert(err?.data?.message, "error")
      }
    }
    setFilterObj(null);
    setColumnToFilter(null);
    setSearchQuery("");
    setSelectAll(false);
  }
  const calculateFillPercentage = () => {
    const min = columnToFilter?.min ?? 0;
    const max = columnToFilter?.max ?? 100;
    const percentage = ((rangeValue - min) / (max - min)) * 100;

    return `${isNaN(percentage) ? 100 : percentage}%`;
  };
  const calculateTooltipPosition = () => {
    const min = columnToFilter?.min ?? 0;
    const max = columnToFilter?.max ?? 100;
    const percentage = ((rangeValue - min) / (max - min)) * 100;
    return `${percentage}%`;
  };
  function lightenColor(color, percent) {
    const num = parseInt(color.slice(1), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;

    return `rgb(${Math.min(255, R)}, ${Math.min(255, G)}, ${Math.min(255, B)})`;
  }
  React.useEffect(() => {
    if (searchQuery === '') {
      setFilteredItems(columnToFilter?.colData || []);
    } else {
      setFilteredItems(
        columnToFilter?.colData?.filter((item) => {
          // Convert item to string and then apply toLowerCase
          return item.toString().toLowerCase().includes(searchQuery.toLowerCase());
        }) || []
      );
    }
  }, [searchQuery, columnToFilter]);
  // Handle select all / deselect all
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setFilterObj({ ...filterObj, values: filteredItems });
    } else {
      setFilterObj({ ...filterObj, values: [] });
    }
  };
  const handleDeleteDropdown = (id) => {
    //setDropdowns((prev) => prev.filter((dropdown) => dropdown.id !== id));
    if (Object?.entries(component?.properties?.drilledData).length > 0) {
      const newDrps = component?.properties?.drilledColumns?.filter((item) => item?.id !== id);
      setFlag(false);
      setDrillDownData(newDrps);
    }
  };
  // Handle individual checkbox change
  const handleCheckboxChange = (item) => {
    if (filterObj?.values?.includes(item)) {
      setFilterObj({
        ...filterObj,
        values: filterObj?.values?.filter((val) => val !== item),
      });
    } else {
      setFilterObj({ ...filterObj, values: [...filterObj.values, item] });
    }
  };
  const handleAddDropdown = () => {
    onPropertyChange({
      drilledColumns: [...component.properties.drilledColumns, { id: columnCount + 1, columnToFilter: '' }]
    });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev?.properties,
        drilledColumns: [
          ...prev.properties.drilledColumns,
          { id: columnCount + 1, columnToFilter: '' },
        ]
      },
    }));
    setColumnCount((prev) => prev + 1);
    setFlag(false);
    // setDropdowns((prev) => [
    //   ...prev,
    //   { id: prev.length + 1, columnToFilter: '' },
    // ]);
  };
  // Function to update the selected value in the specific dropdown
  const handleDropdownChange = (id, value) => {
    const newDrps = component.properties.drilledColumns?.map((dropdown) =>
      dropdown.id === id
        ? { ...dropdown, columnToFilter: value }
        : dropdown
    )

    // onPropertyChange({
    //   drilledColumns: component.properties.drilledColumns?.map((dropdown) =>
    //     dropdown.id === id
    //       ? { ...dropdown, columnToFilter: value }
    //       : dropdown
    //   )
    // });
    // setSelectedComponent((prev) => ({
    //   ...prev,
    //   properties: {
    //     ...prev?.properties,
    //     drilledColumns: prev.properties.drilledColumns?.map((dropdown) =>
    //       dropdown.id === id
    //         ? { ...dropdown, columnToFilter: value }
    //         : dropdown
    //     )
    //   },
    // }));
    setDrillDownData(newDrps)
    setFlag(false);
    // setDropdowns((prev) =>
    //   prev.map((dropdown) =>
    //     dropdown.id === id
    //       ? { ...dropdown, columnToFilter: value }
    //       : dropdown
    //   )
    // );
    //  handleSelectColumnToFilter(value); // Call your existing handler if needed
  };
  const getFilteredOptions = () => {
    return component?.properties?.columns?.filter(
      (option) => !selectedValues.includes(option) || option === ""
    );
  };
  function processData(data, selectedColumns) {

    const treeStructure = {};
    data.forEach(row => {
      let currentLevel = treeStructure;
      selectedColumns.forEach((column, index) => {
        const columnValue = row[column.columnToFilter];

        if (index === selectedColumns.length - 1) {
          currentLevel[columnValue] = (currentLevel[columnValue] || 0) + 1;
        } else {
          if (!currentLevel[columnValue]) {
            currentLevel[columnValue] = {};
          }
          currentLevel = currentLevel[columnValue];
        }
      });
    });
    return treeStructure;
  }
  const setDrillDownData = (dropdowns, data) => {
    const result = processData(data ? data : component?.properties?.data, dropdowns);
    onPropertyChange({ drilledData: result, drilledColumns: dropdowns });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        drilledData: result,
        drilledColumns: dropdowns
      },
    }));
    setFlag(false);
  }
  const clearDrillDown = () => {
    onPropertyChange({ drilledData: [], drilledColumns: [{ id: 1, columnToFilter: '' }] });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        drilledData: [],
        drilledColumns: [{ id: 1, columnToFilter: '' }]
      },
    }));
    // setDropdowns([{ id: 1, columnToFilter: '' }]);
    setFlag(false);
  }
  const handleToggleDrillDropdown = (id) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }
  React.useEffect(() => {
    // Function to close dropdowns when clicking outside
    const handleClickOutside = (event) => {
      Object.keys(dropdownRefs.current).forEach((id) => {
        if (
          openDropdowns[id] &&
          dropdownRefs.current[id] &&
          !dropdownRefs.current[id].contains(event.target)
        ) {
          setOpenDropdowns((prevState) => ({
            ...prevState,
            [id]: false,
          }));
        }
      });
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [openDropdowns]);
  return (
    <>
      {IsAddNewColumnOpen && (
        <ReusableReportModal
          open
          onClose={() => {
            setIsAddNewColumnOpen(false);
            setSelectedColumns("");
            setActiveTab(0);
            setIsColumnEdited(false);
            setOriginalColumnName(null);
          }}
          title={"Select Column Type You want to add"}
        >
          <div
            className="px-3 text-sm no-scrollbar d-flex flex-col my-3"
            style={{
              minWidth: "35rem",
              maxHeight: "40vh",
              overflow: "auto",
            }}
            fontColor="#105597"
          >
            {Object.keys(component?.properties?.data[0])?.map(
              (item, index) => (
                <>
                  {/* xs=3 creates 4 columns (12/3=4) */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={component?.properties?.columns.includes(
                          item
                        )}
                        onChange={() =>
                          //  handleCheckboxChange(item)
                          handleColumnNameFilter(item)
                        }
                        sx={{
                          padding: "4px 9px",
                        }}
                      />
                    }
                    label={
                      <span className="whitespace-nowarp text-sm pb-1">{item}</span>
                    }
                  />
                </>
              )
            )}
          </div>
        </ReusableReportModal>
      )}
      <DndProvider backend={HTML5Backend}>
        <div className="flex flex-row items-start justify-start w-full gap-1 py-3 px-3 items-center">
          <p className="font-semibold text-sm  text-gray-500 m-0">Name:</p>
          <input
            type="text"
            className="block p-1 text-sm text-gray-900 bg-white border-custome rounded w-full  focus:ring-blue-500 focus:border-blue-500"
            value={component?.properties?.name || ""}
            onChange={(e) => {
              const newValue = e.target.value;
              onPropertyChange({ name: newValue });
              setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                  ...prev.properties,
                  name: newValue,
                },
              }));
              setFlag(false);
            }}
          />
        </div>
        <span className="pivottab">
          <Tabs
            value={component?.properties?.tableView}
            // value='MTD'
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
          >
            <Tab label="Data Table" value="Data Table" />
            <Tab label="Pivot Table" value="Pivot Table" />
          </Tabs>
          {component?.properties?.tableView === 'Pivot Table' && (
            <span>
              <div className="flex flex-col items-start justify-start w-full gap-3 pt-2 pb-2 px-3">
                <span className="w-full flex flex-col">
                  <DropZone accept={ItemTypes?.COLUMN} onDrop={handleDropToSelected}
                    title="Selected Column:"
                  >
                    {selectedCols?.filter((item) => selectedColumn?.includes(item))?.map((item, index) => (
                      <Column key={item} item={item} index={index} removeColumn={() => removeSelectedColumn(item)} showCloseIcon={false} />
                    ))}
                    <span className="w-full flex justify-end addcol pr-1 pb-1">
                      <Tooltip title="Add Column">
                        <AddIcon className="cursor-pointer" onClick={() => setIsAddNewColumnOpen(true)} />
                      </Tooltip>
                    </span>
                  </DropZone>
                </span>
                <span className="w-full">
                  <DropZone accept={ItemTypes?.COLUMN} onDrop={handleDropToRow}
                    title="Row Field:"
                  >
                    {component?.properties?.rowFields?.map((item, index) => (
                      <Column key={item} item={item} index={index} removeColumn={() => removeRowField(item)} showCloseIcon={true} />
                    ))}
                  </DropZone>
                </span>
                <span className="w-full">
                  <DropZone accept={ItemTypes?.COLUMN} onDrop={handleDropToCol}
                    title="Column Field:"
                  >
                    {component?.properties?.colFields?.map((item, index) => (
                      <Column key={item} item={item} index={index} removeColumn={() => removeColField(item)} showCloseIcon={true} />
                    ))}
                  </DropZone>
                </span>
              </div>
              <div>
                <span className="flex  py-2 space-x-4 lg:ml-5 sm:ml-4 mb-2">
                  <p className="m-0 prop-label whitespace-nowrap self-center">
                    Aggregation:
                  </p>
                  <select
                    value={component?.properties?.aggType || "solid"}
                    onChange={(e) => {
                      onPropertyChange({ aggType: e.target.value });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          aggType: e.target.value,
                        },
                      }));
                      setFlag(false);
                    }}
                    className="block ml-2 p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                  >
                    {["Count", "Sum", "Average", "Maximum", "Minimum", "Median", "First", "Last"]?.map(
                      (item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      )
                    )}
                  </select>
                </span>
              </div>
            </span>)}{component?.properties?.tableView === 'Data Table' && (<div className="mb-2"></div>)}
        </span>
        <SidebarSection
          title="Filters"
          subSection={false}
          isOpen={openSection === "filters"}
          onToggle={() => handleToggle("filters")}
        >
          <span className="d-flex mt-3  space-x-4 lg:justify-center sm:justify-start lg:mr-[67px] lg:ml-0 sm:ml-2">
            <p className="m-0  whitespace-nowrap prop-label self-center">
              Select Column:
            </p>
            <select
              value={columnToFilter?.name || -1}
              onChange={(e) => {
                handleSelectColumnToFilter(e?.target?.value);
              }}
              className="block  p-1 text-xs w-[150px] text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
            >
              <option value={-1}>Select Column</option>
              {component?.properties?.columns?.map((item, index) => (
                <option key={index} value={item} >
                  {item}
                </option>
              ))}
            </select>
          </span>
          {columnToFilter?.name !== null && columnToFilter?.name !== undefined && columnToFilter?.name !== "" &&
            <span className="d-flex gap-3 justify-center  lg:w-full sm:w-64 lg:ml-1 sm:ml-[15px]  pt-3">
              <p className="m-0 whitespace-nowrap  prop-label self-center">
                Column Filter:
              </p>
              <span className="flex relative flex-col mx-0 h-auto" ref={dropdownRef}>
                <span className="flex items-center relative" onClick={handleToggleDropdown}
                >
                  <input
                    type="text"
                    // value={columnToFilter?.name}
                    value={filterObj?.values.filter(item => (typeof item == 'object' || typeof item == 'boolean' ? JSON.stringify(item) : item)).join(', ') || ''}
                    className="w-full pl-2 pr-10  text-xs text-[#374151] h-7 rounded border-custome cursor-pointer"
                    readOnly
                    placeholder="Select Values "
                  />
                  <KeyboardArrowDownIcon
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    style={{ color: 'black' }}
                  />
                </span>
                {isDropdownOpen && (
                  <div
                    className="absolute flex flex-col w-full overflow-auto custom-scrollbar bg-white border rounded-md max-h-52 top-8 z-10"
                    style={{
                      boxShadow:
                        "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <div className="hover:bg-primarylightgrey d-flex px-2 py-2 items-center gap-2">
                      <span className="relative w-full prop-label">
                        <input
                          type="text"
                          placeholder="Search"
                          className="w-full pl-2 pr-10 py-1 rounded border-custome text-[#374151]"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <SearchIcon
                          className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                          style={{ color: '#374151' }}
                        />
                      </span>
                    </div>
                    <div className="hover:bg-primarylightgrey d-flex px-2 py-2 items-center gap-2">
                      <label className="flex flex-row items-center gap-2 cursor-pointer">
                        <input
                          type="checkbox"
                          className="w-4 h-4"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                        <p className="m-0 cursor-pointer text-sm font-base">Select All</p>
                      </label>
                    </div>
                    {filteredItems?.map((item, index) => (
                      <div
                        className={classNames(
                          "hover:bg-primarylightgrey",
                          columnToFilter?.colData?.length - 1 &&
                          "border-b",
                          "d-flex px-2 py-2 items-center gap-2"
                        )}
                      >
                        <label className="flex flex-row items-center gap-2 cursor-pointer">
                          <input
                            type="checkbox"
                            className="w-4 h-4 checked:lightblue"
                            checked={
                              filterObj?.values.includes(item) ? true : false
                            }
                            // onChange={() => {
                            //   if (filterObj?.values?.includes(item)) {
                            //     setFilterObj({ ...filterObj, values: filterObj?.values?.filter((val) => val !== item) })
                            //   } else {
                            //     setFilterObj({ ...filterObj, values: [...filterObj.values, item] })
                            //   }
                            // }}
                            onChange={() => handleCheckboxChange(typeof item == 'object' ? JSON.stringify(item) : item)}
                          />
                          <p
                            className={classNames(
                              "m-0 cursor-pointer text-sm font-base"
                            )}
                          >
                            {typeof item == 'object' ? JSON.stringify(item) : typeof item === 'boolean' ? JSON.stringify(item) : item}
                          </p>
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </span>
            </span>
          }
          {columnToFilter?.type === "DATE" &&
            <span className="d-flex lg:justify-center sm:justify-start my-3 gap-3 lg:ml-[37px] sm:ml-[27px]">
              <p className="m-0 whitespace-nowrap prop-label self-center ">
                Date Range:
              </p>
              <div className='d-flex flex-row justify-center filter-date '>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DateRangePicker']}>
                    <DateRangePicker
                      localeText={{ start: '', end: '' }}
                      calendars={2}
                      value={dateRange}
                      onChange={(newValue) => {
                        setDateRange(newValue);
                        setFilterObj({ ...filterObj, from: new Date(newValue[0]), to: new Date(newValue[1]) })
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </span>}
          {columnToFilter?.type === "VARCHAR" &&
            <span className="d-flex lg:justify-center sm:justify-start py-2  pt-3 space-x-4 lg:ml-[57px] sm:ml-[38px]">
              <p className="m-0  whitespace-nowrap prop-label self-center">
                Condition:
              </p>
              <span className="flex gap-2">
                <select
                  value={filterObj?.condition}
                  onChange={(e) => {
                    setFilterObj({ ...filterObj, condition: e?.target?.value });
                  }}
                  className="block lg:w-fit sm:w-[90px] p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value={-1}>Select Condition</option>
                  {stringTypeConditions.map((item, index) => (
                    <option key={index} value={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </select>
                <input
                  className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                  value={filterObj?.condition_value || ""}
                  onChange={(e) => {
                    setFilterObj({ ...filterObj, condition_value: e?.target?.value })
                  }}
                />
              </span>
            </span>}
          {columnToFilter?.type === "INTEGER" && <>
            <span className="d-flex lg:justify-center sm:justify-start  py-2 space-x-4 mt-2 lg:mr-[68px] lg:ml-0 sm:ml-[56px] ">
              <p className="m-0 prop-label whitespace-nowrap  self-center">
                Range:
              </p>
              <span className="d-flex w-[100px]">
                <p className="my-0 ml-1 mr-0 prop-label whitespace-nowrap self-center">
                  {columnToFilter?.min}
                </p>
                <Tooltip title={rangeValue} placement="top">
                  <input type="range"
                    min={columnToFilter?.min}
                    max={
                      columnToFilter?.min === columnToFilter?.max
                        ? columnToFilter?.max + 1 // Slightly increase the max if min and max are the same
                        : columnToFilter?.max
                    }
                    className="custom-range mx-2 self-center"
                    style={{
                      background: `linear-gradient(to right,#0a3a67 ${calculateFillPercentage()}, #ddd ${calculateFillPercentage()})`,
                      opacity: columnToFilter?.min === columnToFilter?.max ? 0.5 : 1,
                      pointerEvents: columnToFilter?.min === columnToFilter?.max ? 'none' : 'auto',
                    }}
                    value={columnToFilter?.min === columnToFilter?.max ? rangeValue + 1 : rangeValue}
                    onChange={handleRangeChange}
                    disabled={columnToFilter?.min === columnToFilter?.max}
                  />
                </Tooltip>
                <p className="m-0 prop-label whitespace-nowrap self-center">
                  {" "}{columnToFilter?.max}
                </p>
              </span>
            </span>
            <span className="d-flex lg:justify-center sm:justify-start py-2 space-x-4 lg:ml-[57px] sm:ml-[38px]">
              <p className="m-0  whitespace-nowrap prop-label self-center">
                Condition:
              </p>
              <span className="flex gap-2">
                <select
                  value={component?.properties?.conditionType || "Equal To"}
                  onChange={(e) => {
                    setFilterObj({ ...filterObj, condition: e?.target?.value });
                    onPropertyChange({ conditionType: e.target.value });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        conditionType: e.target.value,
                      },
                    }));
                    setFlag(false);
                  }}
                  className="block  p-1 lg:w-fit sm:w-[90px] text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value={-1}>Select Condition</option>
                  {numericTypeConditions.map((item, index) => (
                    <option key={index} value={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </select>
                <input
                  type="number"
                  //value={component?.properties?.columnvalue || ""}
                  value={filterObj?.condition_value || ""}
                  onChange={(e) => {
                    setFilterObj({ ...filterObj, condition_value: e?.target?.value })
                    onPropertyChange({ columnvalue: e.target.value });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        columnvalue: e.target.value,
                      },
                    }));
                    setFlag(false);
                  }}
                  className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded lg:w-[90px] sm:w-[60px] focus:ring-blue-500 focus:border-blue-500"
                />
              </span>
            </span>
            {/* <span className="d-flex lg:justify-center sm:justify-start py-2 space-x-4 lg:mr-[76px] lg:ml-0 sm:ml-[35px] mb-2">
              <p className="m-0  whitespace-nowrap prop-label self-center">
                Format As:
              </p>
              <select
                value={component?.properties?.formatType || "Currency"}
                onChange={(e) => {
                  onPropertyChange({ formatType: e.target.value });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      formatType: e.target.value,
                    },
                  }));
                  setFlag(false);
                }}
                className="block  p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
              >
                <option value={-1}>Select Format</option>
                {["Currency", "Percentage", "Duration", "Date/Time"].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </span> */}
          </>}
          <span className="d-flex items-center w-full justify-end pr-2 py-2">
            <button
              onClick={clearFilter}
              className={`px-3 ml-2 py-1 font-bold text-sm btn-bgcolor text-white rounded-md  disabled:opacity-60 disabled:cursor-not-allowed  }`}
              disabled={filterObj === null}
            >
              Clear
            </button>
            <button
              onClick={async () => {
                try {
                  const result = await filterData({ ...filterObj, tableName: component?.properties?.tableName, columnType: filterObj?.columnType === 'BOOLEAN' ? 'VARCHAR' : filterObj?.columnType }).unwrap();
                  onPropertyChange({
                    data: result?.data,
                    drilledData: Object?.entries(component?.properties?.drilledData).length > 0 ?
                      processData(result?.data, component?.properties?.drilledColumns) : []
                  });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      data: result?.data,
                      drilledData: Object?.entries(component?.properties?.drilledData).length > 0 ?
                        processData(result?.data, component?.properties?.drilledColumns) : []
                    },
                  }));
                  setFlag(false);
                } catch (err) {
                  showAlert(err?.data?.message, "error")
                }
              }}
              disabled={filterObj === null}
              className={`px-3 ml-2 py-1 font-bold text-sm btn-bgcolor text-white rounded-md   disabled:opacity-60 disabled:cursor-not-allowed }`}
            >
              Apply
            </button>
          </span>
        </SidebarSection>
        <SidebarSection
          title="Drill Down"
          subSection={false}
          isOpen={openSection === "drill down"}
          onToggle={() => handleToggle("drill down")}
        >
          <span className="d-flex justify-between">
            <div className="d-flex flex-column" >
              {component?.properties?.drilledColumns?.map((dropdown, index) => (
                <span className="d-flex  lg:w-full sm:w-64  py-2 pl-3" key={dropdown?.id}
                  ref={(el) => {
                    if (el) {
                      dropdownRefs.current[dropdown?.id] = el;
                    }
                  }}>
                  <p className="m-0 whitespace-nowrap  prop-label self-center pr-3">
                    Select Column:
                  </p>
                  <span className="flex relative  mx-0 h-auto">
                    <span className="flex items-center relative cursor-pointer"
                      onClick={() => handleToggleDrillDropdown(dropdown?.id)}
                    >
                      <input
                        type="text"
                        value={dropdown?.columnToFilter || ''}
                        className="w-full pl-2 pr-10  cursor-pointer  text-xs text-[#374151] h-7 rounded border-custome"
                        onChange={(e) => handleDropdownChange(dropdown?.id, e.target.value)}
                        placeholder="Select Column "
                        readOnly
                      />
                      <KeyboardArrowDownIcon
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer"
                        style={{ color: 'black' }}
                      />
                    </span>
                    {openDropdowns[dropdown?.id] && (
                      <div
                        className="absolute flex flex-col w-full overflow-auto custom-scrollbar bg-white border rounded-md max-h-52 top-8 z-10"
                        style={{
                          boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        {getFilteredOptions().map((item, index) => (
                          <div
                            key={index}
                            className="hover:bg-primarylightgrey border-b d-flex px-2 py-2 items-center gap-2"
                            onClick={() => {
                              handleDropdownChange(dropdown.id, item);
                              setOpenDropdowns((prevState) => ({
                                ...prevState,
                                [dropdown.id]: false,
                              }));
                            }}
                          >
                            <option className="m-0 cursor-pointer text-sm font-base">
                              {item}
                            </option>
                          </div>
                        ))}
                      </div>
                    )}
                  </span>
                  {index !== 0 &&
                    <Tooltip title="Remove Column">
                      <DeleteOutlineOutlinedIcon
                        className="ml-1 cursor-pointer text-[#105597]"
                        onClick={() => handleDeleteDropdown(dropdown.id)}
                      />
                    </Tooltip>
                  }
                </span>
              ))}
            </div>
            <Tooltip title="Add Column">
              <AddIcon
                className={`mx-2 my-2 ${component?.properties?.drilledColumns.length >= MAX_COLUMNS ||
                  component?.properties?.drilledColumns.some((dropdown) => !dropdown?.columnToFilter?.trim())
                  ? 'text-gray-400 cursor-not-allowed'
                  : 'cursor-pointer text-black'
                  }`}
                onClick={
                  component?.properties?.drilledColumns.length >= MAX_COLUMNS ||
                    component?.properties?.drilledColumns.some((dropdown) => !dropdown?.columnToFilter?.trim())
                    ? null
                    : handleAddDropdown
                }
              />
            </Tooltip>
          </span>
          <span className="flex justify-end gap-1 pr-3 mt-2 mb-2">
            <button
              onClick={clearDrillDown}
              className={`px-3 ml-2 py-1 font-bold text-sm btn-bgcolor text-white rounded-md  disabled:opacity-60 disabled:cursor-not-allowed  }`}
            >
              Clear
            </button>
          </span>
        </SidebarSection>
        {selectedTemplate == null ? (
          <>
            <SidebarSection
              title="Position"
              subSection={false}
              isOpen={openSection === "position"}
              onToggle={() => handleToggle("position")}
            >
              <span className="d-flex px-2 py-2 justify-center">
                <span className="d-flex flex-col">
                  <span className="d-flex py-2">
                    <p className="prop-label mb-1 self-end">Position (px):</p>
                    <div className="mx-2">
                      <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-700">
                        Left
                      </p>
                      <input
                        type="number"
                        className=" border-custome block p-1 text-xs text-gray-900 bg-white rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                        value={Math?.round(component?.properties?.left)}
                        onChange={(e) => handlePositionChange(e, "left")}
                      />
                    </div>
                    <div className="mx-2">
                      <p className="m-0 font-medium text-tiny text-gray-700 whitespace-nowrap">
                        Top
                      </p>
                      <input
                        type="number"
                        className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                        value={Math?.round(component?.properties?.top)}
                        onChange={(e) => handlePositionChange(e, "top")}
                      />
                    </div>
                  </span>
                  <span className="d-flex py-2 self-end">
                    <div className="mx-2">
                      <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-700">
                        Right
                      </p>
                      <input
                        type="number"
                        className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                        value={Math?.round(component?.properties?.right)}
                        onChange={(e) => handlePositionChange(e, "right")}
                      />
                    </div>
                    <div className="mx-2">
                      <p className="m-0 font-medium text-tiny text-gray-700 whitespace-nowrap">
                        Bottom
                      </p>
                      <input
                        type="number"
                        className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                        value={Math?.round(component?.properties?.bottom)}
                        onChange={(e) => handlePositionChange(e, "bottom")}
                      />
                    </div>
                  </span>
                </span>
              </span>
            </SidebarSection>
            <SidebarSection
              title="Appearance"
              subSection={false}
              isOpen={openSection === "appearance"}
              onToggle={() => handleToggle("appearance")}
            >
              <span className="justify-center px-2 py-2 pt-3 space-x-2 flex sm:flex-row lg:flex-row ">
                <span className="m-0 lg:self-end lg:pb-0 mb-1 sm:self-center sm:pb-7">
                  <p className="m-0 prop-label lg:self-end mb-0">Size (px):</p>
                </span>
                <span className="flex lg:flex-row sm:flex-col lg:space-x-2 sm:space-y-1 lg:space-y-0">
                  <div className="flex flex-col space-x-2">
                    <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-700 pl-2">
                      Height
                    </p>
                    <input
                      type="number"
                      className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                      value={component?.properties?.height || 250}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        if (newValue !== "") {
                          newValue = newValue.replace(/^0+/, "") || "0";
                        }
                        if (parseInt(newValue) < 0) {
                          newValue = "0";
                        }
                        onPropertyChange({ height: newValue });
                        setSelectedComponent((prev) => ({
                          ...prev,
                          properties: {
                            ...prev.properties,
                            height: newValue,
                          },
                        }));
                        setFlag(false);
                      }}
                    />
                  </div>
                  <div className="flex flex-col space-x-2">
                    <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-700 pl-2">
                      Width
                    </p>
                    <input
                      type="number"
                      className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                      value={component?.properties?.width || 900}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        if (newValue !== "") {
                          newValue = newValue.replace(/^0+/, "") || "0";
                        }
                        if (parseInt(newValue) < 0) {
                          newValue = "0";
                        }
                        onPropertyChange({ width: newValue });
                        setSelectedComponent((prev) => ({
                          ...prev,
                          properties: {
                            ...prev.properties,
                            width: newValue,
                          },
                        }));
                        setFlag(false);
                      }}
                    />
                  </div>
                </span>
              </span>

              <div className=" flex lg:flex-row sm:flex-col justify-center items-center space-x-4">
                <span className="flex  py-2 space-x-4 lg:ml-5 sm:ml-16">
                  <p className="m-0 prop-label whitespace-nowrap self-center">
                    Border:
                  </p>
                  <select
                    value={component?.properties?.borderType || "solid"}
                    onChange={(e) => {
                      onPropertyChange({ borderType: e.target.value });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          borderType: e.target.value,
                        },
                      }));
                      setFlag(false);
                    }}
                    className="block ml-2 p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value={-1}>Select Border Type</option>
                    {["Solid", "Dotted", "Dashed", "Double", "None"].map(
                      (item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      )
                    )}
                  </select>
                </span>
                <span
                  className="relative flex sm:flex-row sm:space-x-4 lg:space-x-0 sm:py-2 sm:pr-20 lg:pr-0"
                  ref={borderColorRef}
                >
                  <p className="m-0  whitespace-nowrap prop-label self-center sm:block lg:hidden">
                    Border Color:
                  </p>
                  <span className="flex items-center border-custome ">
                    <div
                      className="w-6 h-6 sketch-border"
                      style={{
                        backgroundColor:
                          component?.properties?.borderColor || "#000000",
                      }}
                    />
                    <div
                      className="w-5 h-6 text-center cursor-pointer"
                      onClick={() => {
                        setColorPickerOpen(!colorPickerOpen);
                        setColorPickerBg(false);
                        setColorPickerText(false);
                      }}
                    >
                      <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                    </div>
                  </span>
                  {colorPickerOpen && (
                    <div className="absolute z-10 right-0 bottom-0 mb-[38px] cursor-pointer">
                      <SketchPicker
                        color={
                          component?.properties?.borderColor || "rgba(0,0,0,1)"
                        }
                        onChange={(color) => {
                          const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                          const lightShade = lightenColor(color.hex, 30);
                          onPropertyChange({ borderColor: rgbaColor });
                          setSelectedComponent((prev) => ({
                            ...prev,
                            properties: {
                              ...prev.properties,
                              borderColor: rgbaColor,
                            },
                          }));
                          const pivotTable = document.querySelector(`.dashboard-main.element-${component?.id}.dashBoard-pivot .pvtTable`);

                          if (pivotTable) {
                            pivotTable.style.setProperty('--pvt-table-border-color', component?.properties?.borderColor);
                            pivotTable.style.setProperty('--pvt-table-background-color', lightShade);
                          }
                          setFlag(false);
                        }}
                      />
                    </div>
                  )}
                </span>
              </div>
              {component?.properties?.tableView !== 'Pivot Table' &&
                (
                  <div className="d-flex justify-center py-2 space-x-4 lg:mr-[126px] sm:mr-6">
                    <p className="m-0  whitespace-nowrap prop-label self-center">
                      Border Width:
                    </p>
                    <input
                      type="number"
                      className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                      value={component?.properties?.borderWidth || 0}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        if (newValue !== "") {
                          newValue = newValue.replace(/^0+/, "") || "0";
                        }
                        if (parseInt(newValue) < 0) {
                          newValue = "0";
                        }
                        onPropertyChange({ borderWidth: newValue });
                        setSelectedComponent((prev) => ({
                          ...prev,
                          properties: {
                            ...prev.properties,
                            borderWidth: newValue,
                          },
                        }));
                        setFlag(false);
                      }}
                    />
                  </div>
                )
              }
              {/* <div className="d-flex justify-center py-2 space-x-4 lg:mr-[126px] sm:mr-6">
                <p className="m-0  whitespace-nowrap prop-label self-center">
                  Border Width:
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.borderWidth || 0}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue !== "") {
                      newValue = newValue.replace(/^0+/, "") || "0";
                    }
                    if (parseInt(newValue) < 0) {
                      newValue = "0";
                    }
                    onPropertyChange({ borderWidth: newValue });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        borderWidth: newValue,
                      },
                    }));
                    setFlag(false);
                  }}
                />
              </div> */}

              <div className="d-flex justify-center py-2 lg:mr-[70px] sm:ml-8 lg:ml-0 space-x-4">
                <p className="m-0  whitespace-nowrap prop-label self-center">
                  Align:
                </p>
                <select
                  value={component?.properties?.align}
                  onChange={(e) => {
                    const selectedTextAlign = e.target.value
                    onPropertyChange({ align: selectedTextAlign });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        align: selectedTextAlign,
                      },
                    }));
                    const pivotTable = document.querySelector('.dashBoard-pivot .pvtTable');
                    if (pivotTable) {
                      pivotTable.style.setProperty('--pvt-table-text-align', selectedTextAlign);
                    }
                    setFlag(false);
                    //handleColumnPropertyChange(item.name, { align: e.target.value })
                  }}
                  className="block  p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value={-1}>Select Align</option>
                  {["Center", "Left", "Right"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              <div className="d-flex justify-center py-2 space-x-4 lg:mr-[196px] sm:mr-24">
                <p className="m-0 prop-label whitespace-nowrap  self-center">
                  Background Color:
                </p>
                <span className="relative" ref={backgroundColorRef}>
                  <div className="flex items-center border-custome ">
                    <div
                      className="w-6 h-6 sketch-border"
                      style={{
                        backgroundColor:
                          component?.properties?.backgroundColor || "#fff",
                      }}
                    />
                    <div
                      className="w-5 h-6 text-center cursor-pointer"
                      onClick={() => {
                        setColorPickerBg(!colorPickerBg);
                        setColorPickerOpen(false);
                        setColorPickerText(false);
                      }}
                    >
                      <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                    </div>
                  </div>
                  {colorPickerBg && (
                    <div className="absolute z-10 bottom-0  mb-[32px] cursor-pointer">
                      <SketchPicker
                        color={
                          component?.properties?.backgroundColor ||
                          "rgba(255,255,255,1)"
                        }
                        onChange={(color) => {
                          const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                          onPropertyChange({ backgroundColor: rgbaColor });
                          setSelectedComponent((prev) => ({
                            ...prev,
                            properties: {
                              ...prev.properties,
                              backgroundColor: rgbaColor,
                            },
                          }));
                          const pivotTable = document.querySelector(`.dashboard-main.element-${component?.id}.dashBoard-pivot .pvtTable`);
                          if (pivotTable) {
                            pivotTable.style.setProperty('--pvt-table-bg-color', rgbaColor);
                          }
                          setFlag(false);
                        }}
                      />
                    </div>
                  )}
                </span>
              </div>
              <div className="flex lg:flex-row sm:flex-col justify-center items-center space-x-3">
                <span className="flex  py-2 space-x-4 lg:ml-[30px] sm:ml-[72px]">
                  <p className="m-0 prop-label whitespace-nowrap  self-center">
                    Font:
                  </p>
                  <select
                    value={component?.properties?.fontFamily}

                    onChange={(e) => {
                      const selectedFontFamily = e.target.value;
                      onPropertyChange({ fontFamily: selectedFontFamily });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          fontFamily: selectedFontFamily,
                        },
                      }));
                      const pivotTable = document.querySelector('.dashBoard-pivot .pvtTable');
                      if (pivotTable) {
                        pivotTable.style.setProperty('--pvt-table-font-family', selectedFontFamily);
                      }
                      setFlag(false);
                      //handleColumnPropertyChange(item.name, { align: e.target.value })
                    }}
                    className="block p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value={"Inter"}>Select Font Family</option>
                    {Fonts.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </span>
                <span
                  className="relative flex sm:flex-row sm:space-x-4 lg:space-x-0 sm:py-2 sm:pr-16 lg:pr-0"
                  ref={textColorRef}
                >
                  <p className="m-0  whitespace-nowrap prop-label self-center sm:block lg:hidden">
                    Font Color:
                  </p>
                  <span className="flex items-center border-custome ">
                    <div
                      className="w-6 h-6 sketch-border"
                      style={{
                        backgroundColor:
                          component?.properties?.textColor || "#000000",
                      }}
                    />
                    <div
                      className="w-5 h-6 text-center cursor-pointer"
                      onClick={() => {
                        setColorPickerText(!colorPickerText);
                        setColorPickerBg(false);
                        setColorPickerOpen(false);
                      }}
                    >
                      <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                    </div>
                  </span>
                  {colorPickerText && (
                    <div className="absolute z-10 right-0  bottom-0 mb-[38px] cursor-pointer">
                      <SketchPicker
                        color={
                          component?.properties?.textColor || "rgba(0, 0, 0, 1)"
                        }
                        onChange={(color) => {
                          const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                          onPropertyChange({ textColor: rgbaColor });
                          setSelectedComponent((prev) => ({
                            ...prev,
                            properties: {
                              ...prev.properties,
                              textColor: rgbaColor,
                            },
                          }));
                          const pivotTable = document.querySelector(`.dashboard-main.element-${component?.id}.dashBoard-pivot .pvtTable`);
                          if (pivotTable) {
                            pivotTable.style.setProperty('--pvt-table-font-color', rgbaColor);
                          }
                          setFlag(false);
                        }}
                      />
                    </div>
                  )}
                </span>
              </div>
              <div className="d-flex justify-center py-2 space-x-4  lg:mr-[103px]">
                <p className="block m-0 prop-label whitespace-nowrap  self-center">
                  Font Size:
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px] focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.fontSize || 0}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue !== "") {
                      newValue = newValue.replace(/^0+/, "") || "0";
                    }
                    if (parseInt(newValue) < 0) {
                      newValue = "0";
                    }
                    onPropertyChange({ fontSize: newValue });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        fontSize: newValue,
                      },
                    }));
                    const pivotTable = document.querySelector('.dashBoard-pivot .pvtTable');
                    if (pivotTable) {
                      pivotTable.style.setProperty('--pvt-table-font-size', `${newValue}px`);
                    }
                    setFlag(false);
                  }}
                />
              </div>
              <div className="d-flex justify-center py-2 pb-3 space-x-4 lg:mr-[68px] sm:ml-8 lg:ml-0">
                <p className="m-0  whitespace-nowrap prop-label self-center">
                  Font Weight:
                </p>
                <select
                  value={component?.properties?.fontWeight}
                  onChange={(e) => {
                    onPropertyChange({ fontWeight: e.target.value });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        fontWeight: e.target.value,
                      },
                    }));
                    const pivotTable = document.querySelector('.dashBoard-pivot .pvtTable');
                    if (pivotTable) {
                      pivotTable.style.setProperty('--pvt-table-font-weight', component?.properties?.fontWeight);
                    }
                    setFlag(false);
                    //handleColumnPropertyChange(item.name, { align: e.target.value })
                  }}
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded cursor-pointer  focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value={"Inter"}>Select Font Weight</option>
                  {fontWeights.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </SidebarSection>
          </>
        ) : (
          ""
        )}
        {component?.properties?.tableView !== 'Pivot Table' &&
          (
            <SidebarSection
              title="Columns"
              subSection={false}
              isOpen={openSection === "columns"}
              onToggle={() => handleToggle("columns")}
            >
              <span className="d-flex flex-col gap-3 items-center justify-center px-2 py-2 pt-3">
                {selectedTemplate !== null && (
                  <span className={`d-flex flex-col `}>
                    <div className={`d-flex sm:flex-row flex-col h-fit`}>
                      <div
                        className="relative flex  border-custome  mt-1 flex-row items-center gap-2 divide-x-2 rounded w-72 md:w-96 "
                        ref={columnDropdownRef}
                      >
                        <span className="flex flex-row border-0 items-center w-full h-full py-1 gap-2 px-2 overflow-scroll flex-nowrap no-scrollbar border-gray-600 ">
                          <p className="m-0 text-sm text-gray-800 whitespace-nowrap">
                            Select Columns
                          </p>
                        </span>
                        <button
                          className="pl-2 border-0"
                          onClick={() => {
                            setColumnListOpen(!columnListOpen);
                          }}
                        >
                          <KeyboardArrowDownIcon className="text-gray-600"></KeyboardArrowDownIcon>
                        </button>
                        {columnListOpen && (
                          <div
                            className="absolute flex flex-col w-full overflow-auto custom-scrollbar bg-white border rounded-md max-h-52 top-9 z-10"
                            style={{
                              boxShadow:
                                "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                            }}
                          >
                            {true ? (
                              <div className="d-flex px-1 py-2">
                                <input
                                  type="text"
                                  value={newCol}
                                  onChange={(e) => {
                                    setNewCol(e.target.value);
                                  }}
                                  className=" block  text-sm text-gray-900  border-custome rounded w-full focus:ring-blue-500 focus:border-blue-500 px-2 py-1 m-1  outline-1"
                                  autoFocus
                                  placeholder="Add column Name"
                                  style={{ outlineWidth: "1px !important" }}
                                />
                                <select
                                  value={newColType}
                                  onChange={(e) => setNewColType(e.target.value)}
                                  className="block text-sm text-gray-900 border-custome rounded outline-1 w-24 h-8 m-1"
                                >
                                  <option value="VARCHAR" className="text-sm">
                                    Text
                                  </option>
                                  <option value="INTEGER" className="text-sm">
                                    Number
                                  </option>
                                  <option value="FLOAT" className="text-sm">
                                    Float
                                  </option>
                                  <option value="DATE" className="text-sm">
                                    Date
                                  </option>
                                  <option value="BOOLEAN" className="text-sm">
                                    Boolean
                                  </option>
                                </select>
                                <button
                                  disabled={!newCol}
                                  onClick={() => handleAddNewColumn()}
                                  className="px-4 py-1 m-1 text-sm font-bold text-white rounded-md bg-[#105597] disabled:opacity-40 disabled:cursor-not-allowed"
                                >
                                  Add
                                </button>
                              </div>
                            ) : (
                              ""
                            )}

                            {component?.properties?.columns
                              .concat(deselectedColumns)
                              .map((item, index) => (
                                // <div
                                //   className={classNames(
                                //     "hover:bg-primarylightgrey",
                                //     component?.properties?.columns.length - 1 &&
                                //     "border-b"
                                //   )}
                                // >
                                //   <CheckboxSelector
                                //     header={item}
                                //     isChecked={
                                //       selectedColumn.includes(item) ? true : false
                                //     }
                                //     handleChange={(_e) =>
                                //       handleColumnNameFilter(item)
                                //     }
                                //     isBold={false}
                                //   />

                                // </div>

                                <div
                                  className={classNames(
                                    "hover:bg-primarylightgrey",
                                    component?.properties?.columns.length - 1 &&
                                    "border-b",
                                    "d-flex px-2 py-2 items-center gap-2"
                                  )}
                                >
                                  <label className="flex flex-row items-center gap-2 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className="w-4 h-4 checked:lightblue"
                                      checked={
                                        selectedColumn.includes(item) ? true : false
                                      }
                                      onChange={() => handleColumnNameFilter(item)}
                                    />
                                    <p
                                      className={classNames(
                                        "m-0 cursor-pointer text-sm font-base"
                                      )}
                                    >
                                      {item}
                                    </p>
                                  </label>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </span>
                )}
                {selectedTemplate === null && (
                  <div className="d-flex justify-center">
                    <button
                      onClick={() => setIsAddNewColumnOpen(true)}
                      className="px-3 py-2  text-sm font-bold text-white rounded-md bg-[#105597] disabled:opacity-40 disabled:cursor-not-allowed properties-button"
                    >
                      Add New Column
                    </button>
                  </div>
                )}
                <div className="w-full">
                  {component?.properties?.columnProperties
                    ?.filter((item) => selectedColumn.includes(item.name))
                    .map((item, index) => (
                      <SidebarSection
                        title={item.name}
                        subSection={true}
                        isOpen={openItem === index}
                        onToggle={() => handleItemToggle(index)}
                      >
                        {selectedTemplate == null ? (
                          <>
                            <div className="flex justify-center py-2 space-x-3 pt-3 sm:ml-[50px] lg:ml-0">
                              <p className="m-0 prop-label whitespace-nowrap  self-center">
                                Name:
                              </p>
                              <input
                                type="text"
                                className="block p-1 text-xs text-gray-900 bg-white border-custome rounded sm:w-36 lg:w-fit focus:ring-blue-500 focus:border-blue-500"
                                value={item.name || ""}
                                onChange={(e) => {
                                  //  handleColumnPropertyChange(item?.name, { name: e.target.value })
                                  handleHeaderChange(item.name, e.target.value);
                                }}
                              />
                            </div>
                            <div className="d-flex justify-center py-2 lg:mr-[90px] sm:mr-2">
                              <div
                                className="d-flex"
                                style={{
                                  opacity: item?.isIncoming === true ? "0.5" : "1",
                                }}
                              >
                                <p className="m-0  whitespace-nowrap prop-label self-center pr-3">
                                  Type:
                                </p>
                                <Tooltip
                                  title={
                                    item?.isIncoming
                                      ? "You cannot change the type of the column"
                                      : ""
                                  }
                                  placement="top"
                                >
                                  <select
                                    value={item?.type}
                                    onChange={(e) => {
                                      handleColumnPropertyChange(item.name, {
                                        type: e.target.value,
                                      });
                                    }}
                                    disabled={item?.isIncoming}
                                    className="block p-1 text-gray-900 border-custome rounded text-xs  focus:ring-blue-500 focus:border-blue-500 outline-1 "
                                  >
                                    <option value="VARCHAR">Text</option>
                                    <option value="INTEGER">Number</option>
                                    <option value="FLOAT">Float</option>
                                    <option value="DATE">Date</option>
                                    <option value="BOOLEAN">Boolean</option>
                                  </select>
                                </Tooltip>
                              </div>
                            </div>
                          </>
                        ) : (
                          <span className="flex lg:flex-row sm:flex-col justify-center py-3 space-x-3">
                            <div className="flex items-center space-x-2 sm:justify-center">
                              <p className="m-0 prop-label whitespace-nowrap self-center">
                                Name:
                              </p>
                              <input
                                type="text"
                                className="block p-1 text-xs text-gray-900 bg-white w-44 border-custome rounded focus:ring-blue-500 focus:border-blue-500"
                                value={item.name || ""}
                                onChange={(e) => {
                                  handleHeaderChange(item.name, e.target.value);
                                }}
                              />
                            </div>
                            <div className="flex items-center space-x-2 sm:justify-center sm:mt-3 lg:mt-0 sm:pr-[102px] lg:pr-0">
                              <p className="m-0 whitespace-nowrap prop-label self-center">
                                Type:
                              </p>
                              <Tooltip
                                title={
                                  item?.isIncoming
                                    ? "You cannot change the type of the column"
                                    : ""
                                }
                                placement="top"
                              >
                                <select
                                  value={item?.type}
                                  onChange={(e) => {
                                    handleColumnPropertyChange(item.name, {
                                      type: e.target.value,
                                    });
                                  }}
                                  disabled={item?.isIncoming}
                                  className="block p-1 text-gray-900 border-custome rounded text-xs focus:ring-blue-500 focus:border-blue-500 outline-1"
                                >
                                  <option value="VARCHAR">Text</option>
                                  <option value="INTEGER">Number</option>
                                  <option value="FLOAT">Float</option>
                                  <option value="DATE">Date</option>
                                  <option value="BOOLEAN">Boolean</option>
                                </select>
                              </Tooltip>
                            </div>
                          </span>
                        )}

                        {selectedTemplate === null && (
                          <>
                            <div className="d-flex justify-center py-2 space-x-3 lg:mr-[70px] sm:ml-3 lg:ml-0">
                              <p className="m-0  whitespace-nowrap prop-label self-center">
                                Align:
                              </p>
                              <select
                                value={
                                  item?.align
                                    ? item?.align
                                    : component?.properties?.align
                                }
                                onChange={(e) => {
                                  handleColumnPropertyChange(item.name, {
                                    align: e.target.value,
                                  });
                                }}
                                className="block  p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                              >
                                <option value={-1}>Select Align</option>
                                {["Center", "Left", "Right"].map((item, index) => (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="d-flex justify-center py-2 space-x-3 lg:mr-[198px] sm:mr-28">
                              <p className="m-0  whitespace-nowrap prop-label self-center">
                                Background Color:
                              </p>
                              <div className="ml-1">
                                <div
                                  className="relative"
                                  ref={(el) =>
                                  (colorPickerRefs.current[item.name] = {
                                    ...(colorPickerRefs.current[item.name] || {}),
                                    backgroundColor: el,
                                  })
                                  }
                                >
                                  <div className="flex items-center border-custome ">
                                    <div
                                      className="w-6 h-6 sketch-border"
                                      style={{
                                        backgroundColor:
                                          item?.backgroundColor ||
                                          component?.properties?.backgroundColor,
                                      }}
                                    />
                                    <div
                                      className="w-5 h-6 text-center cursor-pointer"
                                      onClick={() => {
                                        handleColorPickerToggle(
                                          item.name,
                                          "backgroundColor"
                                        );
                                      }}
                                    >
                                      <KeyboardArrowDownIcon
                                        style={{ fontSize: "20px" }}
                                      />
                                    </div>
                                  </div>
                                  {colorPickerState[item.name]?.backgroundColor && (
                                    <div
                                      className="absolute z-10 bottom-0 mb-[32px]  cursor-pointer"
                                      ref={(el) =>
                                      (colorPickerRefs.current[item.name] = {
                                        ...(colorPickerRefs.current[item.name] ||
                                          {}),
                                        backgroundColor: el,
                                      })
                                      }
                                    >
                                      <SketchPicker
                                        color={
                                          item.backgroundColor ||
                                          component?.properties?.backgroundColor
                                        }
                                        onChange={(color) => {
                                          const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                          handleColumnPropertyChange(item.name, {
                                            backgroundColor: rgbaColor,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="flex lg:flex-row sm:flex-col justify-center items-center space-x-3">
                              <span className="flex  py-2 space-x-3 lg:ml-[30px] sm:ml-14">
                                <p className="m-0  whitespace-nowrap prop-label self-center">
                                  Font:
                                </p>
                                <select
                                  value={
                                    item?.fontFamily
                                      ? item?.fontFamily
                                      : component?.properties?.fontFamily
                                  }
                                  onChange={(e) => {
                                    handleColumnPropertyChange(item.name, {
                                      fontFamily: e.target.value,
                                    });
                                  }}
                                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded cursor-pointer focus:ring-blue-500 focus:border-blue-500"
                                >
                                  <option value={"Inter"}>
                                    Select Font Family
                                  </option>
                                  {Fonts.map((item, index) => (
                                    <option key={index} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </span>
                              <div
                                className="relative flex sm:flex-row sm:space-x-3 lg:space-x-0 sm:py-2 sm:pr-20 lg:pr-0"
                                ref={(el) =>
                                (colorPickerRefs.current[item.name] = {
                                  ...(colorPickerRefs.current[item.name] || {}),
                                  textColor: el,
                                })
                                }
                              >
                                <p className="m-0  whitespace-nowrap prop-label self-center sm:block lg:hidden">
                                  Font Color:
                                </p>
                                <span className="flex items-center border-custome">
                                  <div
                                    className="w-6 h-6 sketch-border"
                                    style={{
                                      backgroundColor:
                                        item.textColor ||
                                        component?.properties?.textColor,
                                    }}
                                  />

                                  <div
                                    className="w-5 h-6 text-center cursor-pointer"
                                    onClick={() => {
                                      handleColorPickerToggle(
                                        item.name,
                                        "textColor"
                                      );
                                    }}
                                  >
                                    <KeyboardArrowDownIcon
                                      style={{ fontSize: "20px" }}
                                    />
                                  </div>
                                </span>
                                {colorPickerState[item.name]?.textColor && (
                                  <div className="absolute z-10 bottom-0 right-0 mb-[38px] cursor-pointer">
                                    <SketchPicker
                                      color={
                                        item.textColor ||
                                        component?.properties?.textColor
                                      }
                                      onChange={(color) => {
                                        const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                        handleColumnPropertyChange(item.name, {
                                          textColor: rgbaColor,
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="d-flex justify-center py-2 space-x-3 lg:mr-[103px] sm:mr-4">
                              <p className="block  m-0 prop-label whitespace-nowrap  self-center">
                                Font Size:
                              </p>
                              <input
                                type="number"
                                className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                                value={
                                  item?.fontSize
                                    ? item?.fontSize
                                    : component?.properties?.fontSize
                                }
                                onChange={(e) => {
                                  let newValue = e.target.value;
                                  if (newValue !== "") {
                                    newValue = newValue.replace(/^0+/, "") || "0";
                                  }
                                  if (parseInt(newValue) < 0) {
                                    newValue = "0";
                                  }
                                  handleColumnPropertyChange(item.name, {
                                    fontSize: newValue,
                                  });
                                }}
                              />
                            </div>
                            <div className="d-flex justify-center pt-2 pb-2 space-x-3 lg:mr-[70px] lg:ml-0 sm:ml-4">
                              <p className="m-0 prop-label whitespace-nowrap  self-center">
                                Font Weight:
                              </p>
                              <select
                                value={
                                  item?.fontWeight
                                    ? item?.fontWeight
                                    : component?.properties?.fontWeight
                                }
                                onChange={(e) => {
                                  handleColumnPropertyChange(item.name, {
                                    fontWeight: e.target.value,
                                  });
                                }}
                                className="block p-1 text-xs text-gray-900 bg-white border-custome rounded cursor-pointer focus:ring-blue-500 focus:border-blue-500"
                              >
                                <option value={"Inter"}>Select Font Weight</option>
                                {fontWeights.map((item, index) => (
                                  <option key={index} value={item.value}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {item.isComputedColumn && (
                              <div className="d-flex justify-center pt-2 pb-3 space-x-3 lg:ml-[60px]  sm:ml-[52px]">
                                <p className="m-0 prop-label whitespace-nowrap  self-center">
                                  Formula:
                                </p>
                                <Tooltip title={item.expression || ""}>
                                  <input
                                    type="text"
                                    className="block p-1 text-xs text-gray-900 bg-white w-full lg:w-52 sm:w-28 border-custome rounded focus:ring-blue-500 focus:border-blue-500"
                                    value={item.expression || ""}
                                    onChange={(e) => {
                                      handleHeaderChange(item.name, e.target.value);
                                    }}
                                    disabled={!isEditing}
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  />
                                </Tooltip>
                                <span className="report-builder-nav-icon">
                                  <Tooltip title={"Edit"}>
                                    <DriveFileRenameOutlineIcon
                                      className="mx-1"
                                      onClick={() =>
                                        handleEdit(item.expression, item.name)
                                      }
                                    />
                                  </Tooltip>
                                </span>
                              </div>
                            )}
                          </>
                        )}
                      </SidebarSection>
                    ))}
                </div>
              </span>
            </SidebarSection>
          )
        }
        {selectedTemplate == null && component?.properties?.tableView !== 'Pivot Table' && (
          <SidebarSection
            title="Advance"
            subSection={false}
            isOpen={openSection === "advance"}
            onToggle={() => handleToggle("advance")}
          >
            <span className="flex justify-center  pb-2 pt-3 ">
              <p className="m-0  whitespace-nowrap prop-label self-end mb-2 pr-2">
                Indent (pt):
              </p>
              <span className="mx-2">
                <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600 ">
                  Left
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded lg:w-[90px] sm:w-16  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.paddingLeft || 0}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue !== "") {
                      newValue = newValue.replace(/^0+/, "") || "0";
                    }
                    if (parseInt(newValue) < 0) {
                      newValue = "0";
                    }
                    onPropertyChange({ paddingLeft: Number(newValue) });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        paddingLeft: Number(newValue),
                      },
                    }));
                  }}
                />
              </span>
              <span className="mx-2">
                <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600">
                  Right
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded lg:w-[90px] sm:w-16  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.paddingRight || 0}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue !== "") {
                      newValue = newValue.replace(/^0+/, "") || "0";
                    }
                    if (parseInt(newValue) < 0) {
                      newValue = "0";
                    }
                    onPropertyChange({ paddingRight: Number(newValue) });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        paddingRight: Number(newValue),
                      },
                    }));
                  }}
                />
              </span>
            </span>
            <span className="flex justify-center pb-2 pt-1 mr-2 ">
              <p className="m-0 prop-label whitespace-nowrap  self-end mb-2 pr-2">
                Spacing (pt):
              </p>
              <span className="mx-2">
                <p className="m-0 font-medium text-tiny text-gray-600 whitespace-nowrap">
                  Before
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded lg:w-[90px] sm:w-16  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.paddingTop || 0}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue !== "") {
                      newValue = newValue.replace(/^0+/, "") || "0";
                    }
                    if (parseInt(newValue) < 0) {
                      newValue = "0";
                    }
                    onPropertyChange({ paddingTop: Number(newValue) });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        paddingTop: Number(newValue),
                      },
                    }));
                  }}
                />
              </span>
              <span className="mx-2">
                <p className="m-0 font-medium text-tiny text-gray-600 whitespace-nowrap">
                  After
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded lg:w-[90px] sm:w-16  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.paddingBottom || 0}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue !== "") {
                      newValue = newValue.replace(/^0+/, "") || "0";
                    }
                    if (parseInt(newValue) < 0) {
                      newValue = "0";
                    }
                    onPropertyChange({ paddingBottom: Number(newValue) });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        paddingBottom: Number(newValue),
                      },
                    }));
                  }}
                />
              </span>
            </span>
            <div className="d-flex justify-center py-2 pb-3 space-x-4 lg:mr-[115px] sm:mr-16">
              <p className="m-0 prop-label whitespace-nowrap  self-center">
                Vertical Align:
              </p>
              <select
                value={component?.properties?.verticalAlign}
                onChange={(e) => {
                  onPropertyChange({ verticalAlign: e.target.value });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      verticalAlign: e.target.value,
                    },
                  }));
                  setFlag(false);
                }}
                className="block p-1 text-xs text-gray-900 bg-white border-custome rounded cursor-pointer focus:ring-blue-500 focus:border-blue-500"
              >
                <option value={-1}>Select Align</option>
                {["Middle", "Top", "Bottom"].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </SidebarSection>
        )}
      </DndProvider>
    </>
  );
}


export default TableProperties;
