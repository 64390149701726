import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loggedInRoutes } from "../routes/routesOptions";
import { selectUser } from "../features/auth/authSlice";
import { useSelector } from "react-redux";

const usePermissions = () => {
  const user = useSelector(selectUser);
  const location = useLocation();
  const navigate = useNavigate();
  const getIsAccessible = () => {
    const currentPath = loggedInRoutes.find(
      ({ path }) => path === location.pathname
    );
    if (currentPath) {
      console.log(currentPath, "currentPath");
      if (currentPath?.access?.includes("*")) return true;
      const role = currentPath?.access?.find(
        (role) => role === user?.type?.toString()
      );
      if (
        user?.organisation?.data?.isEnterprise &&
        currentPath?.isEnterprise === false
      ) {
        return false;
      }
      return !(role === undefined);
    }
    return false;
  };
  useEffect(() => {
    const isAccessible = getIsAccessible();
    if (!isAccessible) {
      if (!user) {
        return navigate("/signin", { state: { from: location } });
      }
      return navigate("/home", { state: { from: location } });
    }
  }, [location]);

  return null;
};

export default usePermissions;
