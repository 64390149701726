import { Avatar } from "@mui/material";
import React from "react";

const backOfficeRoles = {
  1: "Admin",
  2: "Scheduler",
  3: "Executive",
  4: "Creator",
};

const BackOfficeUser = ({
  user,
  isEdit,
  userData,
  setUserData,
  onAddMedia,
}) => {
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  return (
    <div className="flex flex-col gap-12">
      <div className="d-flex align-items-center">
        <Avatar
          className="my-3 acc-avatar"
          alt={`${user.details.name?.split(" ")[0] || ""} ${
            user.details.name?.split(" ")[1] || ""
          }`}
          src={user?.img}
        >{`${user.details.name?.split(" ")[0][0] || ""} ${
          user.details.name?.split(" ")[1][0] || ""
        }`}</Avatar>
        <span
          className="mx-3 acc-change-profile text-primaryblue"
          role="button"
          onClick={onAddMedia}
        >
          Change profile picture
        </span>
      </div>
      {/* </div> */}
      <div className="flex flex-col gap-8">
        <div className="flex flex-row gap-6 items-center">
          <p className="m-0 font-bold w-16">Name</p>
          {isEdit ? (
            <input
              type="text"
              id="name"
              name="name"
              className="p-2 border-[1px] border-primarygray rounded-md outline-none"
              placeholder="Enter Name"
              value={userData.details.name}
              onChange={(e) => {
                setUserData((prev) => ({
                  ...prev,
                  details: { ...prev.details, name: e.target.value },
                }));
              }}
            />
          ) : (
            <p className="m-0 text-primarytextgrey">{user.details.name}</p>
          )}
        </div>
        <div className="flex flex-row gap-6 items-center">
          <p className="m-0 font-bold w-16">Email</p>
          <p className="m-0 text-primarytextgrey">{user.email}</p>
        </div>
        <div className="flex flex-row gap-6 items-center">
          <p className="m-0 font-bold w-16">Phone</p>
          {isEdit ? (
            <input
              type="tel"
              id="tel"
              name="tel"
              className="p-2 border-[1px] border-primarygray rounded-md outline-none"
              placeholder="xxx-xxx-xxxx"
              value={userData.phone}
              onChange={(e) => {
                if (e.target.value.length >= 13) {
                  return;
                }
                setUserData((prev) => ({
                  ...prev,
                  phone: formatPhoneNumber(e.target.value),
                }));
              }}
            />
          ) : (
            <p className="m-0 text-primarytextgrey">
              {formatPhoneNumber(user.phone)}
            </p>
          )}
        </div>
        <div className="flex flex-row gap-6 items-center">
          <p className="m-0 font-bold w-16">Role</p>
          <p className="m-0 text-primarytextgrey">
            {backOfficeRoles[user.details.role]}
          </p>
        </div>
      </div>
      <div className="w-full h-[1px] bg-primarygrayborder" />
      <div className="flex flex-row gap-6">
        <div className="flex flex-col gap-8 font-bold">
          <p className="m-0">Organization</p>
        </div>
        <div className="flex flex-col gap-8 text-primarytextgrey">
          <p className="m-0">{user.organisation.name}</p>
        </div>
      </div>
    </div>
  );
};

export default BackOfficeUser;
