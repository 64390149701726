import React from "react";
import classNames from "classnames";
import ForgotSteps from "./forgot-steps";

const Forgot = () => {
  return (
    <div
      className={classNames("relative flex flex-row h-screen overflow-hidden")}
    >
      <ForgotSteps />
    </div>
  );
};

export default Forgot;
