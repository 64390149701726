import React, { useEffect, useState } from "react";
import ProviderUserCreateModal from "./components/ProviderUserCreateModal";
import BackOfficeUserCreateModal from "./components/BackOfficeUserCreateModal";
import UsersTable from "./components/UsersTable";
import CloseSvg from "../../assets/svgs/CloseSvg";
import {
  useCreateBackOfficeUserMutation,
  useCreateProviderUserMutation,
  useGetAllUserMutation,
} from "../../features/user/userApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import classNames from "classnames";

const UserManagement = () => {
  const [opaque, setOpaque] = useState(false);
  const [users, setUsers] = useState([]);
  const [addBackOfficeUserModalOpen, setAddBackOfficeUserModalOpen] =
    useState(false);
  const [addProviderUserModalOpen, setAddProviderUserModalOpen] =
    useState(false);

  const [getAllUser] = useGetAllUserMutation();
  const [createProviderUser] = useCreateProviderUserMutation();
  const [createBackOfficeUser] = useCreateBackOfficeUserMutation();

  const user = useSelector(selectUser);

  const fetchUsers = async () => {
    try {
      const { data } = await getAllUser().unwrap();

      setUsers(data);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const saveBackOfficeUser = async (
    email,
    phone,
    firstName,
    lastName,
    role
  ) => {
    const { data } = await createBackOfficeUser({
      userData: {
        type: 1,
        email,
        phone,
      },
      staffData: {
        organisation_id: user.organisation.id,
        firstname: firstName,
        lastname: lastName,
        role,
      },
    }).unwrap();

    const usersList = [data, ...users];
    setUsers(usersList);
  };

  const saveProviderUser = async (email, phone, providerId) => {
    const { data } = await createProviderUser({
      userData: {
        type: 3,
        email,
        phone,
      },
      providerId: +providerId,
    }).unwrap();

    const usersList = [data, ...users];
    setUsers(usersList);
  };

  const handleAddBackOfficeUserModal = () => {
    setAddBackOfficeUserModalOpen(!addBackOfficeUserModalOpen);
    setOpaque(!opaque);
  };

  const handleProviderUserModal = () => {
    setAddProviderUserModalOpen(!addProviderUserModalOpen);
    setOpaque(!opaque);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="relative flex flex-col w-full h-[calc(100vh-110px)]">
      <div className="max-h-screen p-4 overflow-hidden">
        <div className="flex flex-col w-full gap-2 mb-4">
          <div className="flex flex-col items-center justify-between md:flex-row">
            <p className="m-0 text-lg font-bold">
              You have added {users.length} members
            </p>
            <div
              className={classNames(
                user?.type === 1 && user?.details?.role === 4
                  ? "flex flex-row mt-2 md:mt-0 gap-6"
                  : "hidden"
              )}
            >
              {/* <button
                onClick={handleProviderUserModal}
                className="flex flex-row items-center gap-2 p-2 text-white rounded-md bg-primaryblue"
              >
                <p className="m-0 text-base">Register Provider</p>
              </button> */}
              <button
                onClick={handleAddBackOfficeUserModal}
                className="flex flex-row items-center gap-2 p-2 text-white rounded-md bg-primaryblue"
              >
                <p className="m-0 text-base">Add User</p>
              </button>
            </div>
          </div>
        </div>
        <UsersTable users={users} />
      </div>
      {addProviderUserModalOpen && (
        <ProviderUserCreateModal
          handleProviderUserModal={handleProviderUserModal}
          saveProviderUser={saveProviderUser}
        />
      )}
      {addBackOfficeUserModalOpen && (
        <BackOfficeUserCreateModal
          handleAddBackOfficeUserModal={handleAddBackOfficeUserModal}
          saveBackOfficeUser={saveBackOfficeUser}
        />
      )}
    </div>
  );
};

export default UserManagement;
