import React from "react";
import classNames from "classnames";
import { Search_Icon } from "../../../assets/images";
import {
  HouseOutlined,
  LocationOnOutlined,
  MonitorHeartOutlined,
  NoteSharp,
  PeopleSharp,
} from "@mui/icons-material";
import moment from "moment/moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreatePOCMutation,
  useDeletePOCMutation,
  useFetchPatientByIdMutation,
  useFetchPatientCareteamMutation,
  useFetchPatientPOCMembersMutation,
  useUpdatePatientNotesMutation,
  useUpdatePOCMutation,
  // useFetchPatientHouseholdMembersMutation,
} from "../../../features/patient/patientApiSlice";
import ReusableModal from "../../../components/Modals/ReusableModal";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import IconComponent from "../../../components/DataTable/Icon";
import POCDeleteModal from "./components/POCDeleteModal";
import POCModal from "./components/POCModal";
import Loading from "../../../components/Loading";
import { Tooltip } from "@mui/material";
import Search from "../../../components/Search/SearchNew";

const PatientInfo = () => {
  // const [patients, setPatients] = React.useState([]);
  const [careteam, setCareteam] = React.useState([]);
  const [patient, setPatient] = React.useState(null);
  const [poc, setPOC] = React.useState([]);
  const [addPOCOpen, setAddPOCOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(null);
  const [del, setDel] = React.useState(null);

  const [pocFirstName, setPocFirstName] = React.useState("");
  const [pocLastName, setPocLastName] = React.useState("");
  const [pocStreet, setPocStreet] = React.useState("");
  const [pocCity, setPocCity] = React.useState("");
  const [pocState, setPocState] = React.useState("");
  const [pocZip, setPocZip] = React.useState("");
  const [pocPhone, setPocPhone] = React.useState("");
  const [pocSecondaryPhone, setPocSecondaryPhone] = React.useState("");
  const [pocEmail, setPocEmail] = React.useState("");
  const [pocCategory, setPocCategory] = React.useState("");
  const [pocType, setPocType] = React.useState("");

  const [searchFilter, setSearchFilter] = React.useState("");

  const [tab, setTab] = React.useState("care-team");
  const [isEditNote, setIsEditNote] = React.useState(false);
  const [note, setNote] = React.useState("");

  const { id } = useParams();

  const navigate = useNavigate();

  const handlePatientClick = (patient) => {
    navigate(`/care-management/patients/${patient.id}`);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleProviderClick = (provider) => {
    navigate(`/care-management/providers/${provider.id}`);
  };

  const handleTabChange = (newTab) => {
    if (newTab !== tab) {
      setTab(newTab);
    }
  };

  // const [getPatients] = useFetchPatientHouseholdMembersMutation();
  const [getPatientCareteam, { isLoading: careLoading }] =
    useFetchPatientCareteamMutation();
  const [getPatientPOC, { isLoading: pocLoading }] =
    useFetchPatientPOCMembersMutation();

  const [getPatientById, { isLoading: patientLoading }] =
    useFetchPatientByIdMutation();

  const [createPOC, { isLoading: createLoading }] = useCreatePOCMutation();
  const [updatePOC, { isLoading: updateLoading }] = useUpdatePOCMutation();
  const [deletePOC, { isLoading: deleteLoading }] = useDeletePOCMutation();
  const [updatePatientNotes, { isLoading: updateNoteLoading }] =
    useUpdatePatientNotesMutation();

  const isLoading =
    careLoading ||
    pocLoading ||
    updateLoading ||
    createLoading ||
    deleteLoading ||
    patientLoading ||
    updateNoteLoading;

  // const fetchPatients = async () => {
  //   try {
  //     const { data } = await getPatients(patient.locationId).unwrap();

  //     setPatients(data);
  //   } catch (error) {
  //
  //     console.error(error.data.message);
  //   }
  // };

  const fetchPatient = async () => {
    try {
      const { data } = await getPatientById(id).unwrap();

      setPatient(data);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const fetchCareteam = async () => {
    try {
      const { data } = await getPatientCareteam(id).unwrap();

      setCareteam(data);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const fetchPatientPOC = async () => {
    try {
      const { data } = await getPatientPOC(id).unwrap();

      setPOC(data);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const savePOC = async () => {
    try {
      const { data } = await createPOC({
        patientId: id,
        firstname: pocFirstName,
        lastname: pocLastName,
        street: pocStreet,
        city: pocCity,
        state: pocState,
        zip: pocZip,
        phone: pocPhone,
        secondaryphone: pocSecondaryPhone,
        email: pocEmail,
        category: pocCategory,
        type: pocType,
      }).unwrap();

      const pocList = [data, ...poc];
      setPOC(pocList);
    } catch (error) {
      console.error(error?.data?.message);
    }
  };

  const onUpdatePOC = async () => {
    try {
      const { data } = await updatePOC({
        id: edit,
        firstname: pocFirstName,
        lastname: pocLastName,
        street: pocStreet,
        city: pocCity,
        state: pocState,
        zip: pocZip,
        phone: pocPhone,
        secondaryphone: pocSecondaryPhone,
        email: pocEmail,
        category: pocCategory,
        type: pocType,
      }).unwrap();

      setPOC((prev) => {
        const update = [...prev];
        const index = update.findIndex((x) => x.id === edit);
        update[index] = data;
        return [...update];
      });
    } catch (error) {
      console.error(error?.data?.message);
    }
  };

  const onDeletePOC = async () => {
    try {
      const { data } = await deletePOC({
        id: del,
      }).unwrap();

      setPOC((prev) => {
        const update = [...prev];
        const index = update.findIndex((x) => x.id === del);
        update[index] = {
          ...data,
          email: update[index]?.email,
          phone: update[index]?.phone,
        };
        return [...update];
      });
    } catch (error) {
      console.error(error?.data?.message);
    }
  };

  const updateNote = async () => {
    try {
      const { data } = await updatePatientNotes({
        patientId: id,
        notes: note,
      }).unwrap();

      setPatient({
        ...patient,
        notes: data.notes,
      });
    } catch (error) {
      console.error(error?.data?.message);
    }
  };

  const clearInput = () => {
    setPocFirstName("");
    setPocLastName("");
    setPocStreet("");
    setPocCity("");
    setPocState("");
    setPocZip("");
    setPocPhone("");
    setPocSecondaryPhone("");
    setPocEmail("");
    setPocCategory("");
    setPocType("");
    setEdit(null);
    setDel(null);
  };

  React.useEffect(() => {
    if (id) {
      fetchPatient();
      fetchCareteam();
      fetchPatientPOC();
    }
  }, [id]);

  // React.useEffect(() => {
  //   if (patient && patient.noOfMembers > 0) {
  //     fetchPatients();
  //   }
  // }, [patient]);

  const onEdit = (contact) => {
    const [firstname, lastname] = contact.name.split(" ");
    const [street, city, state, zip] = contact.address.split(", ");
    setPocFirstName(firstname);
    setPocLastName(lastname);
    setPocStreet(street);
    setPocCity(city);
    setPocState(state);
    setPocZip(zip);
    setPocPhone(contact?.phone);
    setPocSecondaryPhone(contact?.secondaryphone);
    setPocEmail(contact?.email);
    setPocCategory(contact?.category);
    setPocType(contact?.type);
    setEdit(contact.id);
    setAddPOCOpen(true);
  };

  const onDelete = (contact) => {
    const [firstname, lastname] = contact.name.split(" ");
    setPocFirstName(firstname);
    setPocLastName(lastname);
    setDel(contact.id);
  };

  const Avatar = ({
    name,
    image,
    size = "w-10 h-10",
    textSize = "text-base",
    fontWeight = "font-normal",
  }) => {
    return (
      <>
        {image !== null ? (
          <div className="flex items-center gap-2">
            <img
              src={image}
              alt={name}
              className={classNames(
                "object-cover w-10 h-10 rounded-full",
                size
              )}
            />
          </div>
        ) : (
          <p
            className={classNames(
              "flex items-center justify-center m-0 text-white rounded-full bg-primaryblue",
              size,
              textSize,
              fontWeight
            )}
          >
            {name
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </p>
        )}
      </>
    );
  };

  const header = {
    "care-team": "Care Team ",
    poc: "Point of Contact ",
    notes: "Notes",
  };

  const headerLength = {
    "care-team": careteam.length,
    poc: poc.length,
    notes: "",
  };

  return (
    <div className="flex flex-row items-start">
      <Loading loading={isLoading} />
      <div className="w-4/12 py-4 pl-16 pr-8">
        <div className="w-full overflow-x-auto overflow-y-auto h-[calc(100vh-128px)] bg-white rounded-lg shadow border-1 border-primarygrayborder">
          <button className="p-3 text-primaryblue" onClick={handleBack}>
            Back
          </button>
          <div className="flex flex-col items-center justify-end gap-2 p-4 border-b border-b-primarygrayborder">
            <div className="relative">
              <Avatar
                name={patient?.name ?? ""}
                image={null}
                size="w-20 h-20"
                textSize="text-3xl"
                fontWeight="font-semibold"
              />
              <div className="absolute right-0 w-4 h-4 rounded-full bottom-14 bg-primarygreen" />
            </div>
            {patient && (
              <p className="m-0 text-lg font-bold">{patient?.name}</p>
            )}
            <p className="m-0 text-sm text-primarytextgrey">
              EHR ID {patient?.patient_ehr_id} | {patient?.sex}
            </p>
            <p className="m-0 text-sm text-primarytextgrey">
              {patient?.locationName == null
                ? "Private Residence"
                : patient?.locationName}
            </p>
          </div>
          <div className="flex flex-col h-auto gap-4 p-4 border-b border-b-primarygrayborder">
            <div className="flex items-center gap-4">
              {patient && (
                <div className="flex items-center justify-center w-10 h-10 bg-transparent rounded-md">
                  <MonitorHeartOutlined
                    style={{
                      color: "#828282",
                    }}
                  />
                </div>
              )}
              {patient && (
                <p className="m-0">
                  {moment(patient?.dob).format("MMMM DD, YYYY")}
                </p>
              )}
            </div>
            {/* {patient && patient?.noOfMembers > 0 && (
              <div className="flex items-center gap-4">
                <div className="flex items-center justify-center w-10 h-10 bg-transparent rounded-md">
                  <HouseOutlined
                    style={{
                      color: "#828282",
                    }}
                  />
                </div>
                <p className="m-0">{patient?.noOfMembers} members</p>
              </div>
            )} */}
            <div className="flex items-start gap-4">
              {patient && (
                <div className="flex items-center justify-center w-10 h-10 bg-transparent rounded-md">
                  <LocationOnOutlined
                    style={{
                      color: "#828282",
                    }}
                  />
                </div>
              )}
              <p className="m-0">{patient?.locationAddress}</p>
            </div>
          </div>
          <div className="flex flex-col h-auto gap-4 p-4">
            <button
              onClick={() => handleTabChange("care-team")}
              className="flex items-center gap-4 cursor-pointer"
            >
              <div
                className={classNames(
                  "flex items-center justify-center w-10 h-10 rounded-md",
                  tab === "care-team" && "bg-subtleblue"
                )}
              >
                <PeopleSharp
                  style={{
                    color: tab === "care-team" ? "#3062D4" : "#000",
                    fontSize: "20px",
                  }}
                />
              </div>
              <p
                className={classNames(
                  "m-0 font-semibold",
                  tab === "care-team" && "text-primaryblue"
                )}
              >
                Care Team ({headerLength["care-team"]})
              </p>
            </button>
            <button
              onClick={() => handleTabChange("poc")}
              className="flex items-center gap-4 cursor-pointer"
            >
              <div
                className={classNames(
                  "flex items-center justify-center w-10 h-10 rounded-md",
                  tab === "poc" && "bg-subtleblue"
                )}
              >
                <PeopleSharp
                  style={{
                    color: tab === "poc" ? "#3062D4" : "#000",
                    fontSize: "20px",
                  }}
                />
              </div>
              <p
                className={classNames(
                  "m-0 font-semibold",
                  tab === "poc" && "text-primaryblue"
                )}
              >
                Point of Contact ({patient?.noOfPOC})
              </p>
            </button>
            <button
              onClick={() => handleTabChange("notes")}
              className="flex items-center gap-4 cursor-pointer"
            >
              <div
                className={classNames(
                  "flex items-center justify-center w-10 h-10 rounded-md",
                  tab === "notes" && "bg-subtleblue"
                )}
              >
                <NoteSharp
                  style={{
                    color: tab === "notes" ? "#3062D4" : "#000",
                    fontSize: "20px",
                  }}
                />
              </div>
              <p
                className={classNames(
                  "m-0 font-semibold",
                  tab === "notes" && "text-primaryblue"
                )}
              >
                Notes
              </p>
            </button>
            {/* {patient?.noOfMembers > 0 && (
              <button
                onClick={() => handleTabChange("household")}
                className="flex items-center gap-4 cursor-pointer"
              >
                <div
                  className={classNames(
                    "flex items-center justify-center w-10 h-10 rounded-md",
                    tab === "household" && "bg-subtleblue"
                  )}
                >
                  <PeopleSharp
                    style={{
                      color: tab === "household" ? "#3062D4" : "#000",
                      fontSize: "20px",
                    }}
                  />
                </div>
                <p
                  className={classNames(
                    "m-0 font-semibold",
                    tab === "household" && "text-primaryblue"
                  )}
                >
                  Household Members ({patient?.noOfMembers})
                </p>
              </button>
            )} */}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start w-8/12 py-4">
        <div className="flex flex-row items-center justify-between w-full pl-8 pr-16">
          <div className="flex flex-row items-center w-full gap-12">
            {/* <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
              <span className="ml-2 text-base font-bold text-primaryblue">
                {header[tab]}
                {headerLength[tab]}
              </span>
            </h1> */}
            {tab !== "notes" && (
              <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
                <Search value={searchFilter} setValue={setSearchFilter} />
              </div>
            )}
          </div>
          {tab === "poc" && (
            <button
              onClick={() => {
                setAddPOCOpen(true);
              }}
              className="px-4 py-2 shadow-sm rounded-md border-[2px] border-primarygrayborder whitespace-nowrap text-primaryblue"
            >
              Add New
            </button>
          )}
          {tab === "notes" && (
            <button
              onClick={() => {
                if (isEditNote) {
                  updateNote();
                }
                setIsEditNote(!isEditNote);
              }}
              className="px-4 py-2 shadow-sm rounded-md border-[2px] border-primarygrayborder whitespace-nowrap text-primaryblue"
            >
              {isEditNote ? "Save" : "Edit"}
            </button>
          )}
        </div>
        <div className="w-full pl-8 pr-16">
          <div
            className={classNames(
              "relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow  max-h-[calc(100vh-200px)]",
              tab === "notes" && "p-1"
            )}
          >
            {tab === "notes" && (
              <textarea
                disabled={!isEditNote}
                rows={5}
                placeholder="Enter note"
                className="w-full p-2"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            )}
            {tab === "care-team" ? (
              <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
                <thead>
                  <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                    <th className="px-4 py-3">
                      <p className="m-0">Name</p>
                    </th>
                    <th className="px-4 py-3">
                      <p className="m-0">Speciality</p>
                    </th>
                    <th className="px-4 py-3">NPI ID</th>
                    <th className="px-4 py-3">Phone No.</th>
                    <th className="px-4 py-3">Email</th>
                    <th className="px-4 py-3">
                      <p className="m-0">Status</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {careteam
                    .filter((provider) =>
                      provider.name
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
                    )
                    .map((provider, index) => (
                      <tr
                        className={classNames(
                          "text-left text-gray-900 h-24 whitespace-nowrap"
                        )}
                        key={index}
                      >
                        <td className="px-4 py-3 text-left">
                          <div className="flex flex-row items-center gap-4">
                            <button
                              onClick={() => handleProviderClick(provider)}
                              className="flex flex-row items-center gap-4"
                            >
                              <Avatar
                                name={provider.name}
                                image={provider.profileImage}
                              />
                              <p className="m-0 font-semibold underline">
                                {provider.name}
                              </p>
                            </button>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {provider.speciality}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {provider.npi_id}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {provider.phone}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {provider.email}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          <div
                            className={classNames(
                              provider.status.toUpperCase() === "ACTIVE"
                                ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                                : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                              "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                            )}
                          >
                            <div
                              className={classNames(
                                "w-2 h-2 rounded-full",
                                provider.status.toUpperCase() === "ACTIVE"
                                  ? "bg-primarygreen"
                                  : "bg-primarytextgrey"
                              )}
                            />
                            {provider.status}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : tab === "poc" ? (
              <table className="relative w-full min-h-28 whitespace-no-wrap bg-white border-collapse table-auto table-striped">
                <thead>
                  <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                    <th className="px-4 py-3">
                      <p className="m-0">Name</p>
                    </th>
                    <th className="px-4 py-3">
                      <p className="m-0">Category</p>
                    </th>
                    <th className="px-4 py-3">
                      <p className="m-0">Type</p>
                    </th>
                    <th className="px-4 py-3">
                      <p className="m-0">Email</p>
                    </th>
                    <th className="px-4 py-3">
                      <p className="m-0">Phone</p>
                    </th>
                    <th className="px-4 py-3">
                      <p className="m-0">Secondary Phone</p>
                    </th>
                    <th className="px-4 py-3">
                      <p className="m-0">Address</p>
                    </th>
                    <th className="px-4 py-3">
                      <p className="m-0">Status</p>
                    </th>
                    <th className="px-4 py-3">
                      <p className="m-0">Edit</p>
                    </th>
                    <th className="px-4 py-3">
                      <p className="m-0">Delete</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {poc
                    .filter((contact) =>
                      contact.name
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
                    )
                    .map((contact, index) => (
                      <tr
                        className={classNames(
                          "text-left text-gray-900 h-24 whitespace-nowrap"
                        )}
                        key={index}
                      >
                        <td className="px-4 py-3 text-left">
                          <div className="flex flex-row items-center gap-4">
                            <button className="flex flex-row items-center gap-4">
                              <Avatar name={contact.name} image={null} />
                              <p className="m-0 font-semibold underline">
                                {contact.name}
                              </p>
                            </button>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {contact.category}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {contact.type}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {contact.email}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {contact.phone}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {contact.secondaryphone}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {contact.address}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          <div
                            className={classNames(
                              contact.status.toUpperCase() === "ACTIVE"
                                ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                                : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                              "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                            )}
                          >
                            <div
                              className={classNames(
                                "w-2 h-2 rounded-full",
                                contact.status.toUpperCase() === "ACTIVE"
                                  ? "bg-primarygreen"
                                  : "bg-primarytextgrey"
                              )}
                            />
                            {contact.status}
                          </div>
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          <IconComponent
                            title="Edit Contact"
                            icon={"Edit_Icon"}
                            i={contact}
                            onClick={() => onEdit(contact)}
                          />
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          <IconComponent
                            title="Delete Contact"
                            icon={"Delete_Icon"}
                            i={contact}
                            onClick={() => onDelete(contact)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : null}
          </div>
        </div>
      </div>
      {/* max-h-[calc(100vh-200px)] */}
      <POCModal
        addPOCOpen={addPOCOpen}
        edit={edit}
        clearInput={clearInput}
        pocFirstName={pocFirstName}
        setAddPOCOpen={setAddPOCOpen}
        setPocFirstName={setPocFirstName}
        pocLastName={pocLastName}
        setPocLastName={setPocLastName}
        pocStreet={pocStreet}
        setPocStreet={setPocStreet}
        pocCity={pocCity}
        setPocCity={setPocCity}
        pocState={pocState}
        setPocState={setPocState}
        pocZip={pocZip}
        setPocZip={setPocZip}
        pocEmail={pocEmail}
        setPocEmail={setPocEmail}
        pocPhone={pocPhone}
        setPocPhone={setPocPhone}
        pocSecondaryPhone={pocSecondaryPhone}
        setPocSecondaryPhone={setPocSecondaryPhone}
        pocCategory={pocCategory}
        setPocCategory={setPocCategory}
        pocType={pocType}
        setPocType={setPocType}
        onUpdatePOC={onUpdatePOC}
        savePOC={savePOC}
      />
      <POCDeleteModal
        del={del}
        clearInput={clearInput}
        pocFirstName={pocFirstName}
        onDeletePOC={onDeletePOC}
      />
    </div>
  );
};

export default PatientInfo;
