import "../../../Reports/reportStyles.css";

import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import dayjs from "dayjs";

function OverduePatientsReportFilter({
    getOverduePatientsReports,
    dateRange,
    setDateRange,
    dateRangeValue,
    setDateRangeValue,
    setClear,
    handleClear,
    isSelecetdValue
}) {
    const today = dayjs();
    const handleChange = (event, newValue) => {
        setClear(true);
        event.preventDefault();
        setDateRangeValue(newValue);
        if (newValue === "THIS_MONTH") {
            setDateRange([today.startOf("month"), today]);
        } else if (newValue === "LAST_MONTH") {
            setDateRange([
                today.subtract(1, "month").startOf("month"),
                today.subtract(1, "month").endOf("month"),
            ]);
        } else if (newValue === "YTD") {
            setDateRange([today.startOf("year"), today]);
        }
        getOverduePatientsReports({ dateRangeValue: newValue, setHeaders: true });
    };
    const handleSearch = () => {
        setClear(true);
        getOverduePatientsReports({ setHeaders: true, dateRange: dateRange });
    };
    return (
        <div className="col-12 flex flex-row w-full align-items-center flex-wrap">
            <div className="col-lg-8 col-12 flex flex-row flex-wrap mb-1">
                <div className="d-flex flex-row align-items-center mx-2 mb-1 mb-sm-0">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateRangePicker"]}>
                            <DateRangePicker
                                localeText={{ start: "From", end: "To" }}
                                calendars={2}
                                value={dateRange}
                                onChange={(newValue) => {
                                    setDateRange(newValue);
                                    setDateRangeValue("");
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className="mx-1  mt-1 self-center">
                    <button
                        disabled={dayjs(dateRange[0]).isValid() || dayjs(dateRange[1]).isValid() ? false : true}
                        variant="contained"
                        className="py-2 px-2 text-white font-bold bg-primaryblue rounded-lg disabled:opacity-60 hover:bg-blue-700 text-sm"
                        onClick={handleSearch}
                        style={{ textTransform: "capitalize" }}
                    >
                        Search
                    </button>
                </div>
                <div className=" mx-1  mt-1 self-center">
                    <button
                        variant="contained"
                        disabled={!isSelecetdValue && !dayjs(dateRange[0]).isValid() && !dayjs(dateRange[1]).isValid()}
                        className="py-2 px-2 text-white font-bold bg-primaryblue rounded-lg disabled:opacity-60 hover:bg-blue-700 text-sm"
                        onClick={() => {
                            handleClear();
                        }}
                        style={{ textTransform: "capitalize" }}
                    >
                        Clear
                    </button>
                </div>
            </div>
            <div className="d-flex justify-content-end align-items-center mb-1 mr-0 ml-md-auto col-lg-4 col-12">
                <Tabs
                    value={dateRangeValue}
                    // value='MTD'
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="white"
                    aria-label="secondary tabs example"
                >
                    <Tab label="This Month" value="THIS_MONTH" />
                    <Tab label="Last Month" value="LAST_MONTH" />
                    <Tab label="Year to Date" value="YTD" />
                </Tabs>
            </div>
        </div>
    )
}

export default OverduePatientsReportFilter;