import React, { useEffect } from "react";
import { Alert, AlertTitle, Collapse } from "@mui/material";
import { useLSStore } from "../../context/ls-store";

function AutoHideAlert() {
  const alert = useLSStore((state) => state.alert);
  const setAlert = useLSStore((state) => state.setAlert);

  useEffect(() => {
    const timer = setTimeout(onClose, 3000); // 3 seconds timeout

    // Cleanup the timer when component unmounts
    return () => clearTimeout(timer);
  }, [alert]);

  const onClose = () => {
    setAlert(null);
  };

  return (
    <div className="z-50 w-full px-3">
      <Collapse in={!!alert}>
        {!!alert && (
          <Alert
            sx={{ mb: 2 }}
            onClose={onClose}
            severity={alert === null ? "success" : alert.severity}
          >
            <AlertTitle className="text-capitalize">
              {alert === null ? "success" : alert.severity}
            </AlertTitle>
            <span className="mx-[10px]">{alert && alert.message}</span>
          </Alert>
        )}
      </Collapse>
    </div>
  );
}

export default AutoHideAlert;
