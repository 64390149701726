import { update } from "react-spring";
import { apiSlice } from "../../app/api/apiSlice";

export const patientApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchPatientById: builder.mutation({
      query: (credentials) => ({
        url: `/patient/${credentials}`,
        method: "GET",
      }),
    }),
    fetchPatientCareteam: builder.mutation({
      query: (credentials) => ({
        url: `/patient/careteam/${credentials}`,
        method: "GET",
      }),
    }),
    fetchPatientHouseholdMembers: builder.mutation({
      query: (credentials) => ({
        url: `/patient/household-members/${credentials}`,
        method: "GET",
      }),
    }),
    fetchPatientPOCMembers: builder.mutation({
      query: (credentials) => ({
        url: `/patient/poc-members/${credentials}`,
        method: "GET",
      }),
    }),
    createPOC: builder.mutation({
      query: (credentials) => ({
        url: `/patient/poc-members/${credentials.patientId}`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    updatePOC: builder.mutation({
      query: ({ id, ...credentials }) => ({
        url: `/patient/poc-members/${id}`,
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    deletePOC: builder.mutation({
      query: ({ id }) => ({
        url: `/patient/poc-members/${id}`,
        method: "DELETE",
      }),
    }),
    getNewPatients: builder.mutation({
      query: (credentials) => ({
        url: `/patient/new?organisationId=${credentials.organisationId}`,
        method: "GET",
      }),
    }),
    updatePatientNotes: builder.mutation({
      query: (credentials) => ({
        url: `/patient/notes/${credentials.patientId}`,
        method: "PATCH",
        body: credentials,
      }),
    }),
  }),
});

export const {
  useFetchPatientByIdMutation,
  useFetchPatientCareteamMutation,
  useFetchPatientHouseholdMembersMutation,
  useFetchPatientPOCMembersMutation,
  useGetNewPatientsMutation,
  useCreatePOCMutation,
  useUpdatePOCMutation,
  useDeletePOCMutation,
  useUpdatePatientNotesMutation,
} = patientApiSlice;
