import React from "react";
import IconComponent from "../../../components/DataTable/Icon";
import classNames from "classnames";
import { useGetPatientsMutation } from "../../../features/organisation/organisationApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import { Search_Icon } from "../../../assets/images";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import {
  ArrowDownwardTwoTone,
  ArrowUpwardTwoTone,
  FilterList,
} from "@mui/icons-material";
import CheckboxSelector from "../../Schedules/components/CheckboxSelector";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading";
import { Tooltip } from "@mui/material";
import Search from "../../../components/Search/SearchNew";
import FilterSelect from "../../../components/Filter/FilterSelect";

const PatientList = () => {
  const [patients, setPatients] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);

  const [statusFilter, setStatusFilter] = React.useState([]);
  const [locationFilter, setLocationFilter] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState("");

  const [sortStatusOrder, setSortStatusOrder] = React.useState("none");
  const [sortNameOrder, setSortNameOrder] = React.useState("none");

  const navigate = useNavigate();

  const handleSortStatus = (a, b) => {
    if (sortStatusOrder === "none") {
      return 0;
    } else if (sortStatusOrder === "asc") {
      return a.status.localeCompare(b.status);
    } else {
      return b.status.localeCompare(a.status);
    }
  };

  const handleSortName = (a, b) => {
    if (sortNameOrder === "none") {
      return 0;
    } else if (sortNameOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  };

  const handleLocationFilter = (location) => {
    if (locationFilter.indexOf(location) === -1) {
      setLocationFilter([...locationFilter, location]);
    } else {
      setLocationFilter(locationFilter.filter((filter) => filter !== location));
    }
  };

  const handleStatusFilter = (status) => {
    if (statusFilter.indexOf(status) === -1) {
      setStatusFilter([...statusFilter, status]);
    } else {
      setStatusFilter(statusFilter.filter((filter) => filter !== status));
    }
  };

  const handlePatientClick = (patient) => {
    navigate(`/care-management/patients/${patient.id}`);
  };

  const user = useSelector(selectUser);
  const [getPatients, { isLoading }] = useGetPatientsMutation();

  const fetchPatients = async () => {
    try {
      const { data } = await getPatients(user.organisation.id).unwrap();

      setPatients(data);
      setLocations(
        Array.from(
          new Set(
            data.map((patient) =>
              patient.locationName === null
                ? "Private Residence"
                : patient.locationName
            )
          )
        )
      );
      setStatuses(Array.from(new Set(data.map((patient) => patient.status))));
    } catch (error) {
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    fetchPatients();
  }, []);

  const Avatar = ({ name, image }) => {
    return (
      <>
        {image !== null ? (
          <div className="flex items-center gap-2">
            <img
              src={image}
              alt={name}
              className="object-cover w-10 h-10 rounded-full"
            />
            <p className="m-0 font-semibold">{name}</p>
          </div>
        ) : (
          <p className="flex items-center justify-center w-10 h-10 m-0 text-white rounded-full bg-primaryblue">
            {name
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </p>
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col items-start py-4 overflow-hidden">
      <Loading loading={isLoading} />
      <div className="flex flex-row items-center justify-between w-full px-16">
        <div className="flex flex-row items-center w-full gap-12">
          <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
            Patients{" "}
            <span className="ml-2 text-base font-bold text-primaryblue">
              {patients.length}
            </span>
          </h1>
          <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
            <Search value={searchFilter} setValue={setSearchFilter} />
            <FilterSelect
              value={locationFilter}
              handleChange={handleLocationFilter}
              data={locations}
              placeholder="Filter by Location..."
            />
            <FilterSelect
              value={statusFilter}
              handleChange={handleStatusFilter}
              data={statuses}
              placeholder="Filter by Status ..."
            />
          </div>
        </div>
      </div>
      <div className="w-full px-16">
        <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow max-h-[calc(100vh-200px)]">
          <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
            <thead>
              <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Name</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortNameOrder === "none") {
                          setSortNameOrder("asc");
                        }
                        if (sortNameOrder === "asc") {
                          setSortNameOrder("desc");
                        }
                        if (sortNameOrder === "desc") {
                          setSortNameOrder("none");
                        }
                      }}
                    >
                      {sortNameOrder === "asc" ? (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardTwoTone />
                        </Tooltip>
                      ) : sortNameOrder === "desc" ? (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardTwoTone />
                        </Tooltip>
                      ) : (
                        <div className="flex items-center h-4">
                          <div className="w-6 h-[2px] bg-white" />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">Date of Birth</th>
                <th className="px-4 py-3">Gender</th>
                <th className="px-4 py-3">EHR ID</th>
                <th className="px-4 py-3">Location</th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Status</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortStatusOrder === "none") {
                          setSortStatusOrder("asc");
                        }
                        if (sortStatusOrder === "asc") {
                          setSortStatusOrder("desc");
                        }
                        if (sortStatusOrder === "desc") {
                          setSortStatusOrder("none");
                        }
                      }}
                    >
                      {sortStatusOrder === "asc" ? (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardTwoTone />
                        </Tooltip>
                      ) : sortStatusOrder === "desc" ? (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardTwoTone />
                        </Tooltip>
                      ) : (
                        <div className="flex items-center h-4">
                          <div className="w-6 h-[2px] bg-white" />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                {/* <th className="px-4 py-3">Edit</th> */}
              </tr>
            </thead>
            <tbody>
              {patients
                .filter((patient) =>
                  patient.name
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
                )
                .filter(
                  (patient) =>
                    statusFilter.length === 0 ||
                    statusFilter.indexOf(patient.status) !== -1
                )
                .filter(
                  (patient) =>
                    locationFilter.length === 0 ||
                    locationFilter.indexOf(
                      patient.locationName === null
                        ? "Private Residence"
                        : patient.locationName
                    ) !== -1
                )
                .sort((a, b) => handleSortName(a, b))
                .sort((a, b) => handleSortStatus(a, b))
                .map((patient, index) => (
                  <tr
                    className={classNames(
                      "text-left text-gray-900 h-24 whitespace-nowrap",
                      index !== patients.length - 1 ? "border-b" : "border-none"
                    )}
                    key={index}
                  >
                    <td className="px-4 py-3 text-left">
                      <button
                        onClick={() => handlePatientClick(patient)}
                        className="flex flex-row items-center gap-4"
                      >
                        <Avatar name={patient.name} image={null} />
                        <p className="m-0 font-semibold underline">
                          {patient.name}
                        </p>
                      </button>
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {moment(patient.dob).format("MM/DD/YYYY")}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {patient.sex === "F" ? "Female" : "Male"}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {patient.patientEhrId}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {patient.locationName === null
                        ? "Private Residence"
                        : patient.locationName}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      <div
                        className={classNames(
                          patient.status.toUpperCase() === "ACTIVE"
                            ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                            : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                          "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                        )}
                      >
                        <div
                          className={classNames(
                            "w-2 h-2 rounded-full",
                            patient.status.toUpperCase() === "ACTIVE"
                              ? "bg-primarygreen"
                              : "bg-primarytextgrey"
                          )}
                        />
                        {patient.status}
                      </div>
                    </td>
                    {/* <td className="px-4 py-3 text-left">
                    <button
                      className="p-2 text-white rounded-md"
                      onClick={() => {}}
                    >
                      <IconComponent icon={"Edit_Icon"} i={provider.id} />
                    </button>
                  </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PatientList;
