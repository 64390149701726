import React, { useRef, useEffect, useState, useContext } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Avatar } from "@mui/material";
import {
  avatarName,
  createImageWithAlphabets,
} from "../../utilities/resuseFunctions";
import "./MapsComponent.css";
import CloseSvg from "../../assets/svgs/CloseSvg";
import Home from "../../assets/images/Home.png";
import { useMapStore } from "../../context/map.store";
import { SocketContext } from "../../context/SocketProvider";

const newGrpImg = "https://caresms.s3.us-east-2.amazonaws.com/defaultGroup.png";

const Map = ({ data, isProvider, selectedProvider }) => {
  const { height } = useWindowDimensions();
  const {
    zone,
    isOpen,
    appointmentOpen,
    focusMarker,
    setIsOpen,
    setAppointmentOpen,
    patientSelected,
  } = useMapStore((state) => state);
  const { selectedProviderLocation } = useContext(SocketContext);

  //   const selectedVisit = selections.visit;
  let coords = {
    lat: 33.4484,
    lng: -112.074,
    zoom: 10,
  };

  if (zone?.coords) {
    coords = {
      ...coords,
      ...zone.coords,
    };
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_API_KEY,
  });

  // console.log(data, "data");
  const mapRef = useRef(null);
  const directionsRendererRef = useRef(null);
  const responseRef = useRef(null);
  const [response, setResponse] = useState(null);

  const onIsOpen = (id, latlng) => {
    if (id) {
      focusMarker({ id, latlng });
    } else {
      setIsOpen(id);
    }
  };

  const onAppointmentOpen = (id, latlng, seq) => {
    id && focusMarker({ id, latlng, seq });
    setAppointmentOpen(seq);
  };

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(coords);
    // map.fitBounds(bounds);
    // setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    // setMap(null);
  }, []);

  const combined =
    isProvider && data.length
      ? [selectedProvider, ...data, selectedProvider]
      : [];

  const waypoints = isProvider
    ? combined?.map((item) => {
        return {
          location: {
            lat: parseFloat(item?.coords?.lat),
            lng: parseFloat(item?.coords?.lng),
          },
          stopover: true,
        };
      })
    : [];

  useEffect(() => {
    setResponse(null);
    responseRef.current = null;
    if (typeof directionsRendererRef?.current?.setMap === "function") {
      directionsRendererRef?.current?.setMap(null);
    }
    return () => {
      if (
        !isProvider &&
        typeof directionsRendererRef?.current?.setMap === "function"
      ) {
        directionsRendererRef?.current?.setMap(null);
      }
    };
  }, [data]);

  const CustomInfoWindow = ({
    item,
    isProvider,
    isMultiple,
    position,
    color,
    data,
  }) => {
    const selected = isProvider
      ? item?.find(
          (visit) =>
            appointmentOpen && appointmentOpen?.includes(visit.sequence)
        )
      : isMultiple
      ? item?.find(
          (visit, idx) =>
            (isOpen && isOpen?.includes(idx)) ||
            (appointmentOpen && appointmentOpen?.includes(idx))
        )
      : item?.[0];

    if (isMultiple && !appointmentOpen && isProvider) {
      return (
        <div className="info-card">
          <span className="community-name">{item.Facility}</span>
          {item?.map((visit) => {
            const color = visit?.isCompleted ? "#1D7C4D" : "#F3CA50";
            return (
              <button
                className="d-flex align-items-center px-0 btn"
                onClick={() =>
                  onAppointmentOpen(null, position, `a${visit.sequence}`)
                }
                key={visit.sequence}
              >
                <div>
                  <Avatar
                    style={{ backgroundColor: color }}
                    className="info-avatar"
                    // src={visit?.profileImg}
                  >
                    {visit.sequence}
                  </Avatar>
                </div>
                <div className="mx-2">
                  <h6 className="m-0 bold-medium text-decoration-underline">
                    {visit?.name}
                  </h6>
                </div>
              </button>
            );
          })}
          <CloseSvg
            role="button"
            className="close-marker"
            color={"#2F80ED"}
            onClick={() => {
              onIsOpen(null);
              onAppointmentOpen(null);
            }}
          />
        </div>
      );
    }

    if (isMultiple && !appointmentOpen && !isProvider) {
      return (
        <div className="info-card">
          <span className="community-name">
            Multiple Providers at same location
          </span>
          {item?.map((visit, idx) => {
            const color = visit?.isCompleted ? "#1D7C4D" : "#F3CA50";
            return (
              <button
                className="d-flex align-items-center px-0 btn"
                onClick={() => onAppointmentOpen(null, position, `c${idx}`)}
                key={idx}
              >
                <div>
                  <Avatar
                    style={{ backgroundColor: color }}
                    className="info-avatar"
                    // src={visit?.profileImg}
                  >
                    {visit.sequence}
                  </Avatar>
                </div>
                <div className="mx-2">
                  <h6 className="m-0 bold-medium text-decoration-underline">
                    {visit?.name || `${visit?.PatientName}`}
                  </h6>
                </div>
              </button>
            );
          })}
          <CloseSvg
            role="button"
            className="close-marker"
            color={"#2F80ED"}
            onClick={() => {
              onIsOpen(null);
              onAppointmentOpen(null);
            }}
          />
        </div>
      );
    }

    return (
      <div className="info-card">
        <div>
          {isProvider && (
            <Avatar style={{ backgroundColor: color }} className="info-avatar">
              {selected.sequence}
            </Avatar>
          )}
        </div>
        <div className="d-flex align-items-center">
          <div>
            {!isProvider && (
              <Avatar className="me-2" src={selected?.profileImg}>
                {avatarName(selected?.name)}
              </Avatar>
            )}
          </div>
          <div className="py-1">
            <h5 className="m-0">{selected?.name}</h5>
            <p className="m-0 py-1">
              {isProvider ? data?.name : selected?.speciality}
            </p>
            <p className="m-0 py-1">{data?.address}</p>
          </div>
        </div>
        <CloseSvg
          role="button"
          className="close-marker"
          color={"#2F80ED"}
          onClick={() => {
            onIsOpen(null);
            onAppointmentOpen(null);
          }}
        />
      </div>
    );
  };

  return isLoaded ? (
    <>
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{
          height: height - 270,
          border: "1px solid black",
          borderRadius: "6px",
        }}
        center={coords}
        zoom={coords.zoom || 11}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {isProvider && responseRef.current === null && waypoints?.length && (
          <DirectionsService
            options={{
              origin: waypoints[0].location,
              destination: waypoints[waypoints.length - 1].location,
              waypoints: waypoints.slice(1, -1),
              travelMode: "DRIVING",
            }}
            callback={(res) => {
              if (res !== null) {
                if (res.status === "OK") {
                  setResponse(res);
                  responseRef.current = res;
                  // console.log(res, "res");
                } else {
                  console.error(`error fetching directions ${res}`);
                }
              }
            }}
          />
        )}
        {responseRef.current !== null && isProvider && (
          <DirectionsRenderer
            ref={directionsRendererRef}
            options={{
              directions: responseRef.current,
              suppressMarkers: true,
              polylineOptions: {
                strokeColor: "#000000", // Red color
                strokeOpacity: 0.5,
                // strokeWeight: 6,
              },
            }}
          />
        )}
        {/* {waypoints.map((waypoint, index) => (
          <Marker key={index} position={waypoint.location} />
        ))} */}
        {/* Child components, such as markers, info windows, etc. */}
        {!isProvider &&
          data?.map((i, idx) => {
            const name = i.providers?.[0]?.name || "";
            const title =
              i?.providers?.length > 1
                ? String(i.providers.length)
                : avatarName(name);
            // const color = colors[idx % colors.length];
            const color = "#2F80ED";
            const avatar = createImageWithAlphabets(
              title,
              null,
              color,
              "#fff",
              true
            );
            const isMultiple = i.providers?.length > 1;

            let providerOptions = {};
            if (navigator.geolocation) {
              providerOptions = {
                icon: {
                  url: avatar, // Replace with desired pin color image URL
                  scaledSize: new window.google.maps.Size(30, 30),
                },
              };
            }

            const position = {
              ...i.coords,
            };

            return (
              <Marker
                key={idx}
                onClick={() => {
                  if (!isMultiple) {
                    onAppointmentOpen(
                      `c${idx}`,
                      position,
                      `a${i.data?.[0].sequence || ""}`
                    );
                  } else {
                    onIsOpen(`c${idx}`, position);
                  }
                }}
                position={position}
                options={providerOptions}
              >
                {isOpen === `c${idx}` && (
                  <InfoWindow
                    position={position}
                    onCloseClick={() => {
                      setIsOpen(null);
                      setAppointmentOpen(null);
                    }}
                    options={{ closeBoxURL: "" }}
                  >
                    <CustomInfoWindow
                      color={color}
                      data={i}
                      item={i.providers}
                      isProvider={isProvider}
                      isMultiple={isMultiple}
                      position={position}
                    />
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
        {isProvider &&
          data?.map((i, idx) => {
            const icon = i?.isCompleted ? "green-dot" : "yellow-dot";
            // const color = i?.isCompleted ? "#1D7C4D" : "#F3CA50";
            const isMultiple = i.patients.length > 1;
            // const color =
            //   i?.patients?.[0]?.type === "Ad-Hoc" ? "#F3CA50" : "#2F80ED";
            const color = "#F3CA50";
            const title = isMultiple
              ? `${i.patients?.[0].sequence}-${
                  i.patients?.[i.patients.length - 1].sequence
                }`
              : String(i.patients?.[0].sequence);
            const avatar = createImageWithAlphabets(
              title,
              null,
              color,
              "#fff",
              true
            );

            let providerOptions = {};
            if (navigator.geolocation) {
              providerOptions = {
                icon: {
                  url:
                    avatar ||
                    `https://maps.google.com/mapfiles/ms/icons/${icon}.png`, // Replace with desired pin color image URL
                  scaledSize: new window.google.maps.Size(30, 30),
                },
              };
            }

            const position = {
              ...i.coords,
            };

            return (
              <Marker
                key={idx}
                onClick={() => {
                  if (!isMultiple) {
                    onAppointmentOpen(
                      `c${idx}`,
                      position,
                      `a${i.patients?.[0].sequence || ""}`
                    );
                  } else {
                    onIsOpen(`c${idx}`, position);
                  }
                }}
                position={position}
                options={providerOptions}
              >
                {isOpen === `c${idx}` && (
                  <InfoWindow
                    position={position}
                    onCloseClick={() => {
                      onIsOpen(null);
                      onAppointmentOpen(null);
                    }}
                    options={{ closeBoxURL: "" }}
                  >
                    <CustomInfoWindow
                      color={color}
                      item={i.patients}
                      data={i}
                      isProvider={isProvider}
                      isMultiple={isMultiple}
                      position={position}
                    />
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
        {isProvider &&
          selectedProvider &&
          [selectedProvider]?.map((i, idx) => {
            const position = {
              ...i.coords,
            };

            return (
              <Marker
                key={idx}
                onClick={() => {
                  setIsOpen(`p${idx}`, position);
                }}
                position={position}
                // icon={iconMarker}
                icon={{
                  url: Home,
                  scaledSize: new window.google.maps.Size(30, 30),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(25, 25),
                }}
              >
                {isOpen === `p${idx}` && (
                  <InfoWindow
                    position={position}
                    onCloseClick={() => {
                      new window.google.maps.InfoWindow();
                      setIsOpen(null);
                    }}
                    options={{ closeBoxURL: "" }}
                  >
                    <CustomInfoWindow
                      item={i.providers}
                      data={i}
                      isProvider={false}
                      isMultiple={false}
                      position={position}
                    />
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
        {isProvider &&
          selectedProviderLocation &&
          [selectedProviderLocation]?.map((i, idx) => {
            const name = i.providers?.[0]?.name || "";
            const icon = "yellow-dot";
            const color = "#2F80ED";
            const title = avatarName(name);
            const avatar = createImageWithAlphabets(
              title,
              null,
              color,
              "#fff",
              true
            );

            let providerOptions = {};
            if (navigator.geolocation) {
              providerOptions = {
                icon: {
                  url:
                    avatar ||
                    `https://maps.google.com/mapfiles/ms/icons/${icon}.png`, // Replace with desired pin color image URL
                  scaledSize: new window.google.maps.Size(30, 30),
                },
              };
            }

            const position = {
              lat: i.coords.latitude,
              lng: i.coords.longitude,
            };

            return (
              <Marker
                key={idx}
                onClick={() => {
                  setIsOpen(`p${idx}`, position);
                }}
                position={position}
                options={providerOptions}
              >
                {isOpen === `p${idx}` && (
                  <InfoWindow
                    position={position}
                    onCloseClick={() => {
                      new window.google.maps.InfoWindow();
                      setIsOpen(null);
                    }}
                    options={{ closeBoxURL: "" }}
                  >
                    <CustomInfoWindow
                      color={color}
                      item={i.providers}
                      data={i}
                      isProvider={false}
                      isMultiple={false}
                      position={position}
                    />
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
        {/* {selectedVisit &&
          [selectedVisit]?.map((i, idx) => {
            const icon = "blue-dot";
            const lat = i?.PatientLatitude && parseFloat(i?.PatientLatitude);
            const lng = i?.PatientLongitude && parseFloat(i?.PatientLongitude);
            const zone = {
              ZoneLatitude: lat,
              ZoneLongitude: lng,
            };

            const position = {
              lat,
              lng,
            };

            const title = i.PatientFirstName[0];
            const color = "#2f80ed";
            const avatar = createImageWithAlphabets(title, null, color);

            let providerOptions = {};
            if (navigator.geolocation) {
              providerOptions = {
                icon: {
                  url:
                    avatar ||
                    `https://maps.google.com/mapfiles/ms/icons/${icon}.png`, // Replace with desired pin color image URL
                  scaledSize: new window.google.maps.Size(30, 30),
                },
              };
            }

            return (
              <Marker
                key={idx}
                onClick={() => {
                  setIsOpen(`np${idx}`, {
                    ZoneLatitude: lat,
                    ZoneLongitude: lng,
                  });
                }}
                position={position}
                // icon={iconMarker}
                options={providerOptions}
              >
                {isOpen === `np${idx}` && (
                  <InfoWindow
                    position={position}
                    onCloseClick={() => {
                      new window.google.maps.InfoWindow();
                      setIsOpen(null);
                    }}
                    options={{ closeBoxURL: "" }}
                  >
                    <CustomInfoWindow
                      item={{
                        id: i.PatientID,
                        firstName: i.PatientFirstName,
                        lastName: i.PatientLastName,
                        profileImg: avatar,
                      }}
                      zone={zone}
                    />
                  </InfoWindow>
                )}
              </Marker>
            );
          })} */}
        {patientSelected &&
          [patientSelected]?.map((i, idx) => {
            const icon = "blue-dot";

            const position = {
              ...i.coords,
            };

            const title =
              data.reduce((acc, curr) => {
                return acc + curr.patients.length;
              }, 0) + 1;

            const color = "#F3CA50";
            const avatar = createImageWithAlphabets(
              title,
              null,
              color,
              "#fff",
              true
            );

            let providerOptions = {};
            if (navigator.geolocation) {
              providerOptions = {
                icon: {
                  url:
                    avatar ||
                    `https://maps.google.com/mapfiles/ms/icons/${icon}.png`, // Replace with desired pin color image URL
                  scaledSize: new window.google.maps.Size(30, 30),
                },
              };
            }

            return (
              <Marker
                key={idx}
                onClick={() => {
                  onIsOpen(`np${idx}`, {
                    latitude: i.coords.lat,
                    longitude: i.coords.lng,
                  });
                }}
                position={position}
                // icon={iconMarker}
                options={providerOptions}
              >
                {isOpen === `np${idx}` && (
                  <InfoWindow
                    position={position}
                    onCloseClick={() => {
                      new window.google.maps.InfoWindow();
                      onIsOpen(null);
                    }}
                    options={{ closeBoxURL: "" }}
                  >
                    <CustomInfoWindow
                      color={color}
                      item={i.patients}
                      data={i}
                      isProvider={isProvider}
                      isMultiple={false}
                      position={position}
                    />
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
};

export default Map;
