import React, { useState } from "react";

import Calendar from "./components/Calendar";
import GlobalProviderSettings from "./components/GlobalProviderSettings";
import ProviderFilter from "./components/ProviderFilter";
import ProviderSettingsModal from "./components/ProviderSettingsModal";
import ProviderTable from "./components/ProviderTable";
import SelectedProvidersTable from "../../RunScheduler/components/SelectedProvidersTable";
import moment from "moment";
import { useUpsertProviderConstraintMutation } from "../../../../features/provider/providerApiSlice";

const ProvidersScreen = React.forwardRef(
  (
    {
      setOpaque,
      providers,
      setProviders,
      isGlobalShow = true,
      isRunScheduler,
      isWelComePage,
      showAlert,
    },
    ref
  ) => {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [providerIdToEdit, setProviderIdToEdit] = useState(null);

    const [nameFilter, setNameFilter] = useState("");
    const [specialityFilter, setSpecialityFilter] = useState([]);
    const [AvailabilityFilter, setAvailabilityFilter] = useState([]);

    const [visitPerDaySort, setVisitPerDaySort] = useState(false);
    const [gStartDate, setGStartDate] = useState("");
    const [gEndDate, setGEndDate] = useState("");
    const [providerDates, setProviderDates] = useState({});
    const [isCalendarOpen, setIsCalenderOpen] = useState(false);
    const [provideIdAv, setProviderIsAv] = useState(null);

    const handleOpaque = (opaque) => {
      setOpaque(opaque);
    };

    const handleEditClick = (providerId) => {
      if (editModalOpen) {
        setProviderIdToEdit(null);
        handleOpaque(false);
      } else {
        setProviderIdToEdit(providerId);
        handleOpaque(true);
      }
      setEditModalOpen(!editModalOpen);
    };

    const selectProvider = (providerId) => {
      const updatedProviders = providers.map((provider) => {
        if (provider.id === providerId) {
          return {
            ...provider,
            isSelected: !provider.isSelected,
          };
        }

        return provider;
      });

      setProviders(updatedProviders);
    };

    const selectAllProviders = (selected) => {
      const updatedProviders = providers.map((provider) => ({
        ...provider,
        isSelected: filterProviders(provider) ? selected : provider.isSelected,
      }));

      setProviders(updatedProviders);
    };

    const handleSpecialityFilter = (speciality) => {
      if (specialityFilter.includes(speciality)) {
        setSpecialityFilter(
          specialityFilter.filter((filter) => filter !== speciality)
        );
      } else {
        setSpecialityFilter([...specialityFilter, speciality]);
      }
    };

    const handleAvailabilityFilter = (Availability) => {
      if (AvailabilityFilter.includes(Availability)) {
        setAvailabilityFilter(
          AvailabilityFilter.filter((filter) => filter !== Availability)
        );
      } else {
        setAvailabilityFilter([...AvailabilityFilter, Availability]);
      }
    };

    const handleVisitPerDaySort = () => {
      setVisitPerDaySort(!visitPerDaySort);
    };

    const handleNameFilter = (name) => {
      setNameFilter(name);
    };

    const filterProviders = (provider) => {
      let flag =
        provider.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
        (specialityFilter.length === 0 ||
          specialityFilter.includes(provider.speciality));

      let workingDaysPresent = false;

      AvailabilityFilter.forEach((day) => {
        if (!workingDaysPresent) {
          workingDaysPresent = provider.workingDays.includes(day.toUpperCase());
        }
      });

      return flag && (AvailabilityFilter.length === 0 || workingDaysPresent);
    };
    const handleGlobalDateChange = async (date, label) => {
      try {
        providers.map(async (provider) => {
          await upsertProviderConstraintAPI({
            id: provider.id,
            data: {
              [label]: moment(date).format("YYYY-MM-DD"),
            },
          }).unwrap();
          if (label == "current_start_date") {
            setProviderDates((prevDates) => ({
              ...prevDates,
              [provider.id]: {
                ...prevDates[provider.id],
                startDate: null,
              },
            }));
          } else {
            setProviderDates((prevDates) => ({
              ...prevDates,
              [provider.id]: {
                ...prevDates[provider.id],
                endDate: null,
              },
            }));
          }
        });
        const updatedProviders = providers.map((provider) => {
          return {
            ...provider,
            [label]: moment(date).format("YYYY-MM-DD"),
          };
        });
        setProviders(updatedProviders);
        if (label == "current_start_date") {
          setGStartDate(date);
        } else {
          setGEndDate(date);
        }
      } catch (err) {
        console.log(err);
      }
    };
    const [upsertProviderConstraintAPI] = useUpsertProviderConstraintMutation();
    const handleStartDateChange = (id, newStartDate) => {
      setProviderDates((prevDates) => ({
        ...prevDates,
        [id]: {
          ...prevDates[id],
          startDate: newStartDate,
        },
      }));
    };
    const handleEndDateChange = (id, newEndDate) => {
      setProviderDates((prevDates) => ({
        ...prevDates,
        [id]: {
          ...prevDates[id],
          endDate: newEndDate,
        },
      }));
    };
    React.useEffect(() => {
      const initialDates = {};
      providers.forEach((provider) => {
        initialDates[provider.id] = {
          startDate: provider.current_start_date
            ? provider.current_start_date
            : provider.startDate,
          endDate: provider.current_end_date
            ? provider.current_end_date
            : provider.endDate,
        };
      });
      setProviderDates(initialDates);
    }, [providers]);

    React.useEffect(() => {
      const initialDates = {};
      providers.forEach((provider) => {
        initialDates[provider.id] = {
          startDate: provider.startDate,
          endDate: provider.endDate,
        };
      });
      setProviderDates(initialDates);
    }, []);
    return (
      <div ref={ref}>
        <div className="w-full" />
        <div className={`max-h-screen ${isWelComePage ? "mx-4" : ""} pb-12`}>
          <div
            className={`flex flex-col gap-2 ${
              isRunScheduler || !isGlobalShow ? "" : "mt-4 md:mt-12"
            }`}
          >
            <div className="flex flex-col gap-8 lg:flex-row">
              {isRunScheduler && isWelComePage && (
                <div className="w-full gap-2 d-flex lg:w-96">
                  <input
                    type="date"
                    className="p-2 text-sm text-gray-900 bg-white rounded-md focus:ring-blue-500 disabled:opacity-40 w-52 outline outline-gray-200 grow"
                    min={moment().format("YYYY-MM-DD")}
                    // defaultValue={provider.startDate}
                    onChange={(e) => {
                      handleGlobalDateChange(
                        e.target.value,
                        "current_start_date"
                      );
                    }}
                  />
                  <span className="items-center d-flex">-</span>
                  <input
                    type="date"
                    className="p-2 text-sm text-gray-900 bg-white rounded-md focus:ring-blue-500 disabled:opacity-40 w-52 outline outline-gray-200 grow"
                    min={moment().format("YYYY-MM-DD")}
                    // defaultValue={provider.startDate}
                    onChange={(e) => {
                      handleGlobalDateChange(
                        e.target.value,
                        "current_end_date"
                      );
                    }}
                  />
                </div>
              )}
              <ProviderFilter
                providers={providers}
                handleNameFilter={handleNameFilter}
                handleSpecialityFilter={handleSpecialityFilter}
                handleAvailabilityFilter={handleAvailabilityFilter}
                nameFilter={nameFilter}
                specialityFilter={specialityFilter}
                AvailabilityFilter={AvailabilityFilter}
                isGlobalShow={isGlobalShow}
                isRunScheduler={isRunScheduler}
              />
              {/* <GlobalProviderSettings
              providers={providers}
              filterProviders={filterProviders}
              setProviders={setProviders}
            /> */}
            </div>
            {isRunScheduler ? (
              <SelectedProvidersTable
                providers={providers.filter(filterProviders)}
                setProviders={setProviders}
                handleEditClick={handleEditClick}
                selectProvider={selectProvider}
                hideRemove={isRunScheduler}
                gStartDate={gStartDate}
                gEndDate={gEndDate}
                isRunScheduler={isRunScheduler}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                providerDates={providerDates}
                isWelComePage={isWelComePage}
                selectAllProviders={selectAllProviders}
              />
            ) : (
              <ProviderTable
                providers={
                  providers.filter(filterProviders)
                  // .sort((a, b) =>
                  //   visitPerDaySort
                  //     ? b.visitsPerDay - a.visitsPerDay
                  //     : a.visitsPerDay - b.visitsPerDay
                  // )
                }
                handleEditClick={handleEditClick}
                selectProvider={selectProvider}
                selectAllProviders={selectAllProviders}
                visitPerDaySort={visitPerDaySort}
                handleVisitPerDaySort={handleVisitPerDaySort}
                isGlobalShow={isGlobalShow}
                isRunScheduler={isRunScheduler}
                setIsCalenderOpen={setIsCalenderOpen}
                setProviderIsAv={setProviderIsAv}
              />
            )}
          </div>
        </div>
        {editModalOpen && (
          <ProviderSettingsModal
            handleEditClick={handleEditClick}
            details={providers.find(
              (provider) => provider.id === providerIdToEdit
            )}
            providers={providers}
            setProviders={setProviders}
            setIsCalenderOpen={setIsCalenderOpen}
            setProviderIsAv={setProviderIsAv}
            setEditModalOpen={setEditModalOpen}
          />
        )}
        {isCalendarOpen && (
          <Calendar
            isCalendarOpen={isCalendarOpen}
            setIsCalendarOpen={setIsCalenderOpen}
            provideIdAv={provideIdAv}
            showAlert={showAlert}
          />
        )}
      </div>
    );
  }
);

export default ProvidersScreen;
