import React, { useState } from "react";
import classNames from "classnames";
import RightBanner from "../../common/RightBanner";
import ForgotPassword from "../../common/ForgotPassword";
import VerifyEmail from "../../common/VerifyEmail";
import EnterOtherEmail from "../../common/EnterOtherEmail";

const ForgotSteps = () => {
  const [email, setEmail] = useState("");
  const [currentStep, setCurrentStep] = useState(0);

  const handleStepChange = (index) => {
    if (index === 5) {
      return;
    }
    setCurrentStep(index);
  };

  return (
    <>
      <div
        className={
          "sm:w-7/12 w-full overflow-y-auto" +
          " flex flex-col  h-screen sm:px-16 px-8 pt-4  overflow-x-clip no-scrollbar"
        }
      >
        <div className="relative w-full">
          <div
            className={classNames(
              "absolute top-0 z-50 w-full transition-all ease-in-out",
              currentStep === 0 && "block",
              currentStep > 0 && "-translate-x-full right-28 hidden",
              currentStep < 0 && "translate-x-full left-28 hidden"
            )}
          >
            <EnterOtherEmail
              email={email}
              handleStepChange={handleStepChange}
              step={currentStep}
              option={"reset-password"}
              setEmail={setEmail}
            />
          </div>
          <div
            className={classNames(
              "absolute top-0 z-50 w-full transition-all ease-in-out",
              currentStep === 1 && "block",
              currentStep > 1 && "-translate-x-full right-28 hidden",
              currentStep < 1 && "translate-x-full left-28 hidden"
            )}
          >
            <VerifyEmail
              email={email}
              handleStepChange={handleStepChange}
              step={currentStep}
            />
          </div>
          <div
            className={classNames(
              "absolute top-0 z-50 w-full d-flex items-center transition-all ease-in-out",
              currentStep === 2 && "block",
              currentStep > 2 && "-translate-x-full right-28 hidden",
              currentStep < 2 && "translate-x-full left-28 hidden"
            )}
          >
            <ForgotPassword
              email={email}
              handleStepChange={handleStepChange}
              step={currentStep}
            />
          </div>
        </div>
      </div>
      <RightBanner />
    </>
  );
};

export default ForgotSteps;
