export const loggedInRoutes = [
  {
    id: 1,
    path: "/home",
    access: ["*"],
  },
  {
    id: 2,
    name: "Calendar View",
    path: "/calendar-view",
    access: ["*"],
  },
  {
    id: 3,
    name: "Patient Scheduling Wizard",
    path: "/scheduling-wizard",
    access: ["1"],
  },
  {
    id: 4,
    name: "Providers",
    path: "/care-management/providers",
    access: ["1"],
  },
  {
    id: 5,
    name: "Patients",
    path: "/care-management/patients",
    access: ["1"],
  },
  {
    id: 6,
    name: "Communities",
    path: "/care-management/communities",
    access: ["1"],
  },
  {
    id: 7,
    name: "Caretags",
    path: "/care-management/caretags",
    access: ["1"],
  },
  {
    id: 8,
    name: "Zones",
    path: "/care-management/zones",
    access: ["1"],
  },
  {
    id: 9,
    name: "Messenger",
    path: "/chat",
    access: ["*"],
  },
  {
    id: 10,
    name: "Upload Scheduling Data",
    path: "/schedules/careplan-upload",
    access: ["1"],
  },
  {
    id: 11,
    name: "Provider Settings",
    path: "/schedules/configure/providers",
    access: ["1"],
  },
  {
    id: 12,
    name: "Visit Types",
    path: "/schedules/configure/visit-types",
    access: ["1"],
  },
  {
    id: 13,
    name: "Visit Rules",
    path: "/schedules/configure/visit-rules",
    access: ["1"],
  },
  {
    id: 14,
    name: "Run Scheduler",
    path: "/schedules/run-scheduler",
    access: ["1"],
    isEnterprise: false,
  },
  {
    id: 15,
    name: "Patient Visit Report",
    path: "/reports/patient-visit-report",
    access: ["1"],
  },
  {
    id: 16,
    name: "Provider Activity Report",
    path: "/reports/provider-activity-report",
    access: ["1"],
  },
  {
    id: 17,
    name: "Revenue Report",
    path: "/reports/revenue-report",
    access: ["1"],
  },
  {
    id: 18,
    name: "Appointment Cancellation Report",
    path: "/reports/appointment-cancellation-report",
    access: ["1"],
  },
  {
    id: 19,
    name: "Overdue Patients Report",
    path: "/reports/overdue-patients-report",
    access: ["1"],
  },
  {
    id: 20,
    name: "New Patients Report",
    path: "/reports/new-patients-report",
    access: ["1"],
  },
  {
    id: 21,
    name: "Report Builder",
    path: "/reports/report-builder",
    access: ["1"],
  },
  {
    id: 22,
    name: "Pivot",
    path: "/analytics/pivot",
    access: ["1"],
  },
  {
    id: 22,
    name: "Pivot",
    path: "/analytics/dashboard",
    access: ["1"],
  },
  // {
  //   id: 22,
  //   path: "/schedule/rules",
  //   access: [
  //     {
  //       role: 1,
  //       position: [1,2],
  //     },
  //   ],
  // },
];
